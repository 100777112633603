
import { Options, prop, Vue } from "vue-class-component";
import Accordion from "@/app/ui/components/accordion/index.vue";
import { flags } from "@/feature-flags";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  showViewAdjustment = prop<any>({
    default: false,
    type: Boolean,
  });
  title = prop<string>({
    default: "",
    type: String,
  });
  watermark = prop<string>({
    default: "",
    type: String,
  });
  photoGuides = prop<any>({
    default: [
      { source: "guide-photo-one", desc: "Foto timbangan" },
      { source: "guide-photo-two", desc: "Foto barang dengan <br/> identitas" },
    ],
  });
}
@Options({
  emits: ["onUploadImage", "onDeleteImage"],
  components: {
    Accordion,
  },
})
export default class SectionUploadPhotoForWeightAdjusment extends Vue.with(Props) {
  isShaking = false;
  proofPhotos = [...this.photoGuides];
  attachmentImage: any = [];

  get maxPhoto() {
    return flags.flag_max_photo_for_weight_adjustment.getValue();
  }
  onAddImage() {
    this.proofPhotos.push({ source: "", desc: "Foto tambahan" });
    this.$emit("update:modelValue", this.attachmentImage);
  }

  onUpload(file: any) {
    this.$emit("update:modelValue", this.attachmentImage);
    if (file.file) this.$emit("onUploadImage", file);
  }

  onRemove(index: number) {
    this.deleteFiles(index);
    this.$emit("onDeleteImage", index);
  }

  deleteFiles(index: number) {
    this.attachmentImage[index] = "";
    this.$emit("update:modelValue", this.attachmentImage);
  }

  onClickAccordion() {
    const refs: any = this.$refs;
    if (refs && !this.showViewAdjustment) {
      refs["accordion-adjustment"].toggleAccordion();
      const doc: any = document;
      const element = doc.getElementById("section-upload-photo");
      element.scrollIntoView();
      this.isShaking = true;
      setTimeout(() => {
        this.isShaking = false;
      }, 2000);
    }
  }

  onClick(value: any) {
    this.$emit('update:showViewAdjustment', value);
  }
}
