
import { Options, Vue, prop } from "vue-class-component";

class Props {
  goodsNames = prop<any[]>({
    required: true
  });
}

@Options({
  components: { },
})

export default class SectionGoodsCategory extends Vue.with(Props) {
  isExpanded = false;

  wrapText(text: string) {
    let wrappedText = text;
    if (text.length > 200) {
      wrappedText = text.substring(0, 200) + '...';
    }
    return wrappedText
  }

  setExpand() {
    this.isExpanded = !this.isExpanded;
  }

  get expandedTitle() {
    return this.isExpanded ? "Sembunyikan" : "Lihat semua";
  }
}
