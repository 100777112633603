import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow } from "vue"
import _imports_0 from '@/app/ui/assets/svg/arrow-left.svg'


const _hoisted_1 = {
  class: "relative flex flex-col",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "flex flex-row justify-between items-center border-b border-gray-lp-400 z-20 relative" }
const _hoisted_3 = { class: "flex flex-row my-4" }
const _hoisted_4 = { class: "font-semibold text-20px mr-4 text-black-lp-200 title-layout" }
const _hoisted_5 = { class: "hidden" }
const _hoisted_6 = {
  class: "w-full pt-6 overflow-y-auto",
  style: {"height":"100%"}
}
const _hoisted_7 = { class: "w-1/2 flex flex-row mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "w-full flex flex-col border-t border-gray-lp-400 pt-8"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full"
}
const _hoisted_10 = {
  class: "border-t border-gray-lp-400 pt-8",
  style: {"height":"80px"}
}
const _hoisted_11 = { class: "flex flex-row w-2/5 float-left relative align-middle" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = {
  key: 1,
  class: "relative flex flex-col justify-center align-middle"
}
const _hoisted_14 = { class: "ml-1 relative" }
const _hoisted_15 = { class: "md:float-right w-full sm:w-300px my-2 sm:my-0 font-medium text-12px xxl:text-14px px-4 py-3 bg-gray-300 info relative overflow-hidden" }
const _hoisted_16 = { class: "z-10 relative cursor-default" }
const _hoisted_17 = { class: "mt-12 md:mt-8 table-shipment-id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_data_wrapper = _resolveComponent("data-wrapper")!
  const _component_skeleton = _resolveComponent("skeleton")!
  const _component_custom_dropdown = _resolveComponent("custom-dropdown")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ModalDetailShipment = _resolveComponent("ModalDetailShipment")!
  const _component_ModalAddShipmentDestination = _resolveComponent("ModalAddShipmentDestination")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("img", {
            src: _imports_0,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args))),
            class: "w-5 mr-3 cursor-pointer",
            alt: "arrow-left"
          }),
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.getTitle), 1)
        ])
      ]),
      _withDirectives(_createVNode("div", _hoisted_5, null, 512), [
        [_directive_click_outside, () => (_ctx.chevronUp = _ctx.chevronUp.map(() => false))]
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_data_wrapper, {
          label: "Shipment ID / Booking ID",
          class: "mb-8"
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_7, [
              _withDirectives(_createVNode("input", {
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => (_ctx.shipmentId = $event)),
                  _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.parseInputShipmentId && _ctx.parseInputShipmentId(...args)))
                ],
                type: "text",
                onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.fetchShipmentId('inputShipmentId')), ["enter"])),
                placeholder: "Masukan shipment id atau scan barcode/qrcode",
                class: "flex-grow mr-4 py-2.5 xxl:py-3 px-3 rounded-r-md border-r rounded-l-md border-l border-t border-b false focus:outline-none focus:shadow text-black-lp-300"
              }, null, 544), [
                [_vModelText, _ctx.shipmentId]
              ]),
              _createVNode(_component_lp_button, {
                class: 
              `px-5 rounded-md icon-white button-style flex flex-row justify-center align-middle ${
                !_ctx.shipmentId ? `color-disabeled` : `bg-red-lp-100`
              }`
            ,
                "text-color": "white",
                title: "Booking Pengiriman",
                flat: "",
                iconLeft: "pencil-alt",
                onClick: _cache[5] || (_cache[5] = 
              () => (_ctx.shipmentId ? _ctx.fetchShipmentId('inputShipmentId') : null)
            )
              }, null, 8, ["class"])
            ])
          ]),
          _: 1
        }),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock("div", _hoisted_8, [
              _createVNode(_component_skeleton, {
                width: "600px",
                height: "40px"
              }),
              _createVNode(_component_skeleton, {
                class: "mt-10",
                width: "800px",
                height: "40px"
              }),
              _createVNode(_component_skeleton, {
                class: "mt-3",
                width: "700px",
                height: "20px"
              }),
              _createVNode(_component_skeleton, {
                class: "mt-3",
                width: "500px",
                height: "20px"
              }),
              _createVNode(_component_skeleton, {
                class: "mt-3",
                width: "800px",
                height: "20px"
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.shipmentIdList.length > 0 && !_ctx.isLoading)
          ? (_openBlock(), _createBlock("div", _hoisted_9, [
              _createVNode("div", _hoisted_10, [
                _createVNode("div", _hoisted_11, [
                  _createVNode(_component_custom_dropdown, {
                    placeholder: "Sort By",
                    value: "Sort By",
                    class: "dropdown mr-4 mb-1 md:mb-0",
                    isExpand: _ctx.isExpand,
                    "onUpdate:isExpand": _cache[6] || (_cache[6] = ($event: any) => (_ctx.isExpand = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode("div", _hoisted_12, [
                        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.sortOptions, (item, index) => {
                          return (_openBlock(), _createBlock("label", {
                            class: ["py-1 hover:text-red-lp-100 cursor-pointer", item === _ctx.sortBy && `text-red-lp-100`],
                            key: index.toString(),
                            onClick: () => _ctx.setSortBy(item)
                          }, _toDisplayString(item), 11, ["onClick"]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["isExpand"]),
                  (_ctx.showAddDestination || _ctx.hideAddDestinationWhenIsCod())
                    ? (_openBlock(), _createBlock(_component_lp_button, {
                        key: 0,
                        class: "mr-2 add-destination",
                        title: "Tambah Alamat Tujuan",
                        "text-color": _ctx.enableAddDestination ? `red-lp-100` : `gray-500`,
                        color: _ctx.enableAddDestination ? `red-lp-100` : `gray-500`,
                        outline: "",
                        disabled: !_ctx.enableAddDestination,
                        onClick: _cache[7] || (_cache[7] = () => (_ctx.enableAddDestination ? _ctx.onAddDestination() : null))
                      }, null, 8, ["text-color", "color", "disabled"]))
                    : _createCommentVNode("", true),
                  (_ctx.showAddDestination)
                    ? (_openBlock(), _createBlock("div", _hoisted_13, [
                        _createVNode("div", _hoisted_14, [
                          _createVNode("img", {
                            onMouseenter: _cache[8] || (_cache[8] = () => (_ctx.isOpenTooltip = true)),
                            onMouseleave: _cache[9] || (_cache[9] = () => (_ctx.isOpenTooltip = false)),
                            src: 
                    _ctx.isOpenTooltip
                      ? require('@/app/ui/assets/svg/info-red.svg')
                      : require('@/app/ui/assets/svg/info.svg')
                  ,
                            alt: ""
                          }, null, 40, ["src"]),
                          _withDirectives(_createVNode(_component_Tooltip, {
                            title: "Tambah Alamat Tujuan",
                            message: "Tambahkan alamat tujuan yang baru untuk kelengkapan data dengan mengisi kecamatan kota tujuan pengiriman.",
                            footer: "",
                            isArrowBottom: false,
                            style: {"width":"300px","margin-left":"2.6em","margin-top":"-2.6em"}
                          }, null, 512), [
                            [_vShow, _ctx.isOpenTooltip]
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode("div", _hoisted_15, [
                  _createVNode("img", {
                    class: "absolute w-16 opacity-25",
                    style: {"left":"-23px","top":"-10px"},
                    src: require('@/app/ui/assets/svg/info.svg'),
                    alt: "info"
                  }, null, 8, ["src"]),
                  _createVNode("span", _hoisted_16, _toDisplayString(_ctx.bookedCount) + " dari " + _toDisplayString(_ctx.shipmentIdList.length) + " Shipment ID sudah terbooking", 1)
                ])
              ]),
              _createVNode("div", _hoisted_17, [
                _createVNode(_component_TableV2, {
                  columns: _ctx.columns,
                  data: _ctx.shipmentIdList,
                  loading: _ctx.isLoading,
                  pagination: _ctx.pagination,
                  "onUpdate:pagination": _cache[10] || (_cache[10] = ($event: any) => (_ctx.pagination = $event)),
                  isHardPagination: true,
                  "border-bottom": false,
                  totalColumnFreeze: 1
                }, null, 8, ["columns", "data", "loading", "pagination"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onCancel: _ctx.handleError,
          onTryAgain: _ctx.fetchShipmentId('inputShipmentId'),
          modal: ""
        }, null, 8, ["errorType", "onCancel", "onTryAgain"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.handlerClose,
      onClose: _ctx.handlerCancelClose,
      title: "Yakin ingin keluar?",
      message: "Pastikan kembali, Anda akan kehilangan semua data jika Anda keluar halaman ini.",
      image: "are-you-sure",
      textSuccess: "Keluar",
      textCancel: "Kembali",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose"]), [
      [_vShow, _ctx.isEditTerminated]
    ]),
    (_ctx.showModalShipment)
      ? (_openBlock(), _createBlock(_component_ModalDetailShipment, {
          key: 1,
          onUpdateStatus: _ctx.initPage
        }, null, 8, ["onUpdateStatus"]))
      : _createCommentVNode("", true),
    (_ctx.bookingAddShipmentDestination)
      ? (_openBlock(), _createBlock(_component_ModalAddShipmentDestination, {
          key: 2,
          shipmentId: _ctx.shipmentId,
          source: _ctx.shipmentIdList[0]?.customerName,
          onSuccessBooking: _ctx.fetchShipmentId
        }, null, 8, ["shipmentId", "source", "onSuccessBooking"]))
      : _createCommentVNode("", true),
    (_ctx.shipmentIdList.length === 0 && !_ctx.isLoading && _ctx.isGenerate)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 3,
          errorType: "notfound"
        }))
      : _createCommentVNode("", true)
  ], 64))
}