
import { Options, prop, Vue } from "vue-class-component";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import { PromoConfigurationListData } from "@/domain/entities/PromoConfiguration";
import { DetailDiscount } from "@/domain/entities/Tariff";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";

class Props {
  showDiscountNominal = prop<boolean>({
    default: false,
    type: Boolean
  });
  discountData = prop<any[]>({
    default: [],
    type: Array
  });
  tariffAfterDiscount = prop<number>({
    default: 0,
    type: Number
  });
  defaultDiscount = prop<any[]>({
    default: [],
    type: Array
  });
}

@Options({})
export default class Discount extends Vue.with(Props) {
  refs: any = {};
  onOpenTooltipPromo(event: any, index: number) {
    const refs: any = this.$refs;
    refs[`infoPromo${index}`].toggle(event);
  }

  currency(curr: number) {
    if (curr) {
      return formatPrice(curr);
    }
    return formatPrice(0);
  }

  get listDiscount() {
    return this.defaultDiscount.length > 0 
      ? this.defaultDiscount.filter((item: DetailDiscount) => item.pdcCategory === 'tactical' || item.pdcCategory === 'campaign')
        .map((item: DetailDiscount) => {
          return new PromoConfigurationListData({
            promoConfigurationName: `${
              item.pdcName
            } sampai dengan ${formatDateWithoutTime(
              item.pdcEndDate
            )}`,
            promoConfigurationDiscountNominal:
              item.pdcPromoDiscount
          })
        })
      : this.discountData
  }
}
