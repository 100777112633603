import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import { container } from "tsyringe";
import store from "@/store";
import { PromoConfigurationPresenter } from "@/app/ui/presenters/PromoConfigurationPresenter";
import {
  PromoConfigurationListRequest,
  SubmitPromoConfigurationApiRequest
} from "@/data/payload/api/PromoConfigurationApiRequest";
import { PromoConfigurationComponent } from "@/app/infrastructures/dependencies/modules/PromoConfigurationComponent";
import { PromoConfiguration } from "@/domain/entities/PromoConfiguration";
PromoConfigurationComponent.init();
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "promo-configuration"
})
class PromoConfigurationStore extends VuexModule {
  @Action
  public async createPromoConfiguration(
    payload: SubmitPromoConfigurationApiRequest
  ) {
    const presenter = container.resolve(PromoConfigurationPresenter);
    return presenter.createPromoConfiguration(payload);
  }

  @Action
  public async editPromoConfiguration(
    payload: SubmitPromoConfigurationApiRequest
  ) {
    const presenter = container.resolve(PromoConfigurationPresenter);
    return presenter.editPromoConfiguration(payload);
  }

  @Action
  public getPromoConfigurationList(params: PromoConfigurationListRequest) {
    const presenter = container.resolve(PromoConfigurationPresenter);
    return presenter.getPromoConfigurationList(params);
  }

  @Action
  public getDetailPromoConfiguration(id: number): Promise<PromoConfiguration> {
    const presenter = container.resolve(PromoConfigurationPresenter);
    return presenter.getDetailPromoConfiguration(id);
  }

  @Action
  public getPromoActive(params: PromoConfigurationListRequest) {
    const presenter = container.resolve(PromoConfigurationPresenter);
    return presenter.getPromoActive(params);
  }
}

export const PromoConfigurationController = getModule(PromoConfigurationStore);
