import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogChoosePayment = _resolveComponent("DialogChoosePayment")!
  const _component_PaymentCash = _resolveComponent("PaymentCash")!
  const _component_PaymentQr = _resolveComponent("PaymentQr")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.showSuccessBookingDialogPayment)
      ? (_openBlock(), _createBlock(_component_DialogChoosePayment, {
          key: 0,
          textHeader: _ctx.textHeader,
          billType: _ctx.billType
        }, null, 8, ["textHeader", "billType"]))
      : _createCommentVNode("", true),
    (_ctx.showDialogPaymentCash)
      ? (_openBlock(), _createBlock(_component_PaymentCash, {
          key: 1,
          billType: _ctx.billType,
          onClosePayment: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('successPayment')))
        }, null, 8, ["billType"]))
      : _createCommentVNode("", true),
    (_ctx.showDialogPaymentQr)
      ? (_openBlock(), _createBlock(_component_PaymentQr, {
          key: 2,
          billType: _ctx.billType,
          onClosePayment: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('successPayment')))
        }, null, 8, ["billType"]))
      : _createCommentVNode("", true)
  ], 64))
}