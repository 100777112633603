
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { PickupManifestController } from "@/app/ui/controllers/PickupManifestController";

@Options({
  emits: [
    "change",
    "update:modelValue",
    "focus",
    "filter",
    "close",
    "chevron",
    "submit:driver",
    "submit:licensePlate",
    "inputFilter"
  ],
  components: {
    Skeleton
  },
  props: {
    options: {
      default: [],
      type: Array
    },
    modelValue: {
      required: true
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    isShowErrorMessage: {
      default: true,
      type: Boolean
    },
    isShowErrorIndicator: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: "",
      type: String
    },
    keyName: {
      default: "name",
      type: String
    },
    keyValue: {
      default: "value",
      type: String
    },
    longTrim: {
      default: 50,
      type: Number
    },
    secondKeyName: {
      default: "",
      type: String
    },
    addDriver: {
      default: false,
      type: Boolean
    },
    addLicensePlate: {
      default: false,
      type: Boolean
    },
    serverSide: {
      default: true,
      type: Boolean
    },
    errorCaption: {
      default: "",
      type: String
    },
    addAccountNumber: {
      default: false,
      type: Boolean
    },
    titleInside: {
      default: "",
      type: String
    },
    openIcon: {
      default: "chevron-up",
      type: String
    },
    closeIcon: {
      default: "chevron-down",
      type: String
    },
    maxLength: {
      type: Number
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  },
  watch: {
    modelValue() {
      this.filterSearch =
        this.modelValue[this.props.keyName] || this.modelValue;
    }
  }
})
export default class LpInputSearch extends Vue {
  props: any = this.$props;

  // logic open close menu
  isChevron = false;
  isOpenMenu = false;
  onFilter = false;
  onOpenSelect(value: boolean) {
    if (!value) {
      this.$emit("close", "");
    }
    this.isOpenMenu = value;
  }
  closeSelect() {
    this.onOpenSelect(false);
    if (!this.filterSearch) {
      this.$emit("update:modelValue", "");
      this.$emit("change", "");
      this.filterSearch = "";
    } else
      this.filterSearch =
        this.props.modelValue[this.props.keyName] || this.props.modelValue;
  }
  //logic filter search
  filterSearch =
    this.props.modelValue[this.props.keyName] || this.props.modelValue;
  get dataOptions() {
    if (this.props.serverSide || !this.onFilter) {
      return this.props.options;
    }
    const tempOptions = this.props.options.filter((option: any) => {
      return option[this.props.keyName]
        ? option[this.props.keyName]
            .toLowerCase()
            .includes(this.filterSearch.toLowerCase())
        : option.toLowerCase().includes(this.filterSearch);
    });
    if (this.props.allEnabled && !this.filterSearch) {
      const allObject: any = {};
      allObject[this.props.keyName] = "All";
      allObject[this.props.keyValue] = "all";
      tempOptions.unshift(allObject);
    }
    return tempOptions;
  }
  onSearch(e: any) {
    this.filterSearch = e.target.value;
    this.$emit("inputFilter", e.target.value);
    this.isOpenMenu = false;
  }
  onFocus() {
    if (!this.props.isDisabled) {
      this.$emit("focus", "");
      this.openPlus = false;
      this.onFilter = false;
      this.driver = {
        name: "",
        phone: ""
      };
      this.licensePlate = "";
    }
  }

  // logic for selected item
  isSelected(item: any) {
    return this.props.modelValue[this.props.keyValue]
      ? this.props.modelValue[this.props.keyValue] === item[this.props.keyValue]
      : this.props.modelValue === item;
  }
  onChooseSelect(item: any) {
    if (this.isSelected(item)) {
      this.$emit("update:modelValue", "");
      this.$emit("change", "");
      this.filterSearch = "";
    } else {
      this.$emit("update:modelValue", item);
      this.$emit("change", item);
      this.filterSearch = item[this.props.keyName] || item;
    }
    this.onOpenSelect(false);
  }
  ellipsisValue(value: string) {
    return ellipsisString(value, this.props.longTrim);
  }

  // handle add driver and add license plate
  openPlus = false;
  driver = {
    name: "",
    phone: ""
  };
  licensePlate = "";
  handleOpenPlus(value: boolean) {
    this.openPlus = value;
  }
  clearDriverForm() {
    this.driver.name = "";
    this.driver.phone = "";
  }
  clearLicensePlateForm() {
    this.licensePlate = "";
  }
  async submitDriver() {
    if (this.driver.name && this.driver.phone) {
      const resp = await PickupManifestController.addDriver(this.driver);
      if (resp) {
        this.$emit("submit:driver", this.driver);
        this.clearDriverForm();
        this.handleOpenPlus(false);
      }
    }
  }
  submitLicensePlate() {
    if (this.licensePlate) {
      this.$emit("submit:licensePlate", this.licensePlate);
      this.clearLicensePlateForm();
      this.handleOpenPlus(false);
    }
  }
  get isError() {
    return PickupManifestController.errorModal;
  }
  get errorCause() {
    return PickupManifestController.errorCause;
  }
  handleErrorClose() {
    PickupManifestController.setErrorModal(false);
  }
  filterInputDriverPhone(e: any) {
    e.target.value = e.target.value.replace(/[\D]+/g, "");
  }
  filterInputDriverName(e: any) {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
  }
  filterInputLicensePlate(e: any) {
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
  }

  handleOpenEditBeneficiaryAccount() {
    this.$router.push("/cod-income/beneficiary-account");
  }

  onSearchFilter() {
    if (this.props.isDisabled || !this.filterSearch) {
      return;
    }
    this.onFilter = true;
    this.$emit("filter", this.filterSearch);
    this.isOpenMenu = true;
  }
}
