const parseQRCode = (val: any) => {
    let shipmentId = "";
    const tempValue = val.toUpperCase().trim();
    try {
      const valueInJSON = JSON.parse(tempValue);
      if (
        Object.prototype.hasOwnProperty.call(valueInJSON, "PARTNER_NAME") &&
        Object.prototype.hasOwnProperty.call(valueInJSON, "BOOKING_ID") &&
        valueInJSON.PARTNER_NAME === "TOKOPEDIA"
      ) {
        shipmentId = valueInJSON.BOOKING_ID[0];
      } else {
        shipmentId = tempValue;
      }
    } catch (err) {
      shipmentId = tempValue;
    }

    return shipmentId;
};
export default parseQRCode;
  