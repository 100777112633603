import ApiService from "@/app/infrastructures/services/ApiService";
import { PromoConfigurationRepositoryInterface } from "@/data/persistences/repositories/contracts/PromoConfigurationRepositoryInterface";
import { PromoConfigurationMapper } from "@/data/persistences/mappers/PromoConfigurationMapper";
import { PromoConfigurationEndpoint } from "@/app/infrastructures/endpoints/horde/PromoConfigurationEndpoint";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import {
  PromoConfiguration,
  PromoConfigurationList
} from "@/domain/entities/PromoConfiguration";
import {
  PromoConfigurationListRequest,
  SubmitPromoConfigurationApiRequest
} from "@/data/payload/api/PromoConfigurationApiRequest";
export class PromoConfigurationRepository
  implements PromoConfigurationRepositoryInterface {
  private service: ApiService;
  private mapper: PromoConfigurationMapper;
  private endpoints: PromoConfigurationEndpoint;

  constructor(
    service: ApiService,
    mapper: PromoConfigurationMapper,
    endpoints: PromoConfigurationEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async createPromoConfiguration(
    payload: SubmitPromoConfigurationApiRequest
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.promoConfigApi(),
      {},
      payload
    );
    return this.mapper.convertSubmitDataFromApi(resp);
  }
  public async editPromoConfiguration(
    payload: SubmitPromoConfigurationApiRequest
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.promoConfigApi(`${payload.promoId}`),
      {},
      payload
    );
    return this.mapper.convertSubmitDataFromApi(resp);
  }

  async getPromoConfigurationList(
    params: PromoConfigurationListRequest
  ): Promise<PromoConfigurationList> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getPromoConfigurationList(params)
    );

    return this.mapper.convertPromoConfigurationListFromApi(res);
  }

  public async getDetailPromoConfiguration(
    id: number
  ): Promise<PromoConfiguration> {
    const res = await this.service.invoke(
      "get",
      this.endpoints.promoConfigApi(`${id}`),
      {}
    );
    return this.mapper.convertDetailPromoConfigurationFromApi(res);
  }

  async getPromoActive(
    params: PromoConfigurationListRequest
  ): Promise<PromoConfigurationList> {
    const res = await this.service.invoke(
      "GET",
      this.endpoints.getPromoActive(params)
    );

    return this.mapper.convertPromoConfigurationListFromApi(res);
  }
}
