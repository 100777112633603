
import { Vue, prop } from "vue-class-component";

class Props {
  type = prop<string>({
    type: String,
    default: "cod"
  });
}
export default class WarningSttCod extends Vue.with(Props) {}
