
import { Vue } from "vue-class-component";
import { PaymentProps } from "@/app/ui/components/dialog-payment/payment-props";
import { PaymentController } from "@/app/ui/controllers/PaymentController";

export default class DialogPaymentLayoutHeader extends Vue.with(PaymentProps) {
  get sttNumber() {
    return PaymentController.sttNumber;
  }
}
