import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-24 mb-6 z-20 mr-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_tariff = _resolveComponent("check-tariff")!
  const _component_select_receipt_language = _resolveComponent("select-receipt-language")!
  const _component_Book = _resolveComponent("Book")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_check_tariff, {
      dataCalculation: _ctx.dataCalculation,
      isDisabled: _ctx.isDisabled,
      payload: _ctx.payload,
      discountData: _ctx.discount,
      formType: _ctx.formType,
      isReverseJourney: _ctx.isReverseJourney,
      bookingTypePromo: _ctx.bookingTypePromoAllowed,
      isSttForeign: _ctx.isSttForeign,
      class: " mr-12"
    }, null, 8, ["dataCalculation", "isDisabled", "payload", "discountData", "formType", "isReverseJourney", "bookingTypePromo", "isSttForeign"]),
    (_ctx.isProductInterpack)
      ? (_openBlock(), _createBlock(_component_select_receipt_language, {
          key: 0,
          class: "pr-4"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Book, {
      isDisabled: _ctx.isDisabled,
      payload: _ctx.payload,
      bookingType: _ctx.bookingType,
      onBooking: _ctx.updateEstimationPrice,
      isDiscountActive: _ctx.discount.status,
      onSetDiscount: _ctx.setDiscount
    }, null, 8, ["isDisabled", "payload", "bookingType", "onBooking", "isDiscountActive", "onSetDiscount"])
  ]))
}