
import { Options, Vue, prop } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import Book from "./book.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { prefixAlgoEstimationShow } from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import ModalErrorBalance from "@/app/ui/views/shipment/booking/component/modal-error-balance.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import SelectReceiptLanguage from "@/app/ui/views/shipment/booking/create-booking/payment-detail/select-receipt-language.vue";
import Discount from "../payment-detail/components/discount.vue";
import {
  PromoConfigurationList,
  PromoConfigurationListData
} from "@/domain/entities/PromoConfiguration";
import { PromoConfigurationController } from "@/app/ui/controllers/PromoConfigurationController";
import { PromoConfigurationListRequest } from "@/data/payload/api/PromoConfigurationApiRequest";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { EstimationPrice } from "@/domain/entities/Booking";
import prefixAlgoCheck from "@/app/infrastructures/misc/common-library/PrefixAlgoCheck";
import { promoPrefix } from "@/app/ui/views/shipment/booking/booking-utils";
import startCase from "lodash/startCase";
import { GetEstimationPrice } from "@/data/payload/api/BookingRequest";
import { DetailDiscount } from "@/domain/entities/Tariff";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";

interface DiscountInterface {
  status: boolean;
  data: PromoConfigurationListData[];
}

class Props {
  isBooking = prop<boolean>({
    default: false
  });
  onNextable = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  bookingType = prop<string>({
    default: "",
    type: String
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  accountRefAndCommodity = prop<any>({
    default: {},
    type: Object
  });
  isShowArrow = prop<any>({
    default: true,
    type: Boolean
  });
  isPrintAble = prop<boolean>({
    default: false,
    type: Boolean
  });
  algoId = prop<string>({
    default: "",
    type: String
  });
  comodityCode = prop<string>({
    default: "",
    type: String
  });
  isShowWeightCost = prop<boolean>({
    default: false,
    type: Boolean
  });
  totalItem = prop<any>({
    default: "0",
  });
  totalGrossWeight = prop<any>({
    default: "0",
  });
  totalDimensionWeight = prop<any>({
    default: "0",
  });
  chargeableWeight = prop<any>({
    default: "0",
  });
  isVolumeWeight = prop<boolean>({
    default: false,
    type: Boolean
  });
  isCampaign = prop<boolean>({
    default: false,
    type: Boolean
  });
  isGrossWeight = prop<boolean>({
    default: false,
    type: Boolean
  });
  isFromBooking = prop<boolean>({
    default: true,
    type: Boolean
  });
}

@Options({
  emits: ["updateStatusErrorEmbargo"],
  components: {
    SelectReceiptLanguage,
    Tooltip,
    Book,
    ModalErrorBalance,
    Discount
  },
  watch: {
    "payload.productName": function() {
      this.checkDiscountActive();
      this.resetEstimasiDiscount();
      this.resetDiscount();
    },
    "payload.destinationCity": function() {
      this.checkDiscountActive();
      this.resetEstimasiDiscount();
      this.resetDiscount();
    },
    "payload.sttGrossWeight": function() {
      this.checkDiscountActive();
      this.resetEstimasiDiscount();
      this.resetDiscount();
    },
    "payload.sttVolumeWeight": function() {
      this.checkDiscountActive();
      this.resetEstimasiDiscount();
      this.resetDiscount();
    }
  },
  directives: {
    "click-outside": {
      beforeMount: (el: any, binding: any) => {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: (el: any) => {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Calculation extends Vue.with(Props) {
  isShow = false;
  showDetailloading = false;

  unmounted() {
    BookingController.setDefaultEstimationPrice();
  }

  get isCODOnly() {
    return this.payload.isCOD === true && this.payload.isDFOD === false;
  }

  async showDetail() {
    this.isShow = !this.isShow;
    if (this.isShow) {
      this.showDetailloading = true;
      const resultData = await this.getEstimationPrice();
      if (resultData) {
        this.setLabelTotalTarif = this.isCODOnly || (this.payload.isDFOD && !(this.discountData.status || this.dataDetail.isPromo))
            ? this.dataDetail.codAmount
            : this.dataDetail.totalTariff;
      } else {
        this.setLabelTotalTarif = 0;
      }
    }
  }

  next() {
    if (this.indexElement === this.shipmentList.length - 1) {
      return false;
    }
    ShipmentBookingController.setIndexElement(this.indexElement + 1);
    dataLayer("shipment_next_detail_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  previous() {
    if (this.indexElement === 0) {
      return false;
    }
    ShipmentBookingController.setIndexElement(this.indexElement - 1);
    dataLayer("shipment_prev_detail_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  get shipmentList() {
    return BookingController.shipmentIdList.data;
  }

  get indexElement() {
    return ShipmentBookingController.indexElement;
  }

  async updateEstimationPrice() {
    if (
      this.isBalanceUnderLimitForPos &&
      prefixAlgoEstimationShow(
        this.shipmentList[this.indexElement].shipmentAlgoId
      )
    ) {
      this.balanceUnderLimit = true;
      return;
    }
    const resultData = await this.getEstimationPrice();
    if (resultData) {
      this.setLabelTotalTarif = this.isCODOnly || (this.payload.isDFOD && !(this.discountData.status || this.dataDetail.isPromo))
            ? this.dataDetail.codAmount
            : this.dataDetail.totalTariff;
    } else {
      this.setLabelTotalTarif = 0;
    }

    this.balanceUnderLimit = this.isBalanceLessThenEstimationForPos;
    return resultData;
  }

  async getEstimationPrice() {
    if (!this.isDisabled) {
      this.showDiscountNominal = true;
      const piecesDelivery: any = [];
      this.payload.sttPieces.map((item: any) => {
        piecesDelivery.push({
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_length: item.stt_piece_length,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_width: item.stt_piece_width,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_height: item.stt_piece_height,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_gross_weight: item.stt_piece_gross_weight
        });
      });

      const accountType =
        AccountController.accountData.account_type === "partner"
          ? "pos"
          : AccountController.accountData.account_type;

      const payload = new GetEstimationPrice({
        originId: this.payload.originDistrict,
        destinationId: this.payload.destinationDistrict,
        commodityId:
          typeof this.payload.commodityCode === "object"
            ? this.payload.commodityCode.id
            : this.payload.commodityId,
        productType: this.payload.productName,
        accountType: this.prefixShipmentCod || this.shipmentFavorite ? "client" : accountType,
        accountRefId: (this.prefixShipmentCod && this.payload.shipmentId.startsWith("AR")) || this.shipmentFavorite
          ? 2311
          : this.prefixC1Cod,
        goodsPrice: this.payload.estimationPrice,
        insuranceType: this.payload.insurance,
        isWoodpacking: this.payload.isWoodpack,
        isHaveTaxId: !!this.payload.taxNumber,
        pieces: piecesDelivery,
        isCod: this.prefixShipmentCod ? this.prefixShipmentCod  : this.payload.isCOD,
        isDfod: this.payload.isDFOD,
        codHandling: this.payload.codHandling,
        codAmount: this.prefixShipmentCod ? this.payload.codAmount : this.codAmountShipmentPrefixFavorite,
        isDisablePromo: false,
        promoAppliedTo: "shipment",
        shipmentPrefix: promoPrefix(this.payload.shipmentId),
        discountFavoritePercentage: this.payload.discountFavoritePercentage
      });

      if (this.prefixShipmentCod || this.isShipmentFavoriteCOD) {
        await BookingController.getEstimationPriceCodRetail(
          new GetEstimationPrice({
            ...payload,
            isWoodpacking: false,
            originId: this.payload.destinationDistrict,
            destinationId: this.payload.originDistrict,
            isCod: false,
            isDfod: false,
            isDisablePromo: true
          })
        );
      }
      return BookingController.getEstimationPrice(payload);
    }
  }

  get isShipmentFavoriteCOD() {
    return this.shipmentFavorite && (this.payload.isCOD || this.payload.isDFOD);
  }

  get codAmountShipmentPrefixFavorite() {
    return this.shipmentFavorite && this.payload.isCOD ? this.payload.codAmount : 0;
  }

  get codReturnValue(): number {
    return BookingController.estimationCodRetail.returnFee;
  }
  get prefixShipmentCod(): boolean {
    return !!(prefixAlgoCheck({
      prefix: "ARA,ARB",
      shipmentId: this.payload.shipmentId
    }) || this.payload.shipmentC1Cod)
  }

  get shipmentFavorite() {
    return this.payload.shipmentId.startsWith("AP") || this.payload.shipmentId.startsWith("AS");
  }
  
  get prefixC1Cod() {
    return this.payload.shipmentC1Cod 
      ? this.payload.sttClientIdBranch 
      : AccountController.accountData.account_type_detail.id;
  }

  get dataDetail() {
    if (!this.isDisabled) {
      return {
        ...BookingController.estimationPrice,
        discountBoards: BookingController.estimationPrice.listDiscount
          .filter((item: DetailDiscount) => item.pdcCategory === 'tactical' || item.pdcCategory === 'campaign')
      };
    }

    return {
      ...new EstimationPrice(),
      totalTariff: 0,
      discount: 0,
      isPromo: false
    };
  }

  bigLabelTotalTariff = 0;
  get getLabelTotalTarif() {
    return this.bigLabelTotalTariff;
  }

  set setLabelTotalTarif(value: number) {
    this.bigLabelTotalTariff = value;
  }

  get setLabelTotalTarif() {
    return this.bigLabelTotalTariff;
  }

  isOpenTooltip = false;
  onOpenTooltip(value: boolean) {
    this.isOpenTooltip = value;
  }

  onCloseAnywhere(value: boolean) {
    if (!this.showDetailloading) {
      this.isShow = value;
    } else {
      this.showDetailloading = false;
    }
  }

  currency(curr: number) {
    return formatPrice(curr);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    return (
      BalanceController.balanceData.walletBalance <
      this.dataProfile.limit_rule_min_balance
    );
  }
  get isBalanceLessThenEstimationForPos() {
    return (
      BalanceController.balanceData.walletBalance < this.setLabelTotalTarif
    );
  }

  get isInterpack() {
    return this.payload.productName === "INTERPACK";
  }

  showDiscountNominal = true;
  discountData: DiscountInterface = {
    status: false,
    data: []
  };

  async checkDiscountActive() {
    if (
      this.payload.originCity &&
      this.payload.destinationCity &&
      this.payload.productName &&
      this.payload.sttGrossWeight > 0
    ) {
      try {
        const promotion: PromoConfigurationList = await PromoConfigurationController.getPromoActive(
          new PromoConfigurationListRequest({
            page: 1,
            limit: 2,
            status: "active,nearly-expired",
            origin: this.payload.originCity,
            destination: this.payload.destinationCity,
            productName: this.payload.productName,
            grossWeight: this.payload.sttGrossWeight,
            volumeWeight: this.payload.sttVolumeWeight,
            chargeableWeight: this.payload.sttChargeableWeight,
            shipmentPrefix: promoPrefix(this.payload.shipmentId),
            isTotalData: false,
            appliedTo: "shipment"
          })
        );

        const promotionList = promotion.data;
        const isShipment = promotion.data[0].promoConfigurationAppliedTo.includes(
          "shipment"
        );
        const prefixAllowed = promotion.data[0].promoConfigurationAppliedShipments.includes(
          promoPrefix(this.payload.shipmentId)
        );
        this.calculatePromo(promotionList, isShipment, prefixAllowed);
      } catch (err) {
        this.discountData.status = false;
      }
    }
  }

  calculatePromo(
    promotionList: Array<any>,
    isShipment: boolean,
    prefixAllowed: boolean
  ) {
    if (promotionList.length > 0 && isShipment && prefixAllowed) {
      this.discountData = {
        status: true,
        data: promotionList.map((item: PromoConfigurationListData) => {
          return new PromoConfigurationListData({
            promoConfigurationName: `${
              item.promoConfigurationName
            } sampai dengan ${formatDateWithoutTime(
              item.promoConfigurationEndDate
            )}`,
            promoConfigurationDiscountNominal:
              item.promoConfigurationDiscountNominal
          })
        }) 
      };
    } else {
      this.resetDiscountNominal();
    }
  }

  resetEstimasiDiscount() {
    this.setLabelTotalTarif = 0;
    BookingController.setEstimationPrice(
      new EstimationPrice({
        totalTariffAfterDiscount: 0,
        totalTariff: 0,
        isPromo: false
      })
    );
  }

  resetDiscount() {
    if (this.payload.sttGrossWeight <= 0 || this.payload.sttVolumeWeight <= 0) {
      this.resetDiscountNominal();
    }
  }

  resetDiscountNominal() {
    this.discountData = {
      status: false,
      data: []
    };
  }

  formatDateWithoutTime(value: string) {
    return formatDateWithoutTime(value);
  }

  refs: any = {};
  onOpenTooltipPromo(event: any, index: number) {
    const refs: any = this.$refs;
    refs[`infoPromo${index}`].toggle(event);
  }

  startCase(value: string) {
    return startCase(value);
  }

  get additionalRate(): string {
    return this.currency(
      this.dataDetail.commoditySurcharge +
        this.dataDetail.heavyWeightSurcharge +
        this.dataDetail.documentSurcharge +
        this.dataDetail.woodpackingRates
    );
  }
  
  showDownloadChoice() {
      this.isShow = !this.isShow;
  }

  onCloseAnywhereCost(value: boolean) {
      this.isShow = value;
  }

  toggleTooltip(event: any) {
      this.showDownloadChoice();
      const refs: any = this.$refs;
      refs["opTooltip"].toggle(event);
  }

  hideToggle(event: any) {
      const refs: any = this.$refs;
      refs["opTooltip"].hide(event);
  }
}
