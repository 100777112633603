
import { prop, Vue } from "vue-class-component";

class Props {
  type = prop<string>({
    type: String,
    default: ""
  });
  isValid = prop<boolean>({
    type: Boolean,
    default: false
  });
}

export default class DestinationVendorNinja extends Vue.with(Props) {}
