
import { Options, Vue, prop } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { maxLengthWeight } from "@/app/ui/views/shipment/booking/create-booking-v2/components/modules";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ProductConfigurationRuleRequestParams } from "@/data/payload/api/ProductApiRequest";
import { ProductConfigurationRuleList } from "@/domain/entities/Product";
import { maxKg, volumeWeightForProduct, totalGrossWeightSikat, totalGrossWeightNonSikat } from "../../booking-utils";

class Props {
  formType = prop<any>({
    required: true
  });
  modelValue = prop<any>({
    required: true
  });
  listKGsProp = prop<any>({
    required: true
  });
  errorEmbargo = prop<any>({
    required: true
  });
  showWarningHwsProp = prop<boolean>({
    required: true
  });
  isErrorHws = prop<boolean>({
    required: true
  });
  isCampaign = prop<boolean>({
    required: true
  });
  productConfigurationRuleListProp = prop<any>({
    required: true
  });
}

@Options({
  emits:["update:listKGsProp", "onUpdateEmbargoStatusFail", "onSetErrorHws",
         "setFieldIndex", "update:showWarningHwsProp",
         "updateTotalChargeableWeight", "updateTotalGrossWeight", "updateTotalVolumeWeight",
         "updateTotalCampaignChargeableWeight"
        ],
  components: {
    Tooltip,
    maxLengthWeight
  },
  watch: {
    totalChargeableWeight(newValue: any) {
      this.$emit("updateTotalChargeableWeight", newValue);
    },
    totalGrossWeight(newValue: any) {
      this.$emit("updateTotalGrossWeight", newValue);
    },
    totalVolumeWeight(newValue: any) {
      this.$emit("updateTotalVolumeWeight", newValue);
    },
    totalCampaignChargeableWeight(newValue: any) {
      this.$emit("updateTotalCampaignChargeableWeight", newValue);
    },
  }
})
export default class SectionGoodsWeight extends Vue.with(Props) {
  isOpenTooltipWeight = false;
  isHoverDelete = false;
  tempIndexToRemove = -1;
  cacheIndex = 0;
  indexVolume = 0;
  isMaxLengthGrossWeight: any = null;
  division = 6000;

  async mounted() {
    await this.fetchProductConfigurableRule();
    BookingController.setDefaultManualBooking();
    BookingController.setForceRouter(false);
    BookingController.removeWeightDimensions();
    BookingController.setWeightDimensions({
      grossWeight: !this.$route.query.weight ? '0' : this.$route.query.weight,
      dimension: !this.$route.query.length
        ? {
            length: 10,
            width: 10,
            height: 10
          }
        : {
            length: Number(this.$route.query.length),
            width: Number(this.$route.query.width),
            height: Number(this.$route.query.height)
          },
      volumeWeight: 0.16,
      convertVolumeWeight: "0,16",
      errorHws: false
    });
    this.listKGs = BookingController.weightDimensions.data;
    this.calculationVolumeWeight(0);
  }

  get showWarningHws() {
    return this.showWarningHwsProp;
  }

  set showWarningHws(value) {
    this.$emit("update:showWarningHwsProp", value);
  }

  get listKGs() {
    return this.listKGsProp;
  }

  set listKGs(value) {
    this.$emit("update:listKGsProp", value);
  }

  formatFloat(value: string) {
    const regex = value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");

    this.isMaxLengthGrossWeight = maxLengthWeight(regex);
    return regex;
  }

  get isMaxAddKgs() {
    return this.listKGs.length < 15;
  }

  addKGs() {
    const weight = (10 * 10 * 10) / this.division;
    const splitVolumeWeight = weight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = splitVolumeWeight[0];
    const behind = splitVolumeWeight[1]
      ? "," + splitVolumeWeight[1].slice(0, 2)
      : "";
    this.listKGs.push({
      grossWeight: '0',
      dimension: {
        length: 10,
        width: 10,
        height: 10
      },
      volumeWeight: weight,
      convertVolumeWeight: front + behind,
      errorHws: false
    });
  }

  onSetErrorHws() {
    this.showWarningHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length < 2 &&
      this.listKGs[0]?.grossWeight?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
    this.listKGs.forEach((key: any, index: number) => {
      this.listKGs[index].errorHws =
        this.configHeavyWeightMinimum.minimumExist &&
        +key.grossWeight?.replace(",", ".") >=
          this.configHeavyWeightMinimum.heavyWeightMinimum &&
        this.listKGs.length > 1;
    });
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get isVendorNinjaDestination() {
    return BookingController.isVendorNinjaDestination;
  }

  get validateTotalGrossWeight(): boolean {
    const total = Number(this.totalGrossWeight.replace(",", "."));
    return total > maxKg;
  }

  get validateTotalVolumeWeight(): boolean {
    let total = 0;
    this.listKGs.map((e: any) => (total += Number(e.volumeWeight)));
    return total > maxKg;
  }

  get allowToAddMoreKoliVolume(): boolean {
    return this.validateTotalVolumeWeight;
  }

  get allowToAddMoreKoli(): boolean {
    return this.validateTotalGrossWeight;
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail
    };
  }

  // configurable rule product

  get productConfigurationRuleList(): ProductConfigurationRuleList {
    return this.productConfigurationRuleListProp;
  };

  set productConfigurationRuleList(value) {
    this.$emit('update:productConfigurationRuleListProp', value);
  }

  async fetchProductConfigurableRule() {
    try {
      this.productConfigurationRuleList = await ProductController.fetchProductConfigurationRule(
        new ProductConfigurationRuleRequestParams()
      );
    } catch (err) {
      this.productConfigurationRuleList = new ProductConfigurationRuleList();
    }
  }

  get totalGrossWeight() {
    let indicator = 0;
    this.arrGrossWeight.map((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  get totalChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (
      this.totalGrossWeight.includes(",") ||
      this.totalVolumeWeight.includes(",")
    ) {
      total = this.compareTotalGrossAndVolume(x, y);
    } else {
      total = `${x > y ? x : y}`;
    }
    return total;
  }

  get arrVolumeWeight() {
    return this.listKGs.map((key: any) => {
      key.volumeWeight = Number(key.volumeWeight.toString().replace(/,/g, "."));
      return key.volumeWeight;
    });
  }

  get totalVolumeWeight() {
    let indicator = 0;
    this.arrVolumeWeight.map((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  compareTotalGrossAndVolume(x: number, y: number) {
    let total = 0;
    if (x > y) {
      total = this.totalGrossMoreThanVolume(x, y);
    } else {
      total = this.volumeMoreThanTotalGross(x, y);
    }
    return total;
  }

  totalGrossMoreThanVolume(x: number, y: number) {
    let total = 0;
    const split = this.totalGrossWeight.split(",");
    let result = x - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (x < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleActive.parameter
          ? Math.ceil(x)
          : Math.floor(x);
    }

    return total;
  }

  volumeMoreThanTotalGross(x: number, y: number) {
    let total = 0;
    const split = this.totalVolumeWeight.split(",");
    let result = y - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (y < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleActive.parameter
          ? Math.ceil(y)
          : Math.floor(y);
    }

    return total;
  }

  get productConfigRuleActive() {
    const isRetailAllowed =
      this.isFormBookManual && this.getAccount.accountType === "partner";

    return this.productConfigurationRuleList.findProductConfigRule(
      this.modelValue.productName,
      this.modelValue.clientName?.id || this.detailClientID.id,
      isRetailAllowed
    );
  }

  get isFormBookManual() {
    return this.formType === "manual";
  }

  get detailClientID() {
    return ClientManagementController.detailClientManagement;
  }

  get configHeavyWeightMinimum() {
    return HeavyWeightSurchargeController.heavyWeightMinimum;
  }

  get arrGrossWeight() {
    return this.listKGs.map((key: any) => {
      return Number(key.grossWeight.toString().replace(/,/g, "."));
    });
  }

  removeKGs(index: number) {
    this.isHoverDelete = false;
    this.tempIndexToRemove = index;
    if (this.tempIndexToRemove > -1) {
      this.listKGs.splice(this.tempIndexToRemove, 1);
      this.tempIndexToRemove = -1;
    }

    this.$emit('onSetErrorHws');
  }

  isHoverEnter(indexParam: number) {
    this.isHoverDelete = true;
    this.cacheIndex = indexParam;
  }

  setOnValidationHws(value = "", index = 0) {
    this.showWarningHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length < 2 &&
      +value?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
    this.listKGs[index].errorHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length > 1 &&
      +value?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
  }

  calculationVolumeWeight(index: number) {
    this.indexVolume = index;
    if (!this.listKGs[index]) return
    const length = this.listKGs[index]?.dimension?.length ?? 10;
    const width = this.listKGs[index]?.dimension?.width ?? 10;
    const height = this.listKGs[index]?.dimension?.height ?? 10;
    const dimension = length * width * height;

    const volume = volumeWeightForProduct(this.modelValue.productName);
    this.listKGs[index].volumeWeight = dimension / volume;
    this.division = volume;

    const split = this.listKGs[index].volumeWeight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    this.listKGs[index].convertVolumeWeight = front + behind;
  }

  get isGrossWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x > y;
  }

  get totalCampaignChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (this.totalGrossWeight || this.totalVolumeWeight) {
      total = totalGrossWeightSikat(
        this.isCampaign,
        x,
        y,
        this.totalGrossWeight,
        this.totalVolumeWeight
      );
    } else {
      total = totalGrossWeightNonSikat(this.isCampaign, x, y);
    }
    return total;
  }
}
