import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "border border-dashed bg-yellow-lp-400 rounded-md py-2 px-4 flex items-center gap-x-4",
  style: {"border-color":"#FAB713","background-image":"linear-gradient(180deg, #FEFAE8, white)"}
}
const _hoisted_2 = { class: "text-12px font-normal" }
const _hoisted_3 = { class: "text-14px font-semibold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("img", {
      src: require(`@/app/ui/assets/svg/information.svg`),
      alt: "info"
    }, null, 8, ["src"]),
    _createVNode("div", _hoisted_2, [
      _createVNode("p", _hoisted_3, " Informasi Penting " + _toDisplayString(_ctx.type === "cod" ? "COD" : "DFOD") + " " + _toDisplayString(_ctx.booking === "retail" ? "Retail" : "CA") + "! ", 1),
      (_ctx.type === 'cod')
        ? (_openBlock(), _createBlock("p", _hoisted_4, " POS tidak perlu menagih biaya pengiriman ke Pengirim "))
        : (_openBlock(), _createBlock("p", _hoisted_5, " POS tidak perlu menagih asuransi biaya pengiriman ke Pengirim hanya menagih biaya Asuransi Basic jika ditambahkan "))
    ])
  ]))
}