
import { Vue, Options } from "vue-class-component";
import { BookingController } from "@/app/ui/controllers/BookingController";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import ModalDetailShipment from "@/app/ui/views/shipment/booking/create-booking/modal-detail-shipment.vue";
import ModalAddShipmentDestination from "./modal-add-shipment-destination.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
import custNameAlgoEstimationShow from "@/app/infrastructures/misc/common-library/CustNameAlgoEstimationShow";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { DistrictClientRequest } from "@/data/payload/api/LocationRequest";
import { DistrictClient } from "@/domain/entities/Location";
import { ShipmentId, ShipmentIdList } from "@/domain/entities/Booking";
import { Pagination } from "@/domain/entities/Pagination";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import parseQRCode from "@/app/infrastructures/misc/common-library/ParseQRcode";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

@Options({
  components: {
    CustomDropdown,
    Tooltip,
    ModalDetailShipment,
    ModalAddShipmentDestination,
    Skeleton
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.isBookingSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class AddShipmentId extends Vue {
  isBookingSuccess = false;

  // shipment id
  shipmentId = "";

  async fetchShipmentId(status: string) {
    this.handleError();
    const shipmentId = this.shipmentId;
    if (shipmentId.startsWith("TKP01")) {
      const shipment: any = await BookingController.getShipmentDetailFromMiddleware(
        {
          ticketCode: shipmentId
        }
      );
      if (shipment) {
        playNotification("success");
        const origin = LocationController.districtDetail;
        const shipmentData = BookingController.shipmentIdList.data;
        const newShipmentData = []
        let i = 0;
        for (const el of shipmentData) {
          try {
            const district = await LocationController.getDistrictClient({
              id: el.shipmentDestinationCityKR,
              args: new DistrictClientRequest({
                clientId: this.clientIdForCourierRecommendation
              })
            });
            this.setDistrict(district, i);
          } catch (error) {
            return;
          }

          i++;

          newShipmentData.push(
            new ShipmentId({
              ...el,
              shipmentSttClientId: this.clientIdForCourierRecommendation
            })
          );
        }

        BookingController.setShipmentIdList(
          new ShipmentIdList({
            pagination: new Pagination(1, 99),
            data: newShipmentData
          })
        );
      }
    } else {
      BookingController.getShipmentIdList({
        id: shipmentId,
        limit: 100,
        page: 1
      });
    }

    if (status === "inputShipmentId") {
      dataLayer("shipment_booking_pengiriman_button_clicked", {}, [
        "timestamp",
        "userType",
        "userId"
      ]);
    }
  }

  setDistrict(district: any, index: number) {
    const shipmentData = BookingController.shipmentIdList.data;
    if(district) {
      shipmentData[index].shipmentDestinationDistrict = new DistrictClient({
        id: district.id,
        name: `${district.name}, ${district.cityName}`,
        code: district.code,
        cityCode: district.cityCode, 
        type: district.type,
        vendorCode: district.vendorCode
      })
    }
  }

  get clientIdForCourierRecommendation() {
    return FlagsMedusa.client_id_courrier_recommendation_config.getValue();
  }

  get isGenerate() {
    return BookingController.isGenerate;
  }

  get isLoading() {
    return BookingController.isLoading;
  }

  // Error
  get isError() {
    return BookingController.isError;
  }

  get errorCause() {
    return BookingController.isErrorCause;
  }

  handleError() {
    BookingController.setError(false);
  }

  // sort
  sortBy = "";
  isExpand = false;
  get sortOptions() {
    return ["Tanggal STT", "No. STT"];
  }
  setSortBy(item: string) {
    if (this.sortBy === item) this.sortBy = "";
    else this.sortBy = item;
    this.isExpand = false;
  }

  // Add destination
  get showAddDestination() {
    return this.shipmentIdList[0]?.isAdjustable;
  }

  hideAddDestinationWhenIsCod() {
    const shipmentId = this.shipmentId;
    if (shipmentId.slice(0, 2) === "AC") {
      return false;
    }
  }

  get enableAddDestination() {
    return this.shipmentIdList.length < 100;
  }
  onAddDestination() {
    ShipmentBookingController.setBookingAddShipmentDestination(true);
    dataLayer("shipment_tambah_alamat_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }
  get bookingAddShipmentDestination() {
    return ShipmentBookingController.bookingAddShipmentDestination;
  }

  // tooltip
  isOpenTooltip = false;

  // booked count
  get bookedCount() {
    return this.shipmentIdList.filter(item => !!item.STT).length;
  }

  // Table
  pagination = {
    page: 1,
    limit: 20
  };

  get shipmentIdList() {
    const data = BookingController.shipmentIdList.data;
    const collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base"
    });
    this.chevronUp = data.map(() => false);
    return this.sortBy === this.sortOptions[0]
      ? [
          ...this.sortShipment(data, collator),
          ...data.filter(item => !item.STT)
        ]
      : [
          ...data.filter(item => !item.STT),
          ...this.sortShipment(data, collator),
        ];
  }

  sortShipment(data: any, collator: any) {
    let dataFiltered = [];
    if(data) {
      dataFiltered = data
        .filter((item: ShipmentId) => !!item.STT)
        .sort((a: any, b: any) =>
          collator.compare(
            a.STT ? a.STT.sttNo : "",
            b.STT ? b.STT.sttNo : ""
          )
        )
        .reverse();
    }
    
    return dataFiltered;
  }

  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-20 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any, index: any) => {
          return `<div class='text-left'>${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: this.isHideBookingIDColumn
          ? "Shipment ID / Booking ID"
          : "Shipment ID",
        styleHead: "w-40 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
          ${item.shipmentId ? item.shipmentId : item.shipmentAlgoId}
        </div>`;
        }
      },
      {
        name: "Booking ID",
        styleHead: "w-40 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
          ${item.shipmentBookingId}
        </div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-50 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
          ${(item.STT && item.STT.sttNo) || "-"}
        </div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-40 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='flex'><div class='rounded px-2 py-0 bg-gray-lp-400 text-black-lp-300'>
          ${(item.STT && item.STT.sttProductType) || item.shipmentProduct}
        </div></div>`;
        }
      },
      {
        name: "Koli",
        styleHead: "w-32 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
          ${(item.STT && item.STT.sttPieces.length) ||
            item.shipmentGoodDetail.length}
        </div>`;
        }
      },
      {
        name: "Berat",
        styleHead: "w-32 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
          ${(item.STT && item.STT.sttChargeableWeight) ||
            item.shipmentChargeableWeight} Kg
        </div>`;
        }
      },
      {
        name: "Total Tarif",
        styleHead: "w-40 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return prefixAlgoEstimationShow(item.shipmentAlgoId) ||
            custNameAlgoEstimationShow(item?.customerName)
            ? `<div class='text-left text-red-lp-100 font-bold'>${this.totalTariff(item)}</div>`
            : "<div class='text-left'>-</div>";
        }
      },
    ];
    if (this.isHideBookingIDColumn) {
      columns.splice(2, 1);
    }
    return [...columns, ...this.columnsAction];
  }

  get columnsAction() {
    return [
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-40 text-left text-12px md:text-16xpx",
        styleButton: (item: any, index: any) => {
          return item.STT?.sttId !== 0
            ? {
                outline: true,
                icon: "chevron-up",
                title: "Print",
                textColor: "black",
                color: "gray-lp-400",
                small: false,
                class: `icon-chevron-up ${
                  this.chevronUp[index] ? "" : "icon-chevron-down"
                }`,
                clickFunction: () => {
                  this.chevronUp[index] = !this.chevronUp[index];
                },
                groupOptions: [
                  {
                    icon: "printer",
                    label: "Print Thermal Resi",
                    clickFunction: () =>
                      this.print(
                        item.STT.sttId,
                        "thermal",
                        item.STT.sttProductType === "INTERPACK" ? "en" : "id"
                      )
                  },
                  {
                    icon: "document-download-outline-grey",
                    label: "Print Thermal Resi PDF",
                    clickFunction: () =>
                      this.print(
                        item.STT.sttId,
                        "thermal-pdf",
                        item.STT.sttProductType === "INTERPACK" ? "en" : "id"
                      )
                  },
                  {
                    icon: "document-download-outline-grey",
                    label: "Print Basic/Save to PDF",
                    clickFunction: () =>
                      this.print(
                        item.STT.sttId,
                        "basic",
                        item.STT.sttProductType === "INTERPACK" ? "en" : "id"
                      )
                  }
                ]
              }
            : {
                outline: false,
                icon: "chevron-next",
                title: "Booking",
                textColor: "white",
                color: "red-lp-100",
                small: false,
                class: "icon-white-next",
                clickFunction: this.onBooking
              };
        }
      }
    ]
  }

  totalTariff(item: any) {
    return item.STT && item.STT.sttTotalAmount 
      ? formatPrice(item.STT.sttTotalAmount) 
      : "-";
  }

  chevronUp: boolean[] = [];

  refs: any = "";

  get isHideBookingIDColumn() {
    return !this.shipmentIdList.find((key: any) => !!key.shipmentBookingId);
  }

  mounted() {
    this.refs = this.$refs;
    this.initPage();
  }
  initPage() {
    this.shipmentId = "";
    BookingController.resetShipmentIdList();
    ShipmentBookingController.setShowModalDetailBookingShipment(false);
    this.setGenerate(false);
  }

  async onBooking(item: any) {
    dataLayer("shipment_booking_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    ShipmentBookingController.setShowModalDetailBookingShipment(true);
    ShipmentBookingController.setDetailBookingShipment(item);
  }

  get showModalShipment() {
    return ShipmentBookingController.showModalDetailBookingShipment;
  }

  setGenerate(boolean: boolean) {
    BookingController.setGenerate(boolean);
  }

  // route navigation
  goBack() {
    this.$router.push("/shipment/booking");
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  parseInputShipmentId(value: string | number | null) {
    if (String(value).includes("#")) {
      const val = String(value).split("#")[0]
      this.shipmentId = val
      return val
    }
    this.shipmentId = parseQRCode(value);
    return value
  }

  async print(sttId: number, type: string, language = "id") {
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () => book.default.methods.printThermal(sttId, language),
      "thermal-pdf": () => book.default.methods.printBasic(sttId, 0, language, false, true),
      basic: () => book.default.methods.printBasic(sttId, 1, language)
    };

    printType[type]();
  }
}
