
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { Options, prop, Vue } from "vue-class-component";
import debounce from "lodash/debounce";
import { PaginationV2 } from "@/domain/entities/Pagination";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { OptionsClass } from "@/domain/entities/MainApp";

class Props {
  errorMandatory = prop<boolean>({
    default: false,
    type: Boolean,
    required: true
  });
  isErrorClient = prop<boolean>({
    default: false,
    type: Boolean,
    required: true
  });
  modelValue = prop<any>({
    required: true,
  })
}

@Options({
  emits: ["update:modelValue", "update:error", "getClientStatus"],
  components: {
    SelectSearchV2
  },
})
export default class SectionFilterClient extends Vue.with(Props) {
  
  //filter  
  filterClient = {
    cityOriginCode: "" as any,
    shipmentType: [] as any,
  };

  get sumFilter(): number {
    return (
      (this.filterClient.cityOriginCode?.name ? 1 : 0) +
      (this.filterClient.shipmentType.length ? 1 : 0)
    );
  }

  onResetFilter() {
    this.filterClient.cityOriginCode = "";
    this.filterClient.shipmentType = [];
    this.summaryFilter = [];
    this.fetchdDataClients("");
  }

  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }

  onChangeAdvancedFilter() {
    if (this.showPopupFilterClient) {
      this.fetchdDataClients(this.modelValue.clientName?.name || "", true);
    }
  }

  get isLoadingClients() {
    return PartnerController.loadingClientsNamePOS;
  }


  goToDetail(item: any) {
    this.modelValue.clientName = item;
    const isExist =
      this.summaryFilter.filter((key: any) => key.value === item.name).length >
      0;
    if (!isExist) {
      this.summaryFilter.push({
        value: this.modelValue.clientName?.name || item.name,
        from: "client",
      });
      this.summaryFilter = this.summaryFilter.filter(
        (key: any) =>
          (key.from === "client" && key.value === item.name) ||
          key.from !== "client"
      );
    }
    this.onShowPopupFilterClient();
    this.searchValue = "";
  }

  // city destination
  fetchCityDestination = debounce((search: string) => {
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (!search || search.length > 2) {
      LocationController.getCityList({
        page: 1,
        limit: 10,
        search,
        status: "",
      });
    }
  });

  get loadingCityDestination() {
    return LocationController.isLoading;
  }

  get cityDestinationData() {
    const listData = LocationController.cityData.cityData
      .filter((item) => item.name !== "")
      .map((item) => {
        return { ...item, name: `${item.code} - ${item.name}` };
      });

    return [{ id: 0, name: "All", code: "" }, ...listData];
  }

  onChangeCityDestination(item: any) {
    this.filterClient.cityOriginCode = this.filterClient.cityOriginCode || "";
    this.onChangeAdvancedFilter();
    if (this.filterClient.cityOriginCode || item) {
      this.summaryFilter.push({
        value: `${
          item?.name === "All"
            ? "All City"
            : this.filterClient.cityOriginCode?.name
        }`,
        from: "city",
      });
      const isExist =
        this.summaryFilter.filter((key: any) => key.from === "city").length > 1;
      if (isExist) {
        const filterAgain = this.summaryFilter.filter(
          (key: any) =>
            (key.from === "city" && key.value === item.name) ||
            key.from !== "city"
        );
        this.summaryFilter = filterAgain;
      }
    } else this.onDeleteSummary("city");
  }

  onDeleteSummary(from: string) {
    this.summaryFilter = this.summaryFilter.filter(
      (key: any) => key.from !== from
    );
    switch (from) {
      case "city":
        this.filterClient.cityOriginCode = "";
        break;
      case "shipment":
        this.filterClient.shipmentType = [];
        break;
      default:
        break;
    }
  }

  async onShowPopupFilterClient(isDirectory = false) {
    await this.filterClients(
      this.modelValue.clientName?.name || "",
      isDirectory
    );
    this.showPopupFilterClient = !this.showPopupFilterClient;
  }

  onClosePopupFilter() {
    this.onShowPopupFilterClient(false);
    this.searchValue = "";
  }

  get optionsProduct() {
    return [
      new OptionsClass({
        name: "COD",
        value: "cod"
      }),
      new OptionsClass({
        name: "DFOD",
        value: "dfod"
      }),
      new OptionsClass({
        name: "DO",
        value: "do"
      }),
      new OptionsClass({
        name: "Non COD",
        value: "non_cod"
      }),
    ]
  }

  // filter search
  searchValue = "";
  onSearchValue(val: string) {
    this.searchValue = val;
    this.filterClients(this.searchValue, true);
  }

  clearSearch() {
    this.searchValue = "";
    this.filterClients("", true);
  }

  get columns() {
    return [
      {
        name: "Nama Klien",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.name}</div>`;
        },
      },
      {
        name: "Kota Asal",
        styleHead: "w-48 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientOriginCity}</div>`;
        },
      },
      {
        name: "Tipe Klien",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientCategory}</div>`;
        },
      },
      {
        name: "Layanan Pengiriman",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientPackageType}</div>`;
        },
      },
      {
        name: "Kode Klien",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientCode}</div>`;
        },
      },
    ];
  }

  summaryFilter: any[] = [];

  pagination = new PaginationV2();


  get isEmpty(): boolean {
    return !this.clients.length && this.sumFilter === 0;
  }

  showPopupFilterClient = false;

  onChangeShipmentType(item: any) {
    const filterNotSame = this.summaryFilter.filter(
      (key: any) => key.from !== "shipment"
    );
    this.onChangeAdvancedFilter();
    if (!item.length) this.onDeleteSummary("shipment");
    else
      this.summaryFilter[filterNotSame.length > 0 ? 1 : 0] = {
        value: item.map((option: OptionsClass) => option.name).join(" & "),
        from: "shipment",
      };
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
    };
  }

  // clients
  fetchClients(search: string) {
    this.fetchdDataClients(search);
  }

  filterClients = debounce(async (search: string, isDirectory = false) => {
    await this.fetchdDataClients(search, isDirectory);
  }, 250);

  async fetchdDataClients(search: string, isDirectory = false) {
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      await PartnerController.getClientNamePOS({
        search,
        page: 1,
        limit: 10,
        isBranch: true,
        isApiCbp: false,
        packageType:
          this.filterClient.shipmentType
            ?.map((option: OptionsClass) => option.value)
            .join(",") || "",
        cityCode: this.filterClient.cityOriginCode?.code || "",
        isDirectory,
      });
    }
  }

  onGetClientStatus(obj: any){
    this.$emit("getClientStatus", obj);
  }

  get isErrorGetClient() {
    return PartnerController.errorCauseGetClientsNamePOS;
  }

  get clients() {
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      return PartnerController.clientDataNamePOS.data.map((e: any) => ({
        id: e.clientId,
        name: e.clientName,
        status: e.clientStatus,
        isDO: e.clientIsDO,
        isCOD: e.clientIsCOD,
        clientCode: e.clientCode,
        clientOriginCity: e.clientOriginCity,
        clientCategory: e.clientCategory,
        clientPackageType: e.clientPackageType?.toString(),
        clientCodConfigAmountData: e.clientCodConfigAmount
      }));
    }
    return [];
  }
}
