
import { Options, prop, Vue } from "vue-class-component";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import startCase from "lodash/startCase";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  isShow = prop<boolean>({
    default: false,
    type: Boolean
  });
  dataDetail = prop<any>({
    default: {},
    type: Object
  });
  isDiscountActive = prop<boolean>({
    default: false,
    type: Boolean
  });
  isCodRetail = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDfod = prop<boolean>({
    default: false,
    type: Boolean
  });
  isForeignCurrency = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({})
export default class DetailEstimation extends Vue.with(Props) {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }

  currency(curr: number) {
    return this.isForeignCurrency ? this.currencyRinggit(curr) : this.currencyRupiah(curr);
  }

  currencyRupiah(curr: number) {
    if (curr) {
      return formatPriceRP(curr);
    }
    return formatPriceRP(0);
  }

  currencyRinggit(curr: number) {
    if (curr) {
      return formatPriceRM(curr);
    }
    return formatPriceRM(0);
  }

  get codReturnFee() {
    return BookingController.estimationCodRetail.returnFee;
  }

  startCase(value: string) {
    return startCase(value);
  }

  formatDateWithoutTime(value: string) {
    return formatDateWithoutTime(value);
  }

  refs: any = {};
  onOpenTooltipPromo(event: any, index: number) {
    const refs: any = this.$refs;
    refs[`infoPromo${index}`].toggle(event);
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

}
