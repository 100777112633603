import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-row text-12px xxl:text-14px relative mt-4 space-x-4" }
const _hoisted_2 = { for: "cod" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.deliveryFeeTypes, (option) => {
      return (_openBlock(), _createBlock("div", {
        key: option.value,
        class: "flex flex-col gap-y-4 mx-2 my-4 city-exclude-radio items-center"
      }, [
        _createVNode("div", {
          class: `p-field-radiobutton gap-x-3 flex flex-row ${
          option.logo ? 'items-center' : 'mt-3'
        }
            ${option.logo === 'dfod-logo' ? 'mt-2' : ''}`
        }, [
          _createVNode(_component_RadioButton, {
            id: `radio-${option.value}`,
            name: "surcharge-status",
            value: option.value,
            modelValue: _ctx.deliveryFeeType,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.deliveryFeeType = $event)),
            disabled: option.disabled,
            onChange: ($event: any) => (_ctx.setValue(option.value))
          }, null, 8, ["id", "value", "modelValue", "disabled", "onChange"]),
          _createVNode("label", {
            for: `radio-${option.value}`,
            class: `cursor-pointer flex ${option.logo ? 'items-center' : ''}`
          }, [
            (option.logo)
              ? (_openBlock(), _createBlock("img", {
                  key: 0,
                  src: require(`@/app/ui/assets/images/${option.logo}.png`),
                  class: "max-w-none w-12 mr-2",
                  alt: "cod-logo"
                }, null, 8, ["src"]))
              : _createCommentVNode("", true),
            _createVNode("label", _hoisted_2, _toDisplayString(option.name), 1)
          ], 10, ["for"])
        ], 2)
      ]))
    }), 128))
  ]))
}