
  import { Options, prop, Vue } from "vue-class-component";
  import { AccountController } from "@/app/ui/controllers/AccountController";
  import OverlayPanel from "primevue/overlaypanel";
  
  class Props {
    formType = prop<any>({
      default: "",
      type: String
    });
    totalItem = prop<any>({
      default: "0",
    });
    totalGrossWeight = prop<any>({
      default: "0",
    });
    totalDimensionWeight = prop<any>({
      default: "0",
    });
    chargeableWeight = prop<any>({
      default: "0",
    });
    isVolumeWeight = prop<boolean>({
      required: true,
    });
    isCampaign = prop<boolean>({
      required: true,
    });
    isGrossWeight = prop<boolean>({
      required: true,
    });
  }
  
  @Options({
    emits: ["updateStatusErrorEmbargo"],
    components: { OverlayPanel },
  })
  export default class WeightCost extends Vue.with(Props) {
    isShow = false;
    
    get dataProfile() {
        return AccountController.accountData;
    }

    updateData() {
        //
    }

    showDownloadChoice() {
        this.isShow = !this.isShow;
    }

    onCloseAnywhere(value: boolean) {
        this.isShow = value;
    }

    toggleTooltip(event: any) {
        this.showDownloadChoice();
        const refs: any = this.$refs;
        refs["opTooltip"].toggle(event);
    }

    hideToggle(event: any) {
        const refs: any = this.$refs;
        refs["opTooltip"].hide(event);
    }
  }
