
import { Vue, Options, prop } from "vue-class-component";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import { PaymentMethod } from "@/domain/entities/Payment";
import Cash from "@/app/ui/components/icons/modules/cash.vue";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  isDiscount = prop<boolean>({
    default: false
  });
}

@Options({
  components: { Cash }
})
export default class SelectPaymentMethods extends Vue.with(Props) {
  mounted() {
    this.payWithCash();
    this.togglePaymentMethods();
    PaymentController.getPaymentMethodsAvailable();
  }
  get paymentMethodActive() {
    return PaymentController.paymentMethod;
  }
  get paymentMethods() {
    return PaymentController.paymentMethods.filter(
      (payment: PaymentMethod) => payment.status === "active"
    );
  }

  showPaymentMethods = false;
  togglePaymentMethods() {
    if (this.accountIsNonForeign) {
      this.showPaymentMethods = !this.showPaymentMethods;
    }
  }
  forceClosePaymentMethods() {
    this.showPaymentMethods = false;
  }

  payWithCash() {
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(
      new PaymentMethod({
        name: "cash",
        title: "Tunai",
        method: "cash"
      })
    );
    this.togglePaymentMethods();
  }
  payWithQr(paymentMethod: PaymentMethod) {
    if (paymentMethod.status === "inactive") return;
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(paymentMethod);
    this.togglePaymentMethods();
    if (
      paymentMethod.name === "bca_qris" ||
      paymentMethod.name === "ovo_qris"
    ) {
      const eventName = paymentMethod.name;
      GTMCommonEvent(`booking_pembayaran_${eventName}`);
    }
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }
}
