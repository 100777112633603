
import { Vue, Options, prop } from "vue-class-component";
import { PAYMENT_STATE } from "@/app/ui/components/dialog-payment/payment-props";
import DialogPaymentLayoutHeader from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutHeader.vue";
import DialogPaymentLayoutImage from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutImage.vue";
import DialogPaymentLayoutButton from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutButton.vue";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import { PaymentMethod } from "@/domain/entities/Payment";
import { BookingController } from "@/app/ui/controllers/BookingController";

export class Props {
  type = prop<string>({
    default: PAYMENT_STATE.FAILED_STATE,
    type: String
  });
  billType = prop<string>({
    default: "",
    type: String
  });
}
@Options({
  components: {
    DialogPaymentLayoutButton,
    DialogPaymentLayoutImage,
    DialogPaymentLayoutHeader
  },
  emits: ["onSubmit"]
})
export default class QrPaymentFailedExpired extends Vue.with(Props) {
  mounted() {
    this.startCountDown();
  }
  unmounted() {
    clearInterval(this.countDownInterval);
  }

  get sttNumber() {
    return PaymentController.sttNumber;
  }
  get paymentMethod() {
    return PaymentController.paymentMethod;
  }
  get title() {
    if (this.type === PAYMENT_STATE.EXPIRED_STATE) {
      return "Waktu Pembayaran Habis";
    }
    return "Terjadi Gangguan Sistem";
  }
  get message() {
    if (this.type === PAYMENT_STATE.EXPIRED_STATE) {
      if (this.billType === "booking") {
        return `STT Anda telah terbooking dengan nomor <strong>${this.sttNumber}</strong>.<br/>Halaman ini akan otomatis tertutup dan anda akan diarahkan ke<br/>halaman list booking dalam <strong>${this.timeoutDuration} detik</strong>`;
      }
      return `Halaman ini akan otomatis tertutup dan anda akan diarahkan ke<br/> halaman pembayaran dalam <strong>${this.timeoutDuration} detik</strong>`;
    }
    return `Silakan bayar dengan cash atau lakukan pembayaran ulang dengan <strong>${this.paymentMethod?.title}</strong>`;
  }
  get image() {
    if (this.type === PAYMENT_STATE.EXPIRED_STATE) {
      return "payment-timeout-illustration";
    }
    return "payment-failed-illustration";
  }

  timeoutDuration = 3;
  countDownInterval: any = null;
  startCountDown() {
    if (this.timeoutDuration === 0) {
      return;
    }
    this.countDownInterval = setInterval(() => {
      if (this.timeoutDuration === 1) {
        PaymentController.changeState("");
        PaymentController.changePaymentMethod(new PaymentMethod());
        if (this.billType === "booking") {
          PaymentController.toggleDialogPayment(false);
          BookingController.setForceRouter(true);
          this.$router.push("/shipment/booking");
        }
      } else {
        this.timeoutDuration--;
      }
    }, 1000);
  }
}
