import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBooking = _resolveComponent("ButtonBooking")!

  return (_openBlock(), _createBlock(_component_ButtonBooking, {
    menus: _ctx.menus,
    action: _ctx.action,
    isDisabled: _ctx.isDisabled
  }, null, 8, ["menus", "action", "isDisabled"]))
}