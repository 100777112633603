
import { Options, prop, Vue } from "vue-class-component";
import Book from "./book.vue";
import SelectReceiptLanguage from "@/app/ui/views/shipment/booking/create-booking/payment-detail/select-receipt-language.vue";

class Props {
  bookingType = prop<string>({
    default: "",
    type: String
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  components: {
    SelectReceiptLanguage,
    Book
  }
})
export default class Uncalculation extends Vue.with(Props) {
  get isProductInterpack() {
    return this.payload.sttProductType === "INTERPACK";
  }
}
