import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBooking = _resolveComponent("ButtonBooking")!
  const _component_ModalErrorBalance = _resolveComponent("ModalErrorBalance")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_ButtonBooking, {
      menus: _ctx.isPrintAble ? _ctx.menuPrint : _ctx.menus,
      action: _ctx.isPrintAble ? _ctx.actionPrint : _ctx.action,
      isDisabled: _ctx.isDisabled,
      isPrintAble: _ctx.isPrintAble,
      isBottom: ""
    }, null, 8, ["menus", "action", "isDisabled", "isPrintAble"]),
    _createVNode(_component_ModalErrorBalance, {
      modelValue: _ctx.balanceUnderLimit,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.balanceUnderLimit = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}