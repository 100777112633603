
import { Options, prop, Vue } from "vue-class-component";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import SelectPhoneCountryV2 from "../../create-booking-v2/components/select-phone-country-v2.vue";
import Checkbox from "primevue/checkbox";
import { ValidateInputPhoneNumber } from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";
import EditCustomerForm from "../../create-booking-v2/components/edit-customer/edit-customer-form.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import SelectCustomerV2 from "@/app/ui/views/shipment/booking/create-booking-v2/components/select-customer-v2.vue";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  accountIsNonForeign = prop<boolean>({
    required: true,
  });

  disableSaveSender = prop<boolean>({
    default: false,
    required: true,
  });

  payload = prop<any>({
    required: true,
  });

  error = prop<any>({
    required: true,
  });

  formType = prop<string>({
    required: true,
  });

  errorMessageName = prop<string>({
    required: true,
  });

  errorMessageAddress = prop<string>({
    required: true,
  });

  errorMessageDistrict = prop<string>({
    required: true,
  });
  isDisableDeliverySender = prop<boolean>({
    default: false,
  });
  existCustomerSender = prop<any>({
    default: { existSender: false },
  });
}

@Options({
  emits: ["setFieldIndex", "loadUpsellingOrigin", "onSet", "checkFocus"],
  components: {
    SelectPhoneCountryV2,
    Checkbox,
    EditCustomerForm,
    SelectCustomerV2
  },
})
export default class SectionSenderDetail extends Vue.with(Props) {
  isOpenUpload = false;
  originCityList: Array<any> = [];

  phoneNumberError = {
    success: false,
    error: false,
    message: "",
  };

  selectedCustomer: any = {
    customerAddress: "",
    customerId: 0,
    customerName: "",
    customerPhoneNumber: "",
  };

  errorMessageAddressLocal = "";
  errorMessageNameLocal = "";

  errorEmbargo = {
    originCity: false,
    destinationCity: false,
    productType: false,
    comodity: false,
    weightStatus: false,
    lengthStatus: false,
  };

  checkFocus() {
    this.$emit("checkFocus");
  }

  mounted(): void {
    this.modelValue.sttSenderPhoneCode = this.accountIsNonForeign
      ? "+62"
      : "+60";
    this.modelValue.phoneFlagSender = this.accountIsNonForeign
      ? "indonesia"
      : "malaysia";
  }

  get maxAddressLength() {
    return FlagsMedusa.config_max_address.getValue();
  }

  get modelValueProps() {
    return this.modelValue;
  }

  // FUNCTIONS

  showForm() {
    this.isOpenUpload = true;
  }

  onSaveCustomer = debounce((event: any) => {
    if (!event.target.checked) {
      return;
    }
    if (
      this.modelValueProps.sender &&
      this.modelValueProps.sttSenderPhone &&
      this.modelValueProps.sttSenderPhoneCode
    ) {
      BookingController.addCustomer({
        name:
          typeof this.modelValueProps.sender === "object"
            ? this.modelValueProps.sender.customerName
            : this.modelValueProps.sender,
        phone: `${this.modelValueProps.sttSenderPhoneCode.substring(1)}${
          this.modelValueProps.sttSenderPhone
        }`,
        address: this.modelValueProps.addressSender,
      });
    }
  }, 1000);

  validateSenderName(value: string) {
    const min3Chars = value.length < 3;
    const empty = value === "";
    this.errorMessageNameLocal = "";
    if (min3Chars) {
      this.errorMessageNameLocal = "Nama pengirim minimum 3 karakter.";
    }
    if (empty) {
      this.errorMessageNameLocal = "Nama pengirim wajib diisi.";
    }
    const senderError = min3Chars || empty;
    this.error.sender = senderError;
    this.$emit("update:error", { ...this.error, sender: senderError });
    if (!senderError){
      this.checkFocus();
    }
  }

  validateAddressSender(value: string) {
    const min3Chars = value.length < 3;
    const empty = value === "";
    this.errorMessageAddressLocal = "";
    if (min3Chars) {
      this.errorMessageAddressLocal = "Alamat pengirim minimum 3 karakter.";
    }
    if (empty) {
      this.errorMessageAddressLocal = "Alamat pengirim wajib diisi.";
    }
    const senderError = min3Chars || empty;
    this.error.addressSender = senderError;
    this.$emit("update:error", { ...this.error, addressSender: senderError });
    if (!senderError) {
      this.checkFocus();
    }
  }

  replaceNewLine(value: string) {
    const removedNewLineAddress = removeNewLineWhitespace(value, " ");
    this.modelValue.addressSender = removedNewLineAddress;
    this.$emit("update:modelValue", { ...this.modelValue });
    return removedNewLineAddress;
  }

  setPhoneSender(val: string, code = "") {
    let selectedPhoneCode = "";
    if (
      this.modelValue.sttSenderPhoneCode !== null &&
      this.modelValue.sttSenderPhoneCode !== ""
    ) {
      selectedPhoneCode = this.modelValue.sttSenderPhoneCode.substring(1);
    }
    const phoneNumberWithCode = `${selectedPhoneCode}${val}`;
    this.phoneNumberError = ValidateInputPhoneNumber(phoneNumberWithCode);
    this.setErrorPhoneCodeSender(this.phoneNumberError.error);
    this.modelValue.sttSenderPhone = val;
    this.$emit("update:modelValue", { ...this.modelValue });
    this.fetchCustomer(val, code);
  }

  setPhoneCodeSender(val: string) {
    this.modelValue.sttSenderPhoneCode = val;
    ShipmentBookingController.setSenderPhoneCodeToEdit(val);
    this.$emit("update:modelValue", { ...this.modelValue });
  }

  setErrorPhoneCodeSender(status: boolean) {
    this.error.sttSenderPhoneCode = status;
    this.$emit("update:error", { ...this.error, sttSenderPhoneCode: status });
  }

  setPhoneFlagSender(val: string) {
    ShipmentBookingController.setSenderPhoneFlagToEdit(val);
  }

  get origin() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityCode: `${detail.cityCode}`,
      zipCode: `${detail.originZipCode}`,
    };
  }

  get isShipperAndConsigneeAddressIdentical() {
    if (
      this.modelValue.sttSenderAddress === "" ||
      this.modelValue.sttRecipientAddress === ""
    ) {
      return false;
    }
    return (
      String(this.modelValue.sttSenderAddress).toLowerCase() ===
      String(this.modelValue.sttRecipientAddress).toLowerCase()
    );
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
    };
  }

  get isErrorEmbargo() {
    return BookingController.isErrorEmbargo;
  }

  fetchOriginCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true,
    });
    this.originCityList = this.filterDistrict(response);
  }, 250);

  get isLoadingOriginCity() {
    return LocationController.isLoadingDistrict;
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.code}`,
        cityCode: `${e.cityCode}`,
        status: e.status,
        type: e.type,
        "vendor_code": e.vendorCode,
        isCod: e.isCod,
        isFtz: e.isFtz,
      }));
  }

  onFocusOriginCity() {
    if (!this.originCityList.length || !this.modelValue.sttOriginAddress) {
      LocationController.setLoadingDistrict(true);
      this.fetchOriginCity("");
    }
    this.$emit("setFieldIndex", 9);
  }

  onUpdateEmbargoStatusFail() {
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
  }

  updateStatusErrorEmbargo(status: boolean) {
    this.errorEmbargo.originCity = status;
    this.errorEmbargo.destinationCity = status;
    this.errorEmbargo.productType = status;
    this.errorEmbargo.comodity = status;
    this.errorEmbargo.weightStatus = status;
    this.errorEmbargo.lengthStatus = status;
  }

  pickProductUpselling(val: any) {
    if (val) {
      this.loadUpsellingProductByCondition();
    } else {
      this.resetProductUpselling();
    }
  }

  loadUpsellingProductByCondition() {
    this.loadUpsellingProductForManualBook();
    this.loadUpsellingProductForClientBook();
  }

  loadUpsellingProductForManualBook() {
    if (
      this.isFormBookManual &&
      this.OriginAndDestinationFullfilledForManualBook
    ) {
      this.loadUpsellingProduct();
    }
  }

  loadUpsellingProductForClientBook() {
    if (this.isFormBookClient && this.fullFilledUpsellingProductForClient) {
      this.loadUpsellingProduct();
    }
  }

  get isFormBookManual() {
    return this.formType === "manual";
  }

  get isFormBookClient() {
    return this.formType === "client";
  }

  get OriginAndDestinationFullfilledForManualBook() {
    return this.origin.id && this.modelValue.destinationCity;
  }

  get fullFilledUpsellingProductForClient() {
    return (
      this.originFullfilledForManualBook &&
      this.clientNameAndDestinationFullfilledForManualBook
    );
  }

  get originFullfilledForManualBook() {
    return this.modelValue.sttOriginAddress || this.origin.id;
  }

  get clientNameAndDestinationFullfilledForManualBook() {
    return this.modelValue.clientName && this.modelValue.destinationCity;
  }

  loadUpsellingProduct() {
    this.$emit("loadUpsellingOrigin");
  }

  get clientlientIdForProductUpselling() {
    let accountRefId = 0;
    let accountType = "";

    if (this.isFormBookManual && this.getAccount.accountType === "partner") {
      accountRefId = 0;
      accountType = "pos";
    } else if (
      this.isFormBookClient &&
      this.getAccount.accountType === "partner"
    ) {
      accountRefId = this.modelValue.clientName.id;
      accountType = "client";
    } else if (
      this.isFormBookManual &&
      this.getAccount.accountType === "client"
    ) {
      accountRefId = this.getAccount.accountClientId;
      accountType = "client";
    } else {
      accountRefId = this.modelValue.clientName.id;
      accountType = "client";
    }

    return {
      accountRefId,
      accountType,
    };
  }

  resetProductUpselling() {
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.resetAllProduct();
  }

  get manualBooking(): any {
    return ShipmentBookingController.shipmentBookingDetail;
  }

  onChooseSenders(item: any, isChoosen = true) {
    if (!item) {
      return false;
    }
    item = {
      ...item,
      customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
      customerPhoneFlag: codePhoneCountry(item.customerPhoneNumber).flag,
      customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number,
    };

    this.manualBooking.sttSenderName = item.customerName;
    this.manualBooking.sttSenderAddress = item.customerAddress;
    this.manualBooking.sttSenderPhoneCode = item.customerPhoneCode;
    this.manualBooking.sttSenderPhoneFlag = item.customerPhoneFlag;
    this.manualBooking.sttSenderPhone = item.customerPhoneNumber;

    this.error.phoneCharMinimalSender =
      item.customerPhoneNumber.length < 5 && isChoosen;

    // if (!isChoosen) {
      this.clearError();
    // }

    this.validatePhoneSender(item.customerPhoneNumber);
    this.validateSenderName(this.modelValue.sttSenderName);
    this.setErrorPhoneCodeSender(this.phoneNumberError.error);
    this.validateAddressSender(this.manualBooking.sttSenderAddress);
  }

  clearError() {
    this.phoneNumberError = {
      success: false,
      error: false,
      message: "",
    };
  }

  validatePhoneSender(value: string) {
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      ShipmentBookingController.setSenderPhoneToEdit(newPhone);
    } else {
      ShipmentBookingController.setSenderPhoneToEdit(value);
    }
    this.error.phoneSender = !/^\d+$/.test(value);
  }

  detectPhoneNumber(phoneArray: Array<string>) {
    return phoneArray.find(
      () => phoneArray[0] === "0" && phoneArray[1] === "8"
    );
  }

  setNameCustomer(search: string, type: string) {
    if (type === "senders") {
      BookingController.setNameSender(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      BookingController.setNameReceiver(search); // set payload even if not choosing
    }
    if (search.length === 0) {
      return;
    }
    this.fetchCustomer(search);
  }

  fetchCustomer = debounce((search: string, code = "") => {
    if (search.length > 2) {
      BookingController.getCustomerList({
        search: code.substring(1) + search,
        cache: true,
      });
    }
  }, 1000);

  get isLoadingCustomer() {
    return BookingController.isLoadingCustomer;
  }

  get senders() {
    return this.getPhoneNumbers;
  }

  get getPhoneNumbers() {
    const list = BookingController.customerList.data;
    if (list.length !== 0) {
      return BookingController.customerList.data.map((item) => {
        return {
          ...item,
          customerPhoneNumber: `${item.customerPhoneNumber}`,
        };
      });
    } else {
      return list;
    }
  }

  editItem(item: any) {
    this.selectedCustomer = item;
    this.showForm();
  }
}
