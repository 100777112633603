import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-6 inline-flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createVNode("img", {
        class: "h-32 w-32",
        alt: "payment_image",
        src: require(`@/app/ui/assets/svg/payment/${_ctx.image}.svg`)
      }, null, 8, ["src"])
    ])
  ]))
}