import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectGroupV2 = _resolveComponent("SelectGroupV2")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_SelectGroupV2, {
      searchCustomerNameMode: true,
      customer: _ctx.selectedCustomer,
      "onUpdate:customer": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedCustomer = $event)),
      customerName: _ctx.customerNameModel,
      "onUpdate:customerName": _cache[2] || (_cache[2] = ($event: any) => (_ctx.customerNameModel = $event)),
      error: _ctx.error,
      errorCaption: _ctx.errorCaption,
      onChange: _ctx.onChange,
      onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focus'))),
      onEditItem: _ctx.editItem,
      isLoading: _ctx.isLoading,
      disabled: _ctx.disabled,
      options: _ctx.options,
      disabledUserDropdown: _ctx.disabledUserDropdown,
      maxLength: 200,
      format: _ctx.formatName,
      keyValue: "customerPhoneNumber",
      keyName: "customerPhoneNumber",
      placeholder: _ctx.placeholder,
      class: "mt-2"
    }, null, 8, ["customer", "customerName", "error", "errorCaption", "onChange", "onEditItem", "isLoading", "disabled", "options", "disabledUserDropdown", "format", "placeholder"])
  ]))
}