
import { Options, prop, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import Book from "./book.vue";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { SHIPMENT_ID_FAVOURITE, promoPrefix } from "@/app/ui/views/shipment/booking/booking-utils";
import SelectReceiptLanguage from "@/app/ui/views/shipment/booking/create-booking/payment-detail/select-receipt-language.vue";
import Discount from "../../create-booking/payment-detail/components/discount.vue";
import DetailEstimation from "../../create-booking/payment-detail/components/detail-estimation.vue";
import { PromoConfigurationListData } from "@/domain/entities/PromoConfiguration";
import { EstimationPrice } from "@/domain/entities/Booking";
import prefixAlgoCheck from "@/app/infrastructures/misc/common-library/PrefixAlgoCheck";
import { GetEstimationPrice } from "@/data/payload/api/BookingRequest";
import { DetailDiscount } from "@/domain/entities/Tariff";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";

interface DiscountInterface {
  status: boolean;
  data: PromoConfigurationListData;
}

class Props {
  dataCalculation = prop<any>({
    default: {},
    type: Object
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  discountData = prop<DiscountInterface>({
    default: {
      status: false,
      data: Object
    },
    type: Object
  });
  formType = prop<any>({
    default: "",
    type: String
  });
  isReverseJourney = prop<boolean>({
    default: false,
    type: Boolean
  });
  bookingTypePromo = prop<boolean>({
    default: false,
    type: Boolean
  });
  isSttForeign = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  components: {
    SelectReceiptLanguage,
    Tooltip,
    Book,
    DetailEstimation,
    Discount
  },
  watch: {
    "payload.sttProductType": function() {
      this.resetEstimasiDiscount();
    },
    "payload.sttDestinationCityId": function() {
      this.resetEstimasiDiscount();
    },
    "payload.sttGrossWeight": function() {
      this.resetEstimasiDiscount();
    },
    "payload.sttVolumeWeight": function() {
      this.resetEstimasiDiscount();
    }
  }
})
export default class CheckTariff extends Vue.with(Props) {
  isShow = false;

  unmounted() {
    BookingController.setDefaultEstimationPrice();
  }

  async showDetail() {
    this.isShow = !this.isShow;
    if (this.isShow) {
      await this.getEstimationPrice();
    }
  }

  async updateEstimationPrice() {
    await this.getEstimationPrice();
  }

  async getEstimationPrice() {
    if (!this.isDisabled) {
      this.showDiscountNominal = true;
      const piecesDelivery: any = [];
      this.payload.sttPieces.map((item: any) => {
        piecesDelivery.push({
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_length: item.stt_piece_length,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_width: item.stt_piece_width,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_height: item.stt_piece_height,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_gross_weight: item.stt_piece_gross_weight
        });
      });

      const payload = new GetEstimationPrice({
        originId: this.payload.sttOriginDistrictId,
        destinationId: this.payload.sttDestinationDistrictId,
        commodityId: this.payload.sttCommodityId,
        productType: this.payload.sttProductType,
        accountType: this.isShipmentCodPrefix || this.shipmentFavoriteClient || this.shipmentFavoriteReverseJourney
          ? "client" 
          : this.checkAccount(
              AccountController.accountData,
              this.payload.sttBooked,
              this.payload.sttShipmentId
            ).accountType,
        accountRefId: (this.isShipmentCodPrefix && this.payload.sttBooked.sttShipmentId.startsWith("AR")) || this.shipmentFavoriteClient || this.shipmentFavoriteReverseJourney
          ? 2311 
          : this.prefixC1Cod,
        goodsPrice: this.payload.sttGoodsEstimatePrice,
        insuranceType: this.payload.sttInsuranceType,
        isWoodpacking: this.payload.sttIsWoodpacking,
        isHaveTaxId: !!this.payload.sttTaxNumber,
        pieces: piecesDelivery,
        isCod: this.isShipmentCodPrefix || this.payload.sttIsCod,
        isDfod: this.payload.sttIsDfod,
        codHandling: this.payload.codHandling,
        isPad: this.payload.sttShipmentId ? false : this.payload.sttIsPad,
        codAmount: this.isCodRetail || this.isShipmentCodPrefix || this.isShipmentFavoriteCOD ? this.payload.sttGoodsEstimatePrice : this.getCodAmount,
        isDisablePromo:
          this.bookingTypePromo &&
          !this.isReverseJourney &&
          (AccountController.accountData.isAllPartner ||
            AccountController.accountData.isInternalAccount)
            ? false
            : true,
        promoAppliedTo: this.payload.sttBooked.sttShipmentId ? "shipment" : "retail",
        shipmentPrefix: promoPrefix(this.payload.sttBooked.sttShipmentId),
        discountFavoritePercentage: this.payload.discountFavoritePercentage
      })

      if (this.isCodRetail || this.isShipmentCodPrefix || this.payload.sttIsDfod || this.isShipmentFavoriteCOD) {
        this.getEstimationForCod(payload);
      }

      return BookingController.getEstimationPrice(payload);
    }
  }

  get isShipmentFavoriteCOD() {
    return this.shipmentFavorite && (this.payload.sttIsCod || this.payload.sttIsDfod);
  }

  get shipmentFavorite() {
    return this.payload.sttBooked.sttShipmentId.match(/^AP|^AS/gi);
  }

  get shipmentFavoriteClient() {
    return (this.payload.sttShipmentId.startsWith("AP") || this.payload.sttShipmentId.startsWith("AS")) && !this.payload.shipmentReverseJourney;
  }

  get shipmentFavoriteReverseJourney() {
    return this.payload.shipmentReverseJourney.startsWith("AP") || this.payload.shipmentReverseJourney.startsWith("AS");
  }

  get prefixC1Cod() {
    return this.payload.shipmentC1Cod 
      ? this.payload.clientIdBranch
      : this.checkAccount(
          AccountController.accountData,
          this.payload.sttBooked,
          this.payload.sttShipmentId
        ).accountRefId;
  }

  getEstimationForCod(payload: any)  {
    const payloadCod = new GetEstimationPrice({
      ...payload,
      originId: this.payload.sttDestinationDistrictId,
      destinationId: this.payload.sttOriginDistrictId,
      isCod: false,
      isDfod: false,
      isWoodpacking: false,
      isDisablePromo: true
    });
    return BookingController.getEstimationPriceCodRetail(payloadCod);
  }

  get getCodAmount() {
    return this.payload.sttShipmentId || this.payload.sttIsDfod ? 0 : this.payload.sttCodAmount;
  }

  get isCodRetail() {
    return this.payload.sttIsCod && !this.payload.sttIsDfod && this.isSttRetail;
  }

  get isSttRetail() {
    return this.payload.sttNo.match(/^11LP/gi) || this.payload.sttNo.match(/^10LP/gi)
  }

  get dataDetail() {
    if (!this.isDisabled) {
      return {
        ...BookingController.estimationPrice,
        discountBoards: BookingController.estimationPrice.listDiscount
          .filter((item: DetailDiscount) => item.pdcCategory === 'tactical' || item.pdcCategory === 'campaign')
      };
    } else {
      return {
        productType: this.dataCalculation.sttProductTypeName,
        cityRates: this.dataCalculation.sttCityRate,
        forwardRates: this.dataCalculation.sttForwardRate,
        shippingCost: this.dataCalculation.sttShipmentPrice,
        commoditySurcharge: this.dataCalculation.sttCommoditySurchargeRate,
        heavyWeightSurcharge: this.dataCalculation.sttHeavyWeightSurchargeRate,
        documentSurcharge: this.dataCalculation.sttDocumentSurchargeRate,
        insuranceName: this.dataCalculation.sttInsuranceName,
        insuranceRates: this.dataCalculation.sttInsuranceRate,
        totalTariff: 0,
        taxRates: this.dataCalculation.sttTaxRate,
        isPromo: false,
        discount: 0,
        totalDiscount: 0,
        totalTariffAfterDiscount: 0
      };
    }
  }

  checkAccount(account: any, sttBooked: any, shipmentId: string) {
    let accountType = "";
    let accountRefId = 0;

    if (
      account.account_type === "internal" ||
      account.account_type_detail.type === "console"
    ) {
      if (SHIPMENT_ID_FAVOURITE.includes(shipmentId.substr(0, 2))) {
        accountType = sttBooked.sttBookedByType;
        accountRefId = sttBooked.sttBookedById;
      } else {
        accountType = sttBooked.sttBookedForType;
        accountRefId = sttBooked.sttBookedForId;
      }
    } else if (account.account_type === "partner") {
      accountType = "pos";
      accountRefId = account.account_type_detail.id;
    } else {
      accountType = account.account_type;
      accountRefId = account.account_type_detail.id;
    }

    return {
      accountType,
      accountRefId
    };
  }

  isOpenTooltip = false;
  onOpenTooltip(value: boolean) {
    this.isOpenTooltip = value;
  }

  onCloseAnywhere(value: boolean) {
    this.isShow = value;
  }

 
  currency(curr: number) {
    return this.isSttForeign ? this.currencyRinggit(curr) : this.currencyRupiah(curr);
  }

  currencyRupiah(curr: number) {
    if (curr) {
      return formatPriceIDR(curr);
    }
    return formatPriceIDR(0);
  }

  currencyRinggit(curr: number) {
    if (curr) {
      return formatPriceRM(curr);
    }
    return formatPriceRM(0);
  }

  get isProductInterpack() {
    return this.payload.sttProductType === "INTERPACK";
  }

  showDiscountNominal = false;
  get isDiscountActive() {
    return this.discountData.status;
  }

  resetEstimasiDiscount() {
    BookingController.setEstimationPrice(
      new EstimationPrice({
        totalTariffAfterDiscount: 0,
        totalTariff: 0,
        isPromo: false
      })
    );
  }

  get isShipmentCodPrefix() {
    return !!(prefixAlgoCheck({
      prefix: "ARA,ARB",
      shipmentId: this.payload.sttBooked.sttShipmentId
    }) || this.payload.shipmentC1Cod)
  }
}
