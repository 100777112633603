/* eslint-disable @typescript-eslint/camelcase */
import { WoodpackingAvailableCities } from "@/domain/entities/ConfigWoodpacking";
import { EditConfigWoodpackingRequestInterface } from "../contracts/ConfigWoodpackingRequest";

export class EditConfigWoodpackingApiRequest
  implements EditConfigWoodpackingRequestInterface {
  private configurable_price_id: number;
  private configurable_price_type: string;
  private configurable_price_description: string;
  private configurable_price_status: string;
  private additional_cost: number;
  private type: string;
  private woodpacking_available_cities: Array<any>;

  constructor(
    configurable_price_id: number,
    configurable_price_type: string,
    configurable_price_description: string,
    configurable_price_status: string,
    additional_cost: number,
    type: string,
    woodpacking_available_cities:WoodpackingAvailableCities[]
  ) {
    this.configurable_price_id = configurable_price_id;
    this.configurable_price_type = configurable_price_type;
    this.configurable_price_description = configurable_price_description;
    this.configurable_price_status = configurable_price_status;
    this.additional_cost = additional_cost;
    this.type = type;
    this.woodpacking_available_cities = woodpacking_available_cities;
  }

  public toJSON(): string {
    return JSON.stringify({
      configurable_price_id: this.configurable_price_id,
      configurable_price_type: this.configurable_price_type,
      configurable_price_description: this.configurable_price_description,
      configurable_price_status: this.configurable_price_status,
      configurable_woodpacking: {
        woodpacking_additional_cost: {
          additional_cost: this.additional_cost,
          type: this.type,
        },
        woodpacking_available_cities: this.woodpacking_available_cities
      }
    });
  }
}
