/* eslint-disable @typescript-eslint/camelcase */
import { HeavyWeightSurchargeData, HeavyWeightSurchargeDetailMinimum } from "@/domain/entities/HeavyWeightSurcharge";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { HeavyWeightSurchargePresenter } from "@/app/ui/presenters/HeavyWeightSurchargePresenter";
import { CommodityPresenter } from "@/app/ui/presenters/CommodityPresenter";
import { CommodityList } from "@/domain/entities/Commodity";
import { EditHeavyWeightSurchargeApiRequest } from "@/data/payload/api/HeavyWeightSurchargeApiRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";

export interface HeavyWeightSurchargeState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  heavyWeightSurchargeDetail: HeavyWeightSurchargeData;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
  heavyWeightMinimum: HeavyWeightSurchargeDetailMinimum;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "heavyweight-surcharge"
})
class HeavyWeightSurchargeStore extends VuexModule
  implements HeavyWeightSurchargeState {
  public isLoading = false;
  public isLoadingDetail = false;
  public heavyWeightSurchargeDetail = new HeavyWeightSurchargeData();
  public isError = false;
  public isErrorEdit = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public heavyWeightMinimum = new HeavyWeightSurchargeDetailMinimum();


  @Action
  public getDetailHeavyWeightSurcharge(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(HeavyWeightSurchargePresenter);
    presenter
      .getDetailHeavyWeightSurcharge(params.id)
      .then(async (res: HeavyWeightSurchargeData) => {
        this.setHeavyWeightSurchargeData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public _onEdit(params: {
    configPriceId: number;
    configPriceDescription: string;
    configPriceProductExclude: Array<string>;
    configPriceCommodityExclude: Array<any>;
    configPriceProductInclude: Array<any>;
    configPriceAdditionalCosts: Array<any>;
    configPriceStatus: string;
    hwSurchargeType: string;
    hwSurchargeDescription: string;
    hwSurchargeId: number;
    hwSurchargeStatus: string;
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(HeavyWeightSurchargePresenter);
    presenter
      .editHeavyWeightSurcharge(
        new EditHeavyWeightSurchargeApiRequest(
          params.configPriceId,
          params.configPriceDescription,
          params.configPriceStatus,
          params.configPriceCommodityExclude,
          params.configPriceAdditionalCosts,
          params.configPriceProductExclude,
          params.configPriceProductInclude,
          params.hwSurchargeType,
          params.hwSurchargeDescription,
          params.hwSurchargeId,
          params.hwSurchargeStatus
        )
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenModalSuccess(true);
        this.setErrorEdit(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setOpenModal(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Perubahan Heavyweight Surcharge Gagal!",
            () => this._onEdit(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
        this.setLoading(false);
      });
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  private setHeavyWeightSurchargeData(data: HeavyWeightSurchargeData) {
    this.heavyWeightSurchargeDetail = data;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  // Commodity State
  public commodityOptionsData: Array<any> = [];
  public isLoadingCommodity = false;

  // Handle Commodity
  @Mutation
  public setLoadingCommodity(value: boolean) {
    this.isLoadingCommodity = value;
  }

  @Mutation
  public setOptionsCommodityData(value: CommodityList) {
    this.commodityOptionsData = value.data;
  }

  @Action
  public getCommodityList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingCommodity(true);
    const presenter = container.resolve(CommodityPresenter);
    presenter
      .getCommodityList(
        new RequestListCommodity({
          search: params.search,
          commodityStatus: params.status,
          page: params.page,
          limit: params.limit
        })
      )
      .then((res: CommodityList) => {
        this.setOptionsCommodityData(res);
      })
      .catch(() => {
        console.warn("error from server");
      })
      .finally(() => {
        this.setLoadingCommodity(false);
      });
  }

  @Action
  public async getDetailMinimumHeavyWeightSurcharge(params: { id: any, product: string, commodity: any }) {
    this.setMinimumHeavyWeightSurcharge({
      value: 0,
      existMinimum: false
    });
    MainAppController.showLoading()
    const presenter = container.resolve(HeavyWeightSurchargePresenter);
    await presenter
      .getDetailHeavyWeightSurcharge(params.id)
      .then(async (res: HeavyWeightSurchargeData) => {
        const isActive = res.configurablePriceStatus.toLowerCase() === "active";
        const product = res
          .configurableHeavyWeight
          .heavy_weight_surcharge_product_include
          .product_types.includes(params.product?.toUpperCase())
        const isEnableHwsConfig = FlagsPermissionBooking.hws_configuration.isEnabled();  
        const commodity = res
          .configurableHeavyWeight
          .heavy_weight_surcharge_commodity_exclude
          .commodities.find((item: any) => item.commodity_code === params.commodity || item.commodity_name === params.commodity)
        
        const otherCondition = product && !commodity;
        const isSetMinimumHws = isActive && otherCondition && isEnableHwsConfig
        if (isSetMinimumHws) {
          this.setMinimumHeavyWeightSurcharge({
            value: res
              .configurableHeavyWeight
              .heavy_weight_surcharge_additional_cost
              .additional_costs[0]?.weight_min,
            existMinimum: true
          })
        }
      })
      .catch(error => {
        this.setMinimumHeavyWeightSurcharge({
          value: 0,
          existMinimum: false
        });
      }).finally(() => {
        MainAppController.closeLoading()
      })
  }

  @Mutation
  public setMinimumHeavyWeightSurcharge(params: {
    value: number, 
    existMinimum: boolean
  }) {
    this.heavyWeightMinimum = new HeavyWeightSurchargeDetailMinimum({
      heavyWeightMinimum: params.value,
      minimumExist: params.existMinimum
    })
  }
}

export const HeavyWeightSurchargeController = getModule(
  HeavyWeightSurchargeStore
);
