import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center py-3 text-14px font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogPaymentLayoutHeader = _resolveComponent("DialogPaymentLayoutHeader")!
  const _component_DialogPaymentLayoutImage = _resolveComponent("DialogPaymentLayoutImage")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DialogPaymentLayoutHeader, { textHeader: _ctx.title }, {
      "sub-header": _withCtx(() => [
        _createVNode("div", _hoisted_1, _toDisplayString(_ctx.sttNumber), 1),
        _withDirectives(_createVNode("div", null, null, 512), [
          [_directive_sanitize_html, _ctx.message]
        ])
      ]),
      _: 1
    }, 8, ["textHeader"]),
    _createVNode(_component_DialogPaymentLayoutImage, { image: _ctx.image }, null, 8, ["image"])
  ], 64))
}