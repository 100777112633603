
import { Options, prop, Vue } from "vue-class-component";
import SelectGroupV2 from "@/app/ui/components/select-group-v2/index.vue";
import { countries } from "@/app/infrastructures/misc/BulkData";

class Props {
  options = prop<any[]>({
    default: [],
    type: Array
  });
  customerName = prop<string>({
    default: "",
    type: String
  });
  error = prop<any>({
    default: {},
    type: Object
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabledUserDropdown = prop<boolean>({
    default: false,
    type: Boolean
  });
  column = prop<string>({
    default: "",
    type: String
  });
  customErrorMandatory = prop<string>({
    default: "",
    type: String
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  keyName = prop<string>({
    default: "name",
    type: String
  });
  errorCaption = prop<string>({
    default: "",
    type: String
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  emits: [
    "checkFocus",
    "setErrorCharMinimal",
    "changePhoneNumber",
    "editItem",
    "selectItem",
    "change",
    "update:customerName"
  ],
  components: {
    SelectGroupV2
  },
  watch: {
    "selectedCustomer": function() {
      this.$emit("selectItem", this.selectedCustomer);
    }
  }
})
export default class SelectCustomerV2 extends Vue.with(Props) {
  countryFiltered = countries;
  selectedCustomer: any = {
    customerAddress: "",
    customerId: 0,
    customerName: "",
    customerPhoneNumber: ""
  };

  selectItem(item: any) {
    this.$emit("selectItem", item);
  }

  editItem(item: any) {
    this.$emit('editItem', item);
  }

  onChange(value: string) {
    this.$emit("change", value);
  }

  formatName(value: string) {
    if (value.length > 100) {
      return value.slice(0, 100);
    }
    return value;
  }

  get customerNameModel() {
    return this.customerName;
  }

  set customerNameModel(value) {
    this.$emit("update:customerName", value);
  }
}
