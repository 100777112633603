
import { Vue, prop } from "vue-class-component";

class Props {
  image = prop<string>({
    default: "",
    type: String
  });
}
export default class DialogPaymentLayoutImage extends Vue.with(Props) {}
