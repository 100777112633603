/* eslint-disable @typescript-eslint/camelcase */
import { OptionsClass, QueryParamsEntities } from "@/domain/entities/MainApp";
import moment from "moment";
import parseInputFloatToFloat from "@/app/infrastructures/misc/common-library/ParseInputFloatToFloat";
export class SubmitPromoConfigurationApiRequest {
  promoId = 0;
  promoName = "";
  originCities = [] as Array<any>;
  destinationCities = [] as Array<any>;
  products: string[] = [];
  minimumWeight = 0;
  maximumWeight = 0;
  startDateFromDetail = "" as any;
  startDate = "" as any;
  endDate = "" as any;
  promoType = new OptionsClass();
  appliedTo = "Retail";
  promoAmount = "";
  maximumDiscount = "";
  minimumTransaction = "";
  promoQuantifier = new OptionsClass();
  statusPromo = "";
  referenceWeight = new OptionsClass();
  appliedToRetail = false;
  appliedToShipment = [] as Array<string>;
  promoUnique = new OptionsClass();

  constructor(fields?: Partial<SubmitPromoConfigurationApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify({
      pdc_name: this.promoName,
      pdc_start_date: moment(this.startDate).format("YYYY-MM-DD"),
      pdc_end_date: moment(this.endDate).format("YYYY-MM-DD"),
      pdc_origin_city:
        this.originCities[0].value === "ALL"
          ? "ALL"
          : this.originCities.map((item: any) => item.value).join(","),
      pdc_destination_city:
        this.destinationCities[0].value === "ALL"
          ? "ALL"
          : this.destinationCities.map((item: any) => item.value).join(","),
      pdc_product_type: this.products.join(","),
      pdc_minimal_gross_weight: Number(Number(this.minimumWeight).toFixed(2)),
      pdc_maximal_gross_weight: Number(Number(this.maximumWeight).toFixed(2)),
      pdc_type: this.promoType.value.toLowerCase(),
      pdc_value: parseInputFloatToFloat(this.promoAmount),
      pdc_maximal_promo: parseInputFloatToFloat(this.maximumDiscount),
      pdc_parameter_calculation: this.promoQuantifier.value,
      is_pdc_start_date_updated: this.startDateFromDetail
        ? (this.startDate as Date).getTime() !==
          (this.startDateFromDetail as Date).getTime()
        : undefined,
      pdc_reference_weight: this.referenceWeight.value,
      pdc_applied_to_retail: this.appliedToRetail,
      pdc_applied_to_shipment: this.appliedToShipment,
      pdc_category: this.promoUnique.value
    });
  }
}

export class PromoConfigurationListRequest {
  page = 1;
  limit = 10;
  name = "";
  origin = "";
  destination = "";
  status = "";
  category = "";
  grossWeight = 0;
  volumeWeight = 0;
  chargeableWeight = 0;
  productName = "";
  shipmentPrefix = "";
  isTotalData = true;
  appliedTo = "";

  constructor(fields?: Partial<PromoConfigurationListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
