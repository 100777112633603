import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-x-4 mt-6" }
const _hoisted_2 = { class: "w-full mt-2" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = { class: "flex flex-row items-center gap-x-4 pb-2" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_UploadImageTiny = _resolveComponent("UploadImageTiny")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, {
      fontSize: "20",
      label: _ctx.$t('bookingShipment.column.additionalInformation'),
      fontColor: "text-gray-lp-300",
      hint: "(Khusus INTERPACK)"
    }, null, 8, ["label"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        label: "Detail Komoditas",
        tooltipTitle: _ctx.$t('bookingShipment.tooltip.detailCommodity'),
        tooltipDescription: _ctx.$t('bookingShipment.tooltip.detailCommodityMsg'),
        fontSize: 14
      }, {
        default: _withCtx(() => [
          _createVNode(_component_lp_input, {
            type: "textarea",
            modelValue: _ctx.modelValue.sttCommodityDetail,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue.sttCommodityDetail = $event)),
            placeholder: _ctx.$t('bookingShipment.placeholder.commodityDetails'),
            format: _ctx.replaceNewLine,
            maxLength: 1000,
            showMaxLength: true,
            onChange: _ctx.validateCommodity,
            error: _ctx.error.commodity,
            errorCaption: _ctx.$t('bookingShipment.error.detailCommodity'),
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setFieldIndex(_ctx.indexErrorCommodity))),
            disabled: _ctx.disabledAdditionalInfo,
            disableBackground: _ctx.disabledAdditionalInfo
          }, null, 8, ["modelValue", "placeholder", "format", "onChange", "error", "errorCaption", "disabled", "disableBackground"])
        ]),
        _: 1
      }, 8, ["tooltipTitle", "tooltipDescription"]),
      _createVNode(_component_DataWrapper, {
        label: "Email",
        fontSize: 14,
        tooltipTitle: " ",
        tooltipDescription: " "
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LpInput, {
            type: "email",
            placeholder: "Contoh: nama@email.com",
            modelValue: _ctx.modelValue.sttRecipientEmail,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.modelValue.sttRecipientEmail = $event)),
            error: _ctx.error.email,
            errorCaption: "Email wajib diisi.",
            onChange: _ctx.validateEmail
          }, null, 8, ["modelValue", "error", "onChange"])
        ]),
        _: 1
      })
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        label: "Foto Pendukung",
        fontSize: 14
      }, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: ["grid grid-cols-3 gap-x-2 mt-2", _ctx.disabledAdditionalInfo ? 'opacity-50 cursor-not-allowed' : '']
          }, [
            _createVNode(_component_card, { class: "p-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_Title, {
                  fontSize: "14",
                  label: "Foto sebelum & sesudah paket packing",
                  fontColor: "text-gray-lp-300"
                }),
                _createVNode("div", _hoisted_3, [
                  _createVNode("div", _hoisted_4, [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.proofPhotos, (photo, index) => {
                      return (_openBlock(), _createBlock("div", { key: index }, [
                        (index < 2)
                          ? (_openBlock(), _createBlock(_component_UploadImageTiny, {
                              key: 0,
                              widthImage: "w-full",
                              accept: "image/jpg, image/jpeg",
                              title: photo,
                              modelValue: _ctx.attachmentImage[index],
                              "onUpdate:modelValue": ($event: any) => (_ctx.attachmentImage[index] = $event),
                              defaultImage: _ctx.storageImages[index],
                              maxSize: 5,
                              multipleIndex: index,
                              disabled: _ctx.disabledAdditionalInfo,
                              onUpload: _cache[4] || (_cache[4] = ($event: any) => (_ctx.disabledAdditionalInfo ? null : _ctx.upload)),
                              onRemove: _cache[5] || (_cache[5] = ($event: any) => (_ctx.disabledAdditionalInfo ? null : _ctx.remove))
                            }, null, 8, ["title", "modelValue", "onUpdate:modelValue", "defaultImage", "multipleIndex", "disabled"]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_card, { class: "p-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_Title, {
                  fontSize: "14",
                  label: "Foto KTP",
                  fontColor: "text-gray-lp-300"
                }),
                _createVNode("div", _hoisted_5, [
                  _createVNode(_component_UploadImageTiny, {
                    accept: "image/jpg, image/jpeg",
                    title: _ctx.proofPhotos[2],
                    modelValue: _ctx.attachmentImage[2],
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.attachmentImage[2] = $event)),
                    defaultImage: _ctx.storageImages[2],
                    maxSize: 5,
                    multipleIndex: 2,
                    disabled: _ctx.disabledAdditionalInfo,
                    onUpload: _cache[7] || (_cache[7] = ($event: any) => (_ctx.disabledAdditionalInfo ? null : _ctx.upload)),
                    onRemove: _cache[8] || (_cache[8] = ($event: any) => (_ctx.disabledAdditionalInfo ? null : _ctx.remove))
                  }, null, 8, ["title", "modelValue", "defaultImage", "disabled"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_card, { class: "p-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_Title, {
                  fontSize: "14",
                  label: "Foto NPWP",
                  fontColor: "text-gray-lp-300"
                }),
                _createVNode("div", _hoisted_6, [
                  _createVNode(_component_UploadImageTiny, {
                    accept: "image/jpg, image/jpeg",
                    title: _ctx.proofPhotos[3],
                    modelValue: _ctx.attachmentImage[3],
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.attachmentImage[3] = $event)),
                    defaultImage: _ctx.storageImages[3],
                    maxSize: 5,
                    multipleIndex: 3,
                    disabled: _ctx.disabledAdditionalInfo,
                    onUpload: _cache[10] || (_cache[10] = ($event: any) => (_ctx.disabledAdditionalInfo ? null : _ctx.upload)),
                    onRemove: _cache[11] || (_cache[11] = ($event: any) => (_ctx.disabledAdditionalInfo ? null : _ctx.remove))
                  }, null, 8, ["title", "modelValue", "defaultImage", "disabled"])
                ])
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      })
    ])
  ]))
}