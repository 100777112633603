
import EditBookingV1 from "../edit-booking/edit.vue";
import EditBookingV2 from "../edit-booking-v2/index.vue";
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
@Options({
  components: {
    EditBookingV1,
    EditBookingV2,
  },
})
export default class ConfigCreateBooking extends Vue {
  get isBookingVersion() {
    return FlagsPermissionBooking.permission_booking_version.getValue();
  }

  get isEditAble() {
    return FlagsPermissionBooking.permission_booking_edit.isEnabled();
  }

  get configBookingVersion() {
    let result = "";
    switch (this.isBookingVersion.toLowerCase()) {
      case "v1":
        result = "EditBookingV1";
        break;
      case "v2":
        result = "EditBookingV2";
        break;
      default:
        result = "EditBookingV1";
        break;
    }
    if (this.isEditAble) {
      return result;
    }
    return "";
  }
}
