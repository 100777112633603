
import { Vue, Options } from "vue-class-component";
import {
  PAYMENT_STATE,
  PaymentProps
} from "@/app/ui/components/dialog-payment/payment-props";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import DialogPaymentLayoutHeader from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutHeader.vue";
import DialogPaymentLayoutImage from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutImage.vue";
import DialogPaymentLayoutButton from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutButton.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { PaymentMethod } from "@/domain/entities/Payment";

@Options({
  components: {
    DialogPaymentLayoutButton,
    DialogPaymentLayoutImage,
    DialogPaymentLayoutHeader
  },
  emits: ["onSubmit"]
})
export default class CashActive extends Vue.with(PaymentProps) {
  changePayment() {
    dataLayer(`${this.billType}_cash_ganti_metode_pembayaran_clicked`, {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(new PaymentMethod());
  }
  paidCash() {
    PaymentController.changeState(PAYMENT_STATE.SUCCESS_STATE);
  }
}
