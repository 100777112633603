import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-4 p-6 rounded-xl border border-gray-lp-1300" }
const _hoisted_2 = { class: "font-semibold text-black-lp-300 flex flex-row" }
const _hoisted_3 = { class: "text-gray-lp-500 font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DataWrapper, { class: "w-full mr-4" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.$t("bookingShipment.column.manualSttNumber")) + " ", 1),
            _createVNode("span", _hoisted_3, "(" + _toDisplayString(_ctx.$t("optional")) + ")", 1)
          ])
        ]),
        _createVNode(_component_lp_input, {
          class: "my-2",
          isFirstInput: _ctx.isFirstInput,
          error: _ctx.error.sttManual,
          errorCaption: _ctx.errorCaptionSttManual,
          disabled: _ctx.isDisabledSttManual,
          errorIcon: false,
          onChange: _ctx.validateSttManual,
          modelValue: _ctx.modelValue.sttManual,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue.sttManual = $event))
        }, null, 8, ["isFirstInput", "error", "errorCaption", "disabled", "onChange", "modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_DataWrapper, {
      label: _ctx.$t('bookingShipment.column.externalRefNumber'),
      optional: _ctx.refNumberOpsionalOrAsterik,
      class: "w-full"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_lp_input, {
          modelValue: _ctx.modelValue.referenceExternal,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.modelValue.referenceExternal = $event)),
          placeholder: "DOR00989",
          errorIcon: false,
          errorCaption: _ctx.errorReferenceNumber,
          showMaxLength: true,
          maxLength: 100,
          onChange: _ctx.validateRefNumber,
          error: this.getErrorMandatory,
          onFocus: _ctx.setErroMandatory
        }, null, 8, ["modelValue", "errorCaption", "onChange", "error", "onFocus"])
      ]),
      _: 1
    }, 8, ["label", "optional"])
  ]))
}