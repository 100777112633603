
import { AccountController } from "@/app/ui/controllers/AccountController";
import BannerProductType from "@/app/ui/views/shipment/booking/component/banner-product-type.vue";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  error = prop<any>({
    required: true,
  });
  formType = prop<string>({
    default: "",
    type: String,
  });
  listKGs = prop<any[]>({
    type: Array,
    default: [],
  });
  isErrorEmbargo = prop<boolean>({
    default: false,
  });
}

@Options({
  components: {
    BannerProductType,
    // call your component here
  },
})
export default class SectionProductUpselling extends Vue.with(Props) {
  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }
}
