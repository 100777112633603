
import { Options, Vue, prop } from "vue-class-component";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { UploadHydraFileRequest, DeleteHydraFileRequest } from "@/data/payload/api/BookingRequest";
import { UploadMultiple } from "@/domain/entities/MainApp";

class Props {
  errorCommodity = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorPhoto = prop<boolean>({
    default: false,
    type: Boolean
  });
  commodity = prop<string>({
    default: "",
    type: String
  });
  photos = prop<string[]>({
    default: [],
    type: Array
  });
  currentIndexError = prop<number>({
    default: 0,
    type: Number
  });
  indexErrorCommodity = prop<number>({
    default: 0,
    type: Number
  });
  indexErrorPhoto = prop<number>({
    default: 0,
    type: Number
  });
  limitError = prop<number>({
    default: 0,
    type: Number
  });
}


const seedImages = new Array(5).fill('');

@Options({
    emits: ["updateCommodity", "updateFiles", "setFieldIndex"],
    components: {
        Tooltip
    },
    watch: {
        "detailCommodity": function() {
            this.updateCommodity();
        }
    }
})
export default class AdditionalInfo extends Vue.with(Props) {
    replaceNewLine(value: string) {
        return removeNewLineWhitespace(value, " ");
    }

    isOpenTooltip = false;
    get proofPhotos() {
        return [
        `${this.$t('bookingShipment.column.photo')} 1`, 
        `${this.$t('bookingShipment.column.photo')} 2`, 
        `${this.$t('bookingShipment.column.photo')} 3`, 
        `${this.$t('bookingShipment.column.photo')} 4`, 
        `${this.$t('bookingShipment.column.photo')} 5`
    ];
    }
    attachmentImage: any = [];
    storageImages: any = seedImages.map((item: string, index: number) => this.photos[index]);
    isError = false;
    errorMessage = "";

    async upload(params: UploadMultiple) {
        this.setFieldIndex(this.indexErrorPhoto);
        this.isError = false;
        this.errorMessage = "";
        try {
            if (params.file) {
                const fileLink = await BookingController.uploadHydraFile(new UploadHydraFileRequest({
                    attachFile: params.file,
                    folderPath: "stt/"
                }))
                this.storageImages[params.index] = fileLink.data;
                this.updateFiles();
            }
        } catch (err) {
            this.isError = true;
            this.errorMessage = this.$t('bookingShipment.error.uploadPhoto', { value: params.index + 1 })
        }
    }

    async remove(index: number) {
        this.isError = false;
        this.errorMessage = "";
        try {
            const del = await BookingController.deleteHydraFile(new DeleteHydraFileRequest({
                attachFiles: [this.storageImages[index]]
            }))
            const statusDel = del.data[this.storageImages[index]];
            statusDel ? this.deleteFiles(index) : this.erroMessageDelete(index);
        } catch (err) {
            this.erroMessageDelete(index);
        }
    }

    detailCommodity = this.commodity;

    updateCommodity() {
        this.$emit("updateCommodity", this.detailCommodity);
    }

    updateFiles() {
        this.$emit("updateFiles", this.storageImages);
    }

    deleteFiles(index: number) {
        this.storageImages[index] = "";
        this.updateFiles();
    }

    setFieldIndex(index: number) {
        this.$emit("setFieldIndex", index);
    }

    erroMessageDelete(index: number) {
        this.deleteFiles(index);
    }

    get currentIndex() {
        return this.currentIndexError === this.limitError;
    }

    get validateLimitUpload() {
        const images = this.storageImages.filter((item: string) => item);
        return images.length < 3 && this.currentIndex;
    }
}
