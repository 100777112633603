/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { ConfigPriceWoodpackingPresenter } from "../presenters/ConfigPriceWoodpackingPresenter";
import { WoodpackingData } from "@/domain/entities/ConfigWoodpacking";
import { ConfigPriceWoodpackingApiRepository } from "@/app/infrastructures/repositories/api/ConfigWoodpackingApiRepository";
import { EditConfigWoodpackingApiRequest } from "@/data/payload/api/ConfigWoodpackingApiRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface ConfigPriceWoodpackingState {
  configPriceWoodpackingData: WoodpackingData;
  isLoading: boolean;
  isError: boolean;
  isErrorEdit: boolean;
  openModal: boolean;
  openSuccess: boolean;
  isRoute: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "ConfigPriceWoodpacking"
})
class ConfigPriceWoodpackingStore extends VuexModule
  implements ConfigPriceWoodpackingState {
  public configPriceWoodpackingData = new WoodpackingData();
  public isLoading = false;
  public isError = false;
  public isErrorEdit = false;
  public openModal = false;
  public openSuccess = false;
  public isRoute = false;
  public errorCause = "";

  @Mutation
  public setConfigPriceWoodpackingData(data: WoodpackingData) {
    this.configPriceWoodpackingData = data;
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorEdit(value: boolean) {
    this.isErrorEdit = value;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setRoute(value: boolean) {
    this.isRoute = value;
  }

  @Action
  public async getDetailWoopacking(params: { id: number }) {
    this.setLoading(true);
    const presenter = container.resolve(ConfigPriceWoodpackingPresenter);
    return presenter
      .getDetailConfigWoodpacking(params.id)
      .then((res: WoodpackingData) => {
        this.setConfigPriceWoodpackingData(res);
        this.setRoute(res.status.toLowerCase() === "active" ? true : false);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return new WoodpackingData();
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async editConfig(params: {
    id: number;
    typeName: string;
    description: string;
    status: string;
    additionalCost: number;
    type: string;
    woodpackingAvailableCities:Array<any>
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = await container.resolve(ConfigPriceWoodpackingApiRepository);
    presenter
      .editConfig(
        new EditConfigWoodpackingApiRequest(
          params.id,
          params.typeName,
          params.description,
          params.status,
          params.additionalCost,
          params.type,
          params.woodpackingAvailableCities
        )
      )
      .then(() => {
        this.setOpenSuccess(true);
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Woodpacking Gagal!", () =>
            this.editConfig(params)
          )
        );
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }
}

export const ConfigPriceWoodpackingController = getModule(
  ConfigPriceWoodpackingStore
);
