
import { Options, prop, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import Discount from "./components/discount.vue";
import DetailEstimation from "./components/detail-estimation.vue";
import Book from "./book.vue";
import { PropType } from "vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import { AccountController } from "@/app/ui/controllers/AccountController";
import ModalErrorBalance from "@/app/ui/views/shipment/booking/component/modal-error-balance.vue";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { PromoConfigurationListData } from "@/domain/entities/PromoConfiguration";
import { EstimationPrice } from "@/domain/entities/Booking";
import { GetEstimationPrice } from "@/data/payload/api/BookingRequest";
import { DetailDiscount } from "@/domain/entities/Tariff";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";

interface DiscountInterface {
  status: boolean;
  data: PromoConfigurationListData;
}

class Props {
  isBooking = prop<boolean>({
    default: false
  });
  onNextPackage = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  bookingType = prop<string>({
    default: "",
    type: String
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  accountRefAndCommodity = prop<any>({
    default: {},
    type: Object
  });
  formType = prop<any>({
    default: "",
    type: String
  });
  discountData = prop<DiscountInterface>({
    default: {
      status: false,
      data: Object
    },
    type: Object
  });
  isCodRetail = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["updateStatusErrorEmbargo"],
  components: {
    Tooltip,
    Book,
    ModalErrorBalance,
    Discount,
    DetailEstimation
  },
  watch: {
    "payload.productName": function() {
      this.resetEstimasiDiscount();
    },
    "payload.destinationCity": function() {
      this.resetEstimasiDiscount();
    },
    "payload.sttGrossWeight": function() {
      this.resetEstimasiDiscount();
    },
     "payload.sttVolumeWeight": function() {
      this.resetEstimasiDiscount();
    }
  }
})
export default class CheckTariff extends Vue.with(Props) {
  isShow = false;

  unmounted() {
    BookingController.setDefaultEstimationPrice();
  }

  async showDetail() {
    if (
      this.formType === "manual" &&
      this.dataProfile.account_type === "partner"
    ) {
      this.isShow = !this.isShow;
      if (this.isShow) {
        const resultData = await this.getEstimationPrice();
        if (resultData) {
          this.setLabelTotalTarif = this.dataDetail.totalTariff;
        } else {
          this.setLabelTotalTarif = 0;
        }
      }
    }
  }

  async updateEstimationPrice() {
    if (this.isBalanceUnderLimitForPos) {
      this.balanceUnderLimit = true;
      return;
    }
    const resultData = await this.getEstimationPrice();
    if (resultData) {
      this.setLabelTotalTarif = this.dataDetail.totalTariff;
    } else {
      this.setLabelTotalTarif = 0;
    }

    this.balanceUnderLimit = this.isBalanceLessThenEstimationForPos;
    return resultData;
  }

  async getEstimationPrice() {
    if (!this.isDisabled) {
      this.showDiscountNominal = true;
      const piecesDelivery: any = [];
      this.payload.sttPieces.map((item: any) => {
        piecesDelivery.push({
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_length: item.stt_piece_length,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_width: item.stt_piece_width,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_height: item.stt_piece_height,
          // eslint-disable-next-line @typescript-eslint/camelcase
          piece_gross_weight: item.stt_piece_gross_weight
        });
      });

      const payload = new GetEstimationPrice({
        originId: this.payload.originDistrict,
        destinationId: this.payload.destinationDistrict,
        commodityId: this.accountRefAndCommodity.commodityId,
        productType: this.payload.productName,
        accountType: this.accountTypePayload,
        accountRefId:
          this.formType === "manual"
            ? AccountController.accountData.account_type_detail.id
            : this.payload.clientName.id,
        goodsPrice: this.payload.estimationPrice,
        insuranceType: this.payload.insurance,
        isWoodpacking: this.payload.isWoodpack,
        isHaveTaxId: !!this.payload.taxNumber,
        pieces: piecesDelivery,
        isCod: this.payload.isCOD,
        isDfod: this.payload.isDFOD,
        codHandling: "",
        isPad: this.payload.isPAD,
        codAmount: this.payload.codAmount,
        isDisablePromo:
          this.formType === "manual" &&
          AccountController.accountData.isPosAccount
            ? false
            : true,
        promoAppliedTo: "retail"
      })
      if (this.isCodRetail) {
        this.getEstimationForCod(payload);
      }
      return BookingController.getEstimationPrice(payload);
    }
  }

  getEstimationForCod(payload: any)  {
    const payloadCod = new GetEstimationPrice({
      ...payload,
      originId: this.payload.destinationDistrict,
      destinationId: this.payload.originDistrict,
      isCod: false,
      isDfod: false,
      isWoodpacking: false,
      isDisablePromo: true
    });
    return BookingController.getEstimationPriceCodRetail(payloadCod);
  }

  get accountTypePayload() {
    let accountType = this.formType;
    if (this.formType === "manual") {
      accountType =
        AccountController.accountData.account_type === "partner"
          ? "pos"
          : AccountController.accountData.account_type;
    }

    return accountType;
  }

  get dataDetail() {
    if (!this.isDisabled) {
      return {
        ...BookingController.estimationPrice,
        discountBoards: BookingController.estimationPrice.listDiscount
          .filter((item: DetailDiscount) => item.pdcCategory === 'tactical' || item.pdcCategory === 'campaign')
      };
    }

    return {
      totalTariff: 0,
      discount: 0,
      isPromo: false
    };
  }

  bigLabelTotalTariff = 0;
  get getLabelTotalTarif() {
    return this.bigLabelTotalTariff;
  }

  set setLabelTotalTarif(value: number) {
    this.bigLabelTotalTariff = value;
  }
  get setLabelTotalTarif() {
    return this.bigLabelTotalTariff;
  }

  isOpenTooltip = false;
  onOpenTooltip(value: boolean) {
    this.isOpenTooltip = value;
  }

  onCloseAnywhere(value: boolean) {
    this.isShow = value;
  }

  currency(curr: number) {
    return this.accountIsNonForeign ? this.currencyRupiah(curr) : this.currencyRinggit(curr);
  }

  currencyRupiah(curr: number) {
    if (curr) {
      return formatPriceIDR(curr);
    }
    return formatPriceIDR(0);
  }

  currencyRinggit(curr: number) {
    if (curr) {
      return formatPriceRM(curr);
    }
    return formatPriceRM(0);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get balanceData() {
    return BalanceController.balanceData;
  }

  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    if (this.formType === "manual") {
      return (
        BalanceController.balanceData.walletBalance <
          this.dataProfile.limit_rule_min_balance &&
        this.dataProfile.account_type === "partner"
      );
    }
    return false;
  }
  get isBalanceLessThenEstimationForPos() {
    return (
      BalanceController.balanceData.walletBalance < this.setLabelTotalTarif &&
      this.dataProfile.account_type === "partner"
    );
  }

  get isErrorEmbargo() {
    return BookingController.isErrorEmbargo;
  }

  showDiscountNominal = false;
  get isDiscountActive() {
    return this.discountData.status || this.dataDetail.isPromo;
  }

  resetEstimasiDiscount() {
    this.setLabelTotalTarif = 0;
    BookingController.setEstimationPrice(
      new EstimationPrice({
        totalTariffAfterDiscount: 0,
        totalTariff: 0,
        isPromo: false
      })
    );
  }

  get prefixCurrency() {
    return this.accountIsNonForeign ? "IDR -" : "RM -";
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }
}
