
import { Options, Vue, prop } from "vue-class-component";
import isDisableSttManual from "@/app/infrastructures/misc/common-library/IsDisableSttManual";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import debounce from "lodash/debounce";
import router from "@/app/ui/router";
import Tooltip from "@/app/ui/components/tooltip/index.vue";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  error = prop<any>({
    required: true,
  });
  formType = prop<string>({
    default: "",
    type: String,
  });
  isFirstInput = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isClientDO = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isDO = prop<boolean>({
    default: false,
    type: Boolean,
  });
  errorMandatory = prop<any>({
    default: false,
    type: Boolean,
  });
  isSttRetail = prop<boolean>({
    default: false,
    type: Boolean,
  });
  checkoutPaymentMethod = prop<string>({
    default: "",
    type: String,
  });
  disabledRefExt = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isSttForeign = prop<boolean>({
    default: false,
    type: Boolean,
  });
}

@Options({
  emits: ["setFieldIndex", "checkFocus"],
  components: {
    Tooltip,
  }
})
export default class SectionSTTManualReference extends Vue.with(Props) {
  errorCaptionSttManual = "";
  errorReferenceNumber = "";
  isErrorRefNumber = false;

  get isDisabledSttManual() {
    return isDisableSttManual(this.formType);
  }

  checkFocus() {
    this.$emit("checkFocus");
  }

  isOpenTooltipShipmentID = false;

  validateSttManual = debounce(async (value: string) => {
    if (value === "") {
      this.errorCaptionSttManual = "";
      this.error.sttManual = false;
      return;
    } else if (value.length < 3) {
      this.errorCaptionSttManual = "Format nomor STT manual minimum 3 angka.";
      this.error.sttManual = true;
    } else {
      const result = await BookingController.validateSttManual({
        clientId: this.getValidateSttManual,
        sttManual: value,
        bookingType:
          router.currentRoute.value.meta.formType === "manual" &&
          this.getAccount.accountType === "partner"
            ? "pos"
            : "client",
        productType: this.modelValue.productName,
      });
      this.error.sttManual = !result.isAllowBooking;
      this.errorCaptionSttManual = result.errorMessage;
    }
  }, 250);

  validateRefNumber(value: string) {
    if (value === "") {
      if (
        (this.formType === "manual" &&
          this.getAccount.accountType === "client") ||
        this.formType === "client"
      ) {
        this.errorReferenceNumber = this.$t("bookingShipment.error.noRef");
      } else {
        this.errorReferenceNumber = "";
        this.isErrorRefNumber = false;
      }
    } else if (value.length < 3) {
      this.errorReferenceNumber = this.$t("bookingShipment.error.minimumRef");
      this.isErrorRefNumber = true;
    } else {
      this.errorReferenceNumber = "";
      this.isErrorRefNumber = false;
    }
  }

  get getValidateSttManual() {
    if (router.currentRoute.value.meta.formType === "client") {
      return this.modelValue.clientName.id;
    } else if (this.getAccount.accountType === "client") {
      return this.getAccount.accountClientId;
    } else {
      return "";
    }
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
    };
  }

  get getErrorMandatory() {
    if (this.formType != "client" && this.formType != "client") {
      return this.isErrorRefNumber;
    } else {
      return this.errorMandatory;
    }
  }

  setErroMandatory() {
    if (this.formType != "client" && this.formType != "client") {
      return;
    } else {
      this.$emit("setFieldIndex", 2);
    }
  }

  get refNumberOpsionalOrAsterik() {
    if (
      this.formType === "manual" &&
      this.getAccount.accountType === "client"
    ) {
      return !this.isDO;
    } else if (this.formType === "client") {
      return !this.isClientDO;
    } else {
      return true;
    }
  }
}
