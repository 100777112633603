
import { Vue, prop } from "vue-class-component";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  condition = prop<number>({
    type: Number,
    default: 0
  });
  commission = prop<number>({
    type: Number,
    default: 0
  });
  profit = prop<number>({
    type: Number,
    default: 0
  });
}


export default class InfoInsurance extends Vue.with(Props) {
  get content() {
    return this.accountIsNonForeign ? this.contentForIndonesian : this.contentForEnglish;
  }

  get contentForIndonesian() {
    const banner: any = {
      1: {
        status: true,
        msg: `<strong>Asuransi Basic</strong> otomatis terpilih. Semakin naik harga barang, semakin <strong>untung ${this.commission}%!</strong>`,
      },
      2: {
        status: true,
        msg: `Anda <strong>untung ${formatPriceRP(this.profit)}</strong> dari <strong>Asuransi Basic</strong>`,
      },
      3: {
        status: false,
        msg: `Dapatkan <strong>untung ${this.commission}%</strong> dengan pilih <strong>Asuransi Basic!</strong>`,
      },
      4: {
        status: true,
        msg: `<strong>Asuransi Basic</strong> terpilih. Semakin naik harga barang, semakin <strong>untung ${this.commission}%!</strong>`,
      },
      5: {
        status: false,
        msg: `Pengiriman <strong>COD</strong> sudah tercover <strong>asuransi</strong>`,
      },
      6: {
        status: true,
        msg: `<strong>Asuransi Basic</strong> terpilih karena harga barang melebihi 5 juta & Anda sudah <strong>untung ${formatPriceRP(this.profit)}</strong>`,
      },
    }

    return banner[this.condition]
  }

  get contentForEnglish() {
    const banner: any = {
      1: {
        status: true,
        msg: `Auto-selected <strong>Basic Insurance, enjoy the benefits</strong> of insurance rates/item prices!`
      },
      2: {
        status: true,
        msg: `You <strong>earned</strong> ${formatPriceRM(this.profit)} with <strong>Basic Insurance</strong>`,
      },
      3: {
        status: false,
        msg: `Get <strong>${this.commission}% profit</strong> with <strong>Basic Insurance!</strong>`
      },
      4: {
        status: true,
        msg: `Auto-selected <strong>Basic Insurance, enjoy the benefits</strong> of insurance rates/item prices!`
      },
      6: {
        status: true,
        msg: `Auto-selected <strong>Basic Insurance</strong> because the item price exceeds RM1492 & <strong>profit ${formatPriceRM(this.profit)}</strong>`
      },
    }

    return banner[this.condition]
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }


}
