
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import ModalDetailShipment from "@/app/ui/views/shipment/booking/create-booking-v2/modal-detail-shipment.vue";
import ModalAddShipmentDestination from "@/app/ui/views/shipment/booking/create-booking/modal-add-shipment-destination.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
// import custNameAlgoEstimationShow from "@/app/infrastructures/misc/common-library/CustNameAlgoEstimationShow";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { DistrictClientRequest } from "@/data/payload/api/LocationRequest";
import { DistrictClient } from "@/domain/entities/Location";
import { ShipmentId, ShipmentIdList } from "@/domain/entities/Booking";
import { Pagination } from "@/domain/entities/Pagination";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import parseQRCode from "@/app/infrastructures/misc/common-library/ParseQRcode";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import {
  maxKg,
  SHIPMENT_ID_FAVOURITE,
  codePhoneCountry,
  totalGrossWeightSikat,
  totalGrossWeightNonSikat,
  clientMapperSikat,
  promoPrefix,
  MAX_WEIGHT_SHIPMENT,
  prefixDisableDimension,
  volumeWeightForProduct
} from "@/app/ui/views/shipment/booking/booking-utils";
import { ProductConfigurationRuleList } from "@/domain/entities/Product";
import {
  payloadOfThermalData,
  downloadFilePrn
} from "@/app/infrastructures/misc/UtilsThermal";
import { ThermalReceiptData } from "@/domain/entities/Printer";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";
import { flags } from "@/feature-flags";
import { WeightDimension } from "@/domain/entities/Booking";

@Options({
  components: {
    CustomDropdown,
    Tooltip,
    ModalDetailShipment,
    ModalAddShipmentDestination,
    Skeleton
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class AddShipmentId extends Vue {
  get isBookingSuccess() {
    return BookingController.isBookingSuccess;
  }

  set isBookingSuccess(value: boolean) {
    BookingController.isBookingSuccess = value;
  }

  indexElement = 0;
  isSetFilter = false;

  formatPrice(value: number) {
    return formatPrice(value);
  }

  get shipmentList() {
    return BookingController.temporaryShipmentIdList;
  }

  get detailShipment() {
    return {
      shipmentAlgoId: this.shipmentIdList[this.indexElement].shipmentAlgoId,
      shipmentProduct: this.shipmentIdList[this.indexElement].shipmentProduct,
      shipmentNoRefExt: this.shipmentNoRefExt
        ? this.shipmentIdList[this.indexElement].dataRaw?.ticket_code
        : this.shipmentIdList[this.indexElement].STT?.sttNoRefExternal || "",
      shipmentOriginCity: this.shipmentIdList[this.indexElement]
        .shipmentOriginCity,
      shipmentOriginDistrict: this.shipmentIdList[this.indexElement]
        .shipmentOriginDistrict,
      shipmentDestinationCity: this.shipmentIdList[this.indexElement]
        .shipmentDestinationCity,
      shipmentDestinationDistrict: this.shipmentIdList[this.indexElement]
        .shipmentDestinationDistrict,
      shipmentSenderName: this.shipmentIdList[this.indexElement]
        .shipmentSenderName,
      shipmentSenderAddress: this.shipmentIdList[this.indexElement]
        .shipmentSenderAddress,
      shipmentSenderPhone: this.shipmentIdList[this.indexElement]
        .shipmentSenderPhone,
      shipmentRecipientName: this.shipmentIdList[this.indexElement]
        .shipmentRecipientName,
      shipmentRecipientAddress: this.shipmentIdList[this.indexElement]
        .shipmentRecipientAddress,
      shipmentRecipientPhone: this.shipmentIdList[this.indexElement]
        .shipmentRecipientPhone,
      shipmentCommodityCode: this.shipmentIdList[this.indexElement]
        .shipmentCommodityCode,
      shipmentCommodityName: this.shipmentIdList[this.indexElement]
        .shipmentCommodityName,
      shipmentGoodsStatus: this.shipmentIdList[this.indexElement]
        .shipmentGoodsStatus,
      shipmentInsurance: this.shipmentIdList[this.indexElement]
        .shipmentInsurance,
      shipmentGoodsPriceEstimation: this.shipmentIdList[this.indexElement]
        .shipmentGoodsPriceEstimation,
      shipmentNpwpNumber: this.shipmentIdList[this.indexElement]
        .shipmentNpwpNumber,
      shipmentGoodDetail: this.shipmentIdList[this.indexElement]
        .shipmentGoodDetail,
      shipmentGoodDetailKeeped: this.shipmentIdList[this.indexElement]
        .shipmentGoodDetailKeeped,
      shipmentIsCod: this.shipmentIdList[this.indexElement].shipmentIsCod,
      shipmentIsWoodpacking: false,
      shipmentPackageid: this.shipmentIdList[this.indexElement]
        .shipmentPacketId,
      shipmentChargeableWeight: this.shipmentIdList[this.indexElement]
        .shipmentChargeableWeight,
      customerName: this.shipmentIdList[this.indexElement].customerName,
      isAdjustable: this.shipmentIdList[this.indexElement].isAdjustable,
      maxAdjustable: this.shipmentIdList[this.indexElement].maxAdjustable,
      STT: this.shipmentIdList[this.indexElement].STT,
      isSaveSender: false,
      isSaveReceiver: false,
      taxNumber: "",
      shipmentCodAmount: this.shipmentIdList[this.indexElement].codAmount,
      shipmentSttClientId: this.shipmentIdList[this.indexElement]
        .shipmentSttClientId
    };
  }

  // shipment id
  shipmentId = "";

  async fetchShipmentId(status: string) {
    if (!this.shipmentId) return;
    this.handleError();
    this.isSetFilter = false;
    this.onResetAdvancedFilter();
    const shipmentId = this.shipmentId;
    if (shipmentId.startsWith("TKP01")) {
      const shipment: any = await BookingController.getShipmentDetailFromMiddleware(
        {
          ticketCode: shipmentId
        }
      );
      if (shipment) {
        playNotification("success");
        if (BookingController.scannedShipmentIDs.includes(this.shipmentId)) {
          return;
        }
        // const origin = LocationController.districtDetail;
          const shipmentData = BookingController.shipmentIdList.data;
          const newShipmentData = [];
          let i = 0;
          for (const el of shipmentData) {
            try {
              const district = await LocationController.getDistrictClient({
                id: el.shipmentDestinationCityKR,
                args: new DistrictClientRequest({
                  clientId: this.clientIdForCourierRecommendation
                })
              });
              this.setDistrict(district, i);
              i++;
              newShipmentData.push(
                new ShipmentId({
                  ...el,
                  shipmentDestinationCity: {
                    code: district.cityCode,
                  },
                  shipmentDestinationDistrict: {
                    code: district.code
                  },
                  shipmentSttClientId: this.clientIdForCourierRecommendation
                })
              );
            } catch (error) {
              i++;
              newShipmentData.push(
                new ShipmentId({
                  ...el,
                  shipmentSttClientId: this.clientIdForCourierRecommendation
                })
              );
            }

          }

        BookingController.addScannedShipmentID(shipmentId);
        BookingController.setShipmentIdList(
          new ShipmentIdList({
            pagination: new Pagination(1, 99),
            data: newShipmentData
          })
        );
        newShipmentData.forEach(value => {
          BookingController.pushTemporaryShipmentIdList(value);
        });
      }
    } else {
      if (
        BookingController.scannedShipmentIDs.includes(this.shipmentId) &&
        status === "inputShipmentId"
      )
        return;
      await BookingController.getShipmentIdList({
        id: shipmentId,
        limit: 100,
        page: 1
      });
    }

    if (status === "inputShipmentId") {
      dataLayer("shipment_booking_pengiriman_button_clicked", {}, [
        "timestamp",
        "userType",
        "userId"
      ]);
    }
  }

  setDistrict(district: any, index: number) {
    const shipmentData = BookingController.shipmentIdList.data;
    if (district) {
      shipmentData[index].shipmentDestinationDistrict = new DistrictClient({
        id: district.id,
        name: `${district.name}, ${district.cityName}`,
        code: district.code,
        cityCode: district.cityCode,
        type: district.type,
        vendorCode: district.vendorCode
      });
    }
  }

  get clientIdForCourierRecommendation() {
    return FlagsMedusa.client_id_courrier_recommendation_config.getValue();
  }

  get isGenerate() {
    return BookingController.isGenerate;
  }

  get isLoading() {
    return BookingController.isLoading;
  }

  // Error
  get isError() {
    return BookingController.isError;
  }

  get errorCause() {
    return BookingController.isErrorCause;
  }

  handleError() {
    BookingController.setError(false);
  }

  // sort
  sortBy = "";
  isExpand = false;
  get sortOptions() {
    return ["Tanggal STT", "No. STT"];
  }
  setSortBy(item: string) {
    if (this.sortBy === item) this.sortBy = "";
    else {
      this.sortBy = item;
    }
    this.isSetFilter = true;
    this.isExpand = false;
  }

  //order by

  orderByTitle = "";
  orderByValue = "";
  orderBySelect = false;
  orderByData = [
    { name: "Semua", value: "" },
    { name: "Sudah Booking", value: "booked" },
    { name: "Sebelum Booking", value: "before_booked" }
  ];

  onOpenOrderBy() {
    this.orderBySelect = true;
  }

  onCloseOrderBy() {
    this.orderBySelect = false;
  }

  onSelectOrderBy(name: string, value: string) {
    this.orderByValue = value;
    this.orderByTitle = name;
    this.isSetFilter = true;
  }

  onResetAdvancedFilter() {
    this.sortBy = "";
    this.orderByValue = "";
    this.orderByTitle = "";
  }

  get sumFilter(): number {
    return this.orderByValue ? 1 : 0;
  }

  // Add destination
  get showAddDestination() {
    const prefix = this.shipmentId.substr(0, 2);
    return (
      (prefix === "AP" || prefix === "AS") && this.shipmentIdList.length > 0
    );
  }

  hideAddDestinationWhenIsCod() {
    const shipmentId = this.shipmentId;
    if (shipmentId.slice(0, 2) === "AC") {
      return false;
    }
  }

  get enableAddDestination() {
    return this.shipmentIdList.length < 100;
  }
  onAddDestination() {
    ShipmentBookingController.setBookingAddShipmentDestination(true);
    dataLayer("shipment_tambah_alamat_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }
  get bookingAddShipmentDestination() {
    return ShipmentBookingController.bookingAddShipmentDestination;
  }

  // tooltip
  isOpenTooltip = false;

  // booked count
  get bookedCount() {
    return this.shipmentIdList.filter(item => item.STT?.sttId).length;
  }

  // Table
  pagination = {
    page: 1,
    limit: 20
  };

  get shipmentIdList() {
    let newData: ShipmentId[] = [];
    switch (this.orderByValue) {
      case "booked":
        newData = this.shipmentList.filter(item => item.STT?.sttId);
        break;
      case "before_booked":
        newData = this.shipmentList.filter(item => !item.STT?.sttId);
        break;
      case "":
        newData = this.shipmentList;
        break;
    }
    const collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base"
    });
    this.chevronUp = newData.map(() => false);
    return this.sortBy === this.sortOptions[0]
      ? [
          ...this.sortShipment(newData, collator),
          ...newData.filter(item => !item.STT?.sttId)
        ]
      : [
          ...newData.filter(item => !item.STT?.sttId),
          ...this.sortShipment(newData, collator)
        ];
  }

  sortShipment(data: any, collator: any) {
    let dataFiltered = [];
    if (data) {
      dataFiltered = data
        .filter((item: ShipmentId) => item.STT?.sttId)
        .sort((a: any, b: any) =>
          collator.compare(a.STT ? a.STT.sttNo : "", b.STT ? b.STT.sttNo : "")
        )
        .reverse();
    }

    return dataFiltered;
  }

  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-20 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any, index: any) => {
          return `<div class='text-left'>${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: this.isHideBookingIDColumn
          ? "Shipment/booking ID"
          : "Shipment ID",
        styleHead: "w-48 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
            ${
              item.shipmentId
                ? item.shipmentId
                : item.shipmentAlgoId
                ? item.shipmentAlgoId
                : item.STT?.shipmentAlgoId ?? ""
            }
          </div>`;
        }
      },
      {
        name: "Booking ID",
        styleHead: "w-40 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
            ${item.shipmentBookingId}
          </div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-50 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
            ${(item.STT && item.STT.sttNo) || "-"}
          </div>`;
        }
      },
      {
        name: "Jenis pengiriman",
        styleHead: "w-44 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return item.STT?.sttId || item.shipmentProduct
            ? `
          <img
            src="${require(`@/app/ui/assets/svg/product-logo/${this.mapProductType(
              (item.STT && item.STT.sttProductType.toLowerCase()) ||
                item.shipmentProduct.toLowerCase()
            )}.svg`)}"
            class="w-36"
            alt="icon"
          />`
            : `
          <div class='flex'><div class='rounded px-2 py-0 bg-gray-lp-400 text-black-lp-300'>
            ${(item.STT?.sttId && item.STT.sttProductType) ||
              item.shipmentProduct}
          </div></div>`;
        }
      },
      {
        name: "Koli",
        styleHead: "w-32 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
            ${(item.STT?.sttId && item.STT.sttPieces.length) ||
              item.shipmentGoodDetail.length}
          </div>`;
        }
      },
      {
        name: "Berat",
        styleHead: "w-32 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left'>
            ${(item.STT?.sttId && item.STT.sttChargeableWeight) ||
              item.shipmentChargeableWeight} Kg
          </div>`;
        }
      },
      {
        name: "Total Tarif",
        styleHead: "w-40 text-left whitespace-no-wrap text-12px md:text-16px",
        styleBody: "text-red-lp-200 text-center",
        render: (item: any) => {
          return `<div class='text-left text-red-lp-100 font-bold'>${this.totalTariff(
            item
          )}</div>`;
        }
      }
    ];
    if (this.isHideBookingIDColumn) {
      columns.splice(2, 1);
    }
    return [...columns, ...this.columnsAction];
  }

  get columnsAction() {
    return [
      {
        name: "Atur",
        key: "double_button_column",
        styleHead: "w-72 text-left text-16px md:text-16xpx font-semibold",
        styleButton: (item: any, index: any) => {
          return item.STT?.sttId
            ? {
                outline: false,
                color: "white"
              }
            : {
                outline: true,
                title: "Ubah",
                textColor: "red-lp-300",
                color: "red-lp-300",
                small: false,
                isWidthFull: true,
                clickFunction: () => {
                  this.onBooking(item, index)
                }
              };
        },
        styleButtonTwo: (item: any, index: any) => {
          return item.STT?.sttId
            ? {
                outline: true,
                icon: "chevron-up",
                title: "Print",
                textColor: "red-lp-300",
                color: "red-lp-300",
                small: false,
                class: `icon-chevron-up ${
                  this.chevronUp[index] ? "" : "icon-chevron-down"
                }`,
                clickFunction: () => {
                  this.chevronUp[index] = !this.chevronUp[index];
                },
                groupOptions: [
                  {
                    icon: "printer",
                    label: "Print Thermal Resi",
                    clickFunction: () =>
                      this.print(
                        item.STT.sttId,
                        "thermal",
                        item.STT.sttProductType === "INTERPACK" ? "en" : "id"
                      )
                  },
                  {
                    icon: "document-download-outline-grey",
                    label: "Print Thermal Resi PDF",
                    clickFunction: () =>
                      this.print(
                        item.STT.sttId,
                        "thermal-pdf",
                        item.STT.sttProductType === "INTERPACK" ? "en" : "id"
                      )
                  },
                  {
                    icon: "document-download-outline-grey",
                    label: "Print Basic/Save to PDF",
                    clickFunction: () =>
                      this.print(
                        item.STT.sttId,
                        "basic",
                        item.STT.sttProductType === "INTERPACK" ? "en" : "id"
                      )
                  }
                ]
              }
            : {
                outline: false,
                icon: "chevron-up",
                title: "Booking",
                textColor: "white",
                color: "red-lp-100",
                small: false,
                class: `icon-chevron-up-white ${
                  this.chevronUp[index] ? "" : "icon-chevron-down"
                }`,
                clickFunction: () => {
                  this.chevronUp[index] = !this.chevronUp[index];
                },
                groupOptions: [
                  {
                    icon: "check-circle-border",
                    label: "Booking, Print Nanti",
                    clickFunction: () => this.action(0, index, item)
                  },
                  {
                    icon: "printer",
                    label: "Booking, Print Thermal Resi",
                    clickFunction: () => this.action(1, index, item)
                  },
                  {
                    icon: "document-download-border",
                    label: "Booking, Print Thermal Resi PDF",
                    clickFunction: () => this.action(2, index, item)
                  },
                  {
                    icon: "document-download-border",
                    label: "Booking, Print Basic/Save to PDF",
                    clickFunction: () => this.action(3, index, item)
                  }
                ]
              };
        }
      }
    ];
  }

  totalTariff(item: any) {
    return item.STT !== null && item.STT?.sttTotalAmount
      ? formatPrice(item.STT?.sttTotalAmount)
      : "-";
  }

  chevronUp: boolean[] = [];

  refs: any = "";

  get isHideBookingIDColumn() {
    return !this.shipmentIdList.find((key: any) => !!key.shipmentBookingId);
  }

  mounted() {
    this.refs = this.$refs;
    this.initPage();
  }

  unmounted() {
    BookingController.setAnswer(false);
    BookingController.clearShipmentIdList();
    BookingController.setIsEditTerminated(false);
  }

  initPage() {
    this.shipmentId = "";
    BookingController.resetShipmentIdList();
    ShipmentBookingController.setShowModalDetailBookingShipment(false);
    this.setGenerate(false);
  }

  async onBooking(item: any, index: number) {
    ShipmentBookingController.setSelectedUniqueId(item.uniqueId);
    dataLayer("shipment_booking_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    ShipmentBookingController.setIndexElement(index);
    ShipmentBookingController.setShowModalDetailBookingShipment(true);
    ShipmentBookingController.setDetailBookingShipment(item);
  }

  get showModalShipment() {
    return ShipmentBookingController.showModalDetailBookingShipment;
  }

  setGenerate(boolean: boolean) {
    BookingController.setGenerate(boolean);
  }

  // route navigation
  goBack() {
    this.$router.push("/shipment/booking");
  }

  // Close when changed
  get isEditTerminated() {
    return BookingController.isEditTerminated;
  }

  set isEditTerminated(value: boolean) {
    BookingController.setIsEditTerminated(value);
  }

  get newPath() {
    return BookingController.newPath;
  }

  set newPath(value: string) {
    BookingController.setNewPath(value);
  }

  get answer() {
    return BookingController.answer;
  }

  set answer(value: boolean) {
    BookingController.setAnswer(value);
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  parseInputShipmentId(e: any) {
    const value = e.target.value;
    if (String(value).includes("#")) {
      const val = String(value).split("#")[0];
      this.shipmentId = val;
      return val;
    }
    this.shipmentId = parseQRCode(value);
    return value;
  }

  async print(sttId: number, type: string, language = "id") {
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () => book.default.methods.printThermal(sttId, language),
      "thermal-pdf": () =>
        book.default.methods.printBasic(sttId, 0, language, false, true),
      basic: () => book.default.methods.printBasic(sttId, 1, language)
    };

    printType[type]();
  }

  mapProductType(value: string) {
    return value.toLocaleLowerCase() + "-active";
  }

  get discount() {
    return ShipmentBookingController.discount;
  }

  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }

  get printData() {
    return BookingController.generatePrintData;
  }

  async action(index: number, indexElement: number, item: any) {
    ShipmentBookingController.setSelectedUniqueId(item.uniqueId);
    this.indexElement = indexElement;
    ShipmentBookingController.setIndexElement(indexElement);
    if (
      this.isBalanceUnderLimitForPos &&
      prefixAlgoEstimationShow(this.shipmentIdList[indexElement].shipmentAlgoId)
    ) {
      this.balanceUnderLimit = true;
      return;
    }
    this.lists();
    ShipmentBookingController.setDetailBookingShipment(item);
    switch (index) {
      case 0:
        this.bookPrintLater();
        break;
      case 1:
        await this.bookPrintThermal();
        break;
      case 2:
        await this.bookPrintDoc("thermal-pdf");
        break;
      default:
        await this.bookPrintDoc("basic");
        break;
    }
  }

  bookPrintLater() {
    this.bookingLater();
  }

  bookingLater() {
    const events: any = {
      shipment: "shipment_booking_print_nanti_button_clicked",
      addAddress: "booking_tambah_alamat_print_nanti_button_clicked"
    };

    dataLayer(events["shipment"], {}, ["timestamp", "userType", "userId"]);
    if (!BookingController.guardBookingSubmit) {
      BookingController.setGuardBookingSubmit(true);
      return ShipmentBookingController.addShipmentBooking(this.payload);
    }
  }

  async bookPrintThermal() {
    const events: any = {
      shipment: "shipment_booking_print_thermal_button_clicked",
      addAddress: "booking_tambah_alamat_print_thermal_button_clicked"
    };

    dataLayer(events["shipment"], {}, ["timestamp", "userType", "userId"]);

    const book = await this.bookingAndPrintSaveDoc();
    if (!book) {
      return;
    }
    const print = await this.generatePrintData();
    if (book && print) {
      const accountType = AccountController.accountData.account_type;
      const payload = payloadOfThermalData(this.printData, accountType).map(
        (item: ThermalReceiptData) => ({
          ...item,
          isHubToHub: this.printData.isHubToHub,
          sttBookedForName: this.printData.sttBookedForName,
          otherShipperTicketCode: this.printData.otherShipperTicketCode,
          sttIsCodRetail:
            !this.printData.sttShipmentId &&
            !this.printData.sttClientId &&
            (accountType === "partner" || accountType === "internal") &&
            item.sttIsCod
        })
      );
      downloadFilePrn(payload, this.$i18n.locale);

      if (this.printData.sttProductType.toLowerCase() === "interpack") {
        const printedLoad: any = await import(
          "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
        );
        printedLoad.default.methods.directPrintInterpack(this.printData.sttId);
      }
    }
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    return (
      BalanceController.balanceData.walletBalance <
      this.dataProfile.limit_rule_min_balance
    );
  }

  get payload(): any {
    BookingController.checkTransportAndEstimateSlaBooking({
      originCity:
        this.origin.cityCode ||
        this.shipmentIdList[this.indexElement].shipmentOriginCity,
      destinationCity: this.shipmentIdList[this.indexElement]
        .shipmentDestinationCity.code,
      originDistrict: this.origin.code,
      destinationDistrict: this.shipmentIdList[this.indexElement]
        .shipmentDestinationDistrict.code,
      product: this.shipmentIdList[this.indexElement]?.shipmentProduct
    }).then(response => {
      this.estimateSla = response;
    });

    return {
      ...this.payloadSectionOne,
      ...this.payloadSectionTwo,
      ...this.payloadSectionThree,
      isFromBooking: true
    };
  }

  get payloadSectionOne() {
    return {
      sttManual: !this.manualBooking.sttManual.id
        ? ""
        : this.manualBooking.sttManual.id,
      referenceExternal: this.shipmentNoRefExt,
      productName: this.shipmentIdList[this.indexElement].shipmentProduct,
      originCity: this.origin.cityCode,
      originDistrict: this.origin.code,
      destinationCity: this.shipmentIdList[this.indexElement]
        .shipmentDestinationCity.code,
      destinationDistrict: this.shipmentIdList[this.indexElement]
        .shipmentDestinationDistrict.code,
      phoneSender: `${this.shipmentIdList[
        this.indexElement
      ].shipmentSenderPhoneCode.substring(1)}${
        this.shipmentIdList[this.indexElement].shipmentSenderPhone
      }`,
      phoneReceiver: `${this.shipmentIdList[
        this.indexElement
      ].shipmentRecipientPhoneCode.substring(1)}${
        this.shipmentIdList[this.indexElement].shipmentRecipientPhone
      }`,
      receiver: this.shipmentIdList[this.indexElement].shipmentRecipientName,
      sender: this.shipmentIdList[this.indexElement].shipmentSenderName,
      addressReceiver: this.shipmentIdList[this.indexElement]
        .shipmentRecipientAddress,
      addressSender: this.shipmentIdList[this.indexElement]
        .shipmentSenderAddress,
      isSaveSender: false,
      isSaveReceiver: false
    };
  }

  get payloadSectionTwo() {
    const commodityCode: any = this.shipmentIdList[this.indexElement]
      .shipmentCommodityName;
    return {
      commodityCode: this.payloadCommodityCode(commodityCode),
      commodityId:
        typeof commodityCode === "object"
          ? commodityCode.id
          : this.shipmentIdList[this.indexElement].shipmentCommodityId,
      insurance: this.shipmentIdList[this.indexElement].shipmentInsurance,
      statusGoods: this.shipmentIdList[this.indexElement].shipmentGoodsStatus,
      estimationPrice: Number(
        this.shipmentIdList[this.indexElement].shipmentGoodsPriceEstimation
          .toString()
          .replace(/[.]/g, "")
      ),
      taxNumber: this.shipmentIdList[this.indexElement].shipmentNpwpNumber,
      isWoodpack: this.shipmentIdList[this.indexElement].shipmentIsWoodpacking,
      isCOD: this.shipmentIdList[this.indexElement].shipmentIsDfod
        ? true
        : this.shipmentIdList[this.indexElement].shipmentIsCod,
      isDFOD: this.shipmentIdList[this.indexElement].shipmentIsDfod
    };
  }

  get payloadSectionThree() {
    return {
      sttPieces: this.convertedArr,
      isDO: this.shipmentIdList[this.indexElement].isDO,
      isMixpack: false,
      codAmount:
        this.shipmentIdList[this.indexElement].shipmentIsDfod ||
        this.shipmentIdList[this.indexElement].codDfodValue === "non-cod"
          ? 0
          : Number(
              this.shipmentIdList[this.indexElement].codAmount
                .toString()
                .replace(/[.]/g, "")
            ),
      codFee: Number(
        this.shipmentIdList[this.indexElement].codFee
          .toString()
          .replace(/[.]/g, "")
      ),
      shipmentPackageId: this.shipmentIdList[this.indexElement]
        .shipmentPacketId,
      shipmentId: this.shipmentIdList[this.indexElement].shipmentAlgoId,
      sttId: this.shipmentIdList[this.indexElement].STT?.sttId || 0,
      sttPiecePerPack: Number(
        this.shipmentIdList[this.indexElement].piecePerPack || 0
      ),
      sttNextCommodity:
        this.shipmentIdList[this.indexElement].nextCommodity || "",
      otherShipperTicketCode:
        this.shipmentIdList[this.indexElement].dataRaw
          ?.other_shipper_ticket_code || "",
      postalCodeDestination:
        this.shipmentIdList[this.indexElement].postalCodeDestination?.id || "",
      sttGrossWeight: Number(
        this.totalGrossWeight.toString().replace(",", ".")
      ),
      sttVolumeWeight: Number(
        this.totalVolumeWeight.toString().replace(",", ".")
      ),
      sttChargeableWeight: this.isCampaign
        ? Number(this.totalCampaignChargeableWeight)
        : Number(this.totalChargeableWeight.toString().replace(",", ".")),
      sttClientIdBranch: this.shipmentIdList[this.indexElement]
        .shipmentClientIdBranch,
      shipmentC1Cod: this.shipmentC1Cod,
      promoPrefix: this.promoPrefix,
      codHandling: this.shipmentC1Cod
        ? this.shipmentIdList[this.indexElement].shipmentCodHandling
        : "",
      discountFavoritePercentage: this.isShipmentFavorite
        ? this.shipmentIdList[this.indexElement].discountFavoritePercentage
        : 0,
      sttWeightAttachFiles: this.storageImages.filter(key => key !== ""),
      sttRecipientAddressType: this.shipmentIdList[this.indexElement]
        .sttRecipientAddressType
    };
  }

  get maxPhoto() {
    return flags.flag_max_photo_for_weight_adjustment.getValue();
  }

  storageImages = new Array(this.maxPhoto).fill("");

  get isShipmentFavorite() {
    return SHIPMENT_ID_FAVOURITE.includes(
      this.shipmentIdList[this.indexElement].shipmentAlgoId.slice(0, 2)
    );
  }

  get promoPrefix() {
    return prefixAlgoEstimationShow(
      this.shipmentIdList[this.indexElement].shipmentAlgoId
    ) || this.shipmentC1Cod
      ? promoPrefix(this.shipmentIdList[this.indexElement].shipmentAlgoId)
      : "";
  }

  get isCourierRecommendation() {
    return !!this.shipmentIdList[this.indexElement]?.dataRaw?.ticket_code;
  }

  get isCourierRecommendationRequired() {
    if (this.isCourierRecommendation) {
      return !!this.shipmentIdList[this.indexElement].shipmentGoodsStatus;
    }

    return true;
  }

  get enabledCommodityT1B1B2() {
    const prefix = this.shipmentIdList[this.indexElement].shipmentAlgoId;
    return (
      prefix.startsWith("T1") ||
      prefix.startsWith("B1") ||
      prefix.startsWith("B2")
    );
  }

  payloadCommodityCode(commodityCode: any) {
    let payload;
    if (typeof commodityCode === "object") {
      payload = commodityCode.code;
    } else if (!this.shipmentIdList[this.indexElement].shipmentCommodityCode) {
      payload = this.enabledCommodityT1B1B2 ? "" : "GEN";
    } else {
      payload = this.shipmentIdList[this.indexElement].shipmentCommodityCode;
    }

    return payload;
  }

  get shipmentC1Cod() {
    return (
      this.shipmentIdList[this.indexElement].shipmentAlgoId.startsWith(
        FlagsFeatureShipmentC1.feature_shipment_c1.getValue()
      ) && this.shipmentIdList[this.indexElement].shipmentIsCod
    );
  }

  get manualBooking(): any {
    return BookingController.manualBooking;
  }

  estimateSla = {
    slaMin: 0,
    slaMax: 0,
    transport: ""
  };

  get configDisableDimension() {
    return prefixDisableDimension(this.shipmentId);
  }

  listKGs: any = [
    {
      grossWeight: 0,
      dimension: {
        length: 10,
        width: 10,
        height: 10
      },
      volumeWeight: 0.16,
      convertVolumeWeight: "0,16",
      isDimensionDisabled: this.configDisableDimension
    }
  ];

  get arrGrossWeight() {
    return this.listKGs.map((key: any) => {
      return Number(key.grossWeight.toString().replace(/,/g, "."));
    });
  }

  get totalGrossWeight() {
    let indicator = 0;
    this.arrGrossWeight.forEach((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  get arrVolumeWeight() {
    return this.listKGs.map((key: any) => {
      key.volumeWeight = Number(key.volumeWeight.toString().replace(/,/g, "."));
      return key.volumeWeight;
    });
  }

  get totalVolumeWeight() {
    let indicator = 0;
    this.arrVolumeWeight.forEach((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  get totalChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (
      this.totalGrossWeight.includes(",") ||
      this.totalVolumeWeight.includes(",")
    ) {
      total = this.compareTotalGrossAndVolume(x, y);
    } else {
      total = `${x > y ? x : y}`;
    }
    return total;
  }

  get totalCampaignChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (this.totalGrossWeight || this.totalVolumeWeight) {
      total = totalGrossWeightSikat(
        this.isCampaign,
        x,
        y,
        this.totalGrossWeight,
        this.totalVolumeWeight
      );
    } else {
      total = totalGrossWeightNonSikat(this.isCampaign, x, y);
    }
    return total;
  }

  compareTotalGrossAndVolume(x: number, y: number) {
    let total = 0;
    if (x > y) {
      const split = this.totalGrossWeight.split(",");
      let result = x - Number(split[0]);
      result = Number(Number.parseFloat(result.toString()).toFixed(2));
      if (x < 1) {
        total = 1;
      } else {
        total =
          result >= this.productConfigRuleActive.parameter
            ? Math.ceil(x)
            : Math.floor(x);
      }
    } else {
      const split = this.totalVolumeWeight.split(",");
      let result = y - Number(split[0]);
      result = Number(Number.parseFloat(result.toString()).toFixed(2));
      if (y < 1) {
        total = 1;
      } else {
        total =
          result >= this.productConfigRuleActive.parameter
            ? Math.ceil(y)
            : Math.floor(y);
      }
    }
    return total;
  }

  get isCampaign() {
    // booking MP do not have promo sikat
    if (this.detailShipment.customerName.toLowerCase() !== "customer apps") {
      return false;
    }
    return this.productConfigRuleActive.isCampaign;
  }

  get productConfigRuleActive() {
    const clientId = clientMapperSikat("AP") || clientMapperSikat("AS");
    return this.productConfigurationRuleList.findProductConfigRule(
      this.manualBooking.productName,
      clientId,
      false
    );
  }

  productConfigurationRuleList: ProductConfigurationRuleList = new ProductConfigurationRuleList();

  get convertedArr() {
    return this.listKGs.map((key: any) => ({
      stt_piece_length: key.dimension.length,
      stt_piece_width: key.dimension.width,
      stt_piece_height: key.dimension.height,
      stt_piece_gross_weight: Number(
        key.grossWeight.toString().replace(",", ".")
      ),
      stt_piece_volume_weight: key.volumeWeight
    }));
  }

  lists() {
    if (!this.detailShipment.shipmentGoodDetail.length) {
      this.listKGsDetail();
      return;
    }
    this.listKGs = this.detailShipment.shipmentGoodDetail.map(
      (key: any, index: number) => {
        const volWeightSecondParam = this.convertDefaultWeight(key);
        const secondValue = volWeightSecondParam[1]
          ? "," + volWeightSecondParam[1].slice(0, 3)
          : "";

        return {
          grossWeight: key.shipment_goods_weight,
          dimension: this.dimensionList(key),
          volumeWeight: this.volumenWeightList(key),
          convertVolumeWeight:
            (
              ((key?.shipment_goods_length === 0
                ? 10
                : key?.shipment_goods_length) *
                (key.shipment_goods_width === 0
                  ? 10
                  : key.shipment_goods_width) *
                (key.shipment_goods_height === 0
                  ? 10
                  : key.shipment_goods_height)) /
              this.division
            )
              .toString()
              .replace(".", ",")
              .split(",")[0] + secondValue,
          errorMaxPerPieces: false,
          isDimensionDisabled:
            this.configDisableDimension &&
            key.shipment_goods_weight < MAX_WEIGHT_SHIPMENT
        };
      }
    );
  }

  get division() {
    const volume = volumeWeightForProduct(
      this.shipmentIdList[this.indexElement].shipmentProduct
    );
    return volume;
  }

  convertDefaultWeight(key: any) {
    return (
      ((key?.shipment_goods_length === 0 ? 10 : key?.shipment_goods_length) *
        (key.shipment_goods_width === 0 ? 10 : key.shipment_goods_width) *
        (key.shipment_goods_height === 0 ? 10 : key.shipment_goods_height)) /
      this.division
    )
      .toString()
      .replace(".", ",")
      .split(",");
  }

  listKGsDetail() {
    this.listKGs = [
      new WeightDimension({
        grossWeight: 0,
        dimension: {
          length: 10,
          width: 10,
          height: 10
        },
        isDimensionDisabled: this.configDisableDimension
      })
    ];
  }

  dimensionList(key: any) {
    return {
      length:
        key?.shipment_goods_length === 0 ? 10 : key?.shipment_goods_length,
      width: key.shipment_goods_width === 0 ? 10 : key.shipment_goods_width,
      height: key.shipment_goods_height === 0 ? 10 : key.shipment_goods_height
    };
  }

  volumenWeightList(key: any) {
    return (
      ((key?.shipment_goods_length === 0 ? 10 : key?.shipment_goods_length) *
        (key.shipment_goods_width === 0 ? 10 : key.shipment_goods_width) *
        (key.shipment_goods_height === 0 ? 10 : key.shipment_goods_height)) /
      this.division
    );
  }

  async bookingAndPrintSaveDoc() {
    const payload: any = {
      sttManual: this.payload.sttManual,
      referenceExternal: this.payload.referenceExternal,
      productName: this.payload.productName,
      originCity: this.payload.originCity,
      originDistrict: this.payload.originDistrict,
      destinationCity: this.payload.destinationCity,
      destinationDistrict: this.payload.destinationDistrict,
      phoneSender: this.payload.phoneSender,
      phoneReceiver: this.payload.phoneReceiver,
      receiver:
        typeof this.payload.receiver === "object"
          ? this.payload.receiver.customerName
          : this.payload.receiver,
      sender:
        typeof this.payload.sender === "object"
          ? this.payload.sender.customerName
          : this.payload.sender,
      addressReceiver: this.payload.addressReceiver,
      addressSender: this.payload.addressSender,
      commodityCode:
        typeof this.payload.commodityCode === "object"
          ? this.payload.commodityCode.code
          : this.payload.commodityCode,
      insurance: this.payload.insurance,
      statusGoods: this.payload.statusGoods,
      estimationPrice: this.payload.estimationPrice,
      taxNumber: this.payload.taxNumber,
      isWoodpack: this.payload.isWoodpack,
      isCOD: this.payload.isCOD,
      isDFOD: this.payload.isDFOD,
      codHandling: this.payload.codHandling,
      isSaveSender: this.payload.isSaveSender,
      isSaveReceiver: this.payload.isSaveReceiver,
      sttPieces: this.payload.sttPieces,
      isDO: this.payload.isDO,
      isMixpack: this.payload.isMixpack,
      codAmount: this.payload.codAmount,
      codFee: this.payload.codFee,
      shipmentPackageId: this.payload.shipmentPackageId,
      shipmentId: this.payload.shipmentId,
      sttPiecePerPack: this.payload.sttPiecePerPack,
      sttNextCommodity: this.payload.sttNextCommodity,
      otherShipperTicketCode: this.payload.otherShipperTicketCode,
      postalCodeDestination: this.payload.postalCodeDestination,
      discountFavoritePercentage: this.payload.discountFavoritePercentage,
      sttWeightAttachFiles: this.payload.sttWeightAttachFiles,
      sttRecipientAddressType: this.payload.sttRecipientAddressType
    };
    if (!BookingController.guardBookingSubmit) {
      BookingController.setGuardBookingSubmit(true);
      return ShipmentBookingController.bookingAndPrintSavePdf(payload);
    }
  }

  get STTID() {
    return ShipmentBookingController.sttId;
  }

  async generatePrintData() {
    return BookingController.generatePrinData({
      stt: this.STTID,
      accountType:
        AccountController.accountData.account_type === "partner"
          ? AccountController.accountData.account_type_detail.type
          : AccountController.accountData.account_type
    });
  }

  async bookPrintDoc(type: string) {
    const events: any = {
      shipment: {
        "thermal-pdf": "shipment_booking_print_thermal_pdf_button_clicked",
        basic: "shipment_booking_print_basic_button_clicked"
      },
      addAddress: {
        "thermal-pdf": "booking_tambah_alamat_print_thermal_pdf_button_clicked",
        basic: "booking_tambah_alamat_print_basic_button_clicked"
      }
    };

    dataLayer(events["shipment"][type], {}, [
      "timestamp",
      "userType",
      "userId"
    ]);

    const book = await this.bookingAndPrintSaveDoc();
    if (!book) {
      return;
    }

    if (book) {
      const sttId = ShipmentBookingController.sttId;
      const printedLoad: any = await import(
        "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
      );
      const printType: any = {
        "thermal-pdf": () =>
          printedLoad.default.methods.printBasic(
            sttId,
            0,
            this.$i18n.locale,
            true,
            true
          ),
        basic: () =>
          printedLoad.default.methods.printBasic(
            sttId,
            1,
            this.$i18n.locale,
            true
          )
      };

      printType[type]();
    }
  }

  get openSuccess() {
    return ShipmentBookingController.showModalSuccess;
  }

  get messageSuccess() {
    return ShipmentBookingController.messageSuccess;
  }

  onCloseSuccess() {
    this.$emit("updateStatus", true);
    ShipmentBookingController.setShowModalSuccess(false);
    ShipmentBookingController.setShowModalShipmentBooking(false);
    BookingController.setGuardBookingSubmit(false);
    ShipmentBookingController.setShowModalDetailBookingShipment(false);
    ShipmentBookingController.setBookingAddShipmentDestination(false);
  }

  get shipmentNoRefExt() {
    return this.isCourierRecommendation
      ? this.shipmentIdList[this.indexElement].dataRaw?.ticket_code
      : this.shipmentIdList[this.indexElement].shipmentBookingId.startsWith(
          "TSLP"
        )
      ? this.shipmentIdList[this.indexElement].shipmentBookingId
      : this.shipmentIdList[this.indexElement].STT?.sttNoRefExternal || "";
  }

  get origin() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityCode: `${detail.cityCode}`
    };
  }
}
