/* eslint-disable @typescript-eslint/camelcase */
import { AddPartnerRequestInterface } from "../contracts/PartnerRequest";

export class AddPartnerRequest implements AddPartnerRequestInterface {
  partnerName = "";
  partnerExternalCode = "";
  partnerAddress = "";
  partnerDefaultDistrict = "";
  partnerContractStart = "";
  partnerContractEnd = "";
  partnerContactName = "";
  partnerContactJobTitle = "";
  partnerContactEmail = "";
  partnerContactPhoneNumber = "";
  partnerBeneficiaryBankName = "";
  partnerBeneficiaryAccountNumber = "";
  partnerBeneficiaryAccountName = "";
  partnerBeneficiaryEmail = "";
  partnerBeneficialAccountLabel = "";
  partnerPhoneNumber = "";
  partnerTaxNumber = "";
  partnerParentId = undefined as number | undefined;
  partnerCity = undefined as string[] | undefined;
  partnerLatitude = "";
  partnerLongitude = "";
  partnerPosType = "";
  partnerPosParentId = undefined as number | undefined;
  partnerPosBranchCommission = undefined as number | undefined;
  partnerReferrerCode = "";
  partnerIsCodDelivery = false;
  partnerIsCodBooking = false;
  partnerIsPcu = false;
  partnerPosReverseJourney = 0;
  partnerPosAttachFiles: string[] = [];
  partnerPosSaldoDeposit = 0;

  constructor(fields?: Partial<AddPartnerRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const payload = {
      partner_name: this.partnerName,
      partner_external_code: this.partnerExternalCode,
      partner_address: this.partnerAddress,
      partner_default_district: this.partnerDefaultDistrict,
      partner_contract_start: this.partnerContractStart,
      partner_contract_end: this.partnerContractEnd,
      partner_contact_name: this.partnerContactName,
      partner_contact_job_title: this.partnerContactJobTitle,
      partner_contact_email: this.partnerContactEmail,
      partner_contact_phone_number: this.partnerContactPhoneNumber,
      partner_beneficiary_bank_name: this.partnerBeneficiaryBankName,
      partner_beneficiary_account_number: this.partnerBeneficiaryAccountNumber,
      partner_beneficiary_account_name: this.partnerBeneficiaryAccountName,
      partner_beneficiary_email: this.partnerBeneficiaryEmail,
      partner_beneficial_account_label: this.partnerBeneficialAccountLabel,
      partner_pos_reverse_journey: this.partnerPosReverseJourney
    };
    // console
    if (!this.partnerParentId) {
      return JSON.stringify({
        ...payload,
        partner_phone_number: this.partnerPhoneNumber,
        partner_tax_number: this.partnerTaxNumber,
        partner_city: this.partnerCity,
        partner_latitude: this.partnerLatitude,
        partner_longitude: this.partnerLongitude
      });
    }
    // subconsole / pos
    return JSON.stringify({
      ...payload,
      partner_parent_id: this.partnerParentId,
      partner_latitude: this.partnerLatitude,
      partner_longitude: this.partnerLongitude,
      partner_referrer_code: this.partnerReferrerCode,
      partner_is_cod_delivery: this.partnerIsCodDelivery,
      partner_is_cod_booking: this.partnerIsCodBooking,
      partner_is_pcu: this.partnerIsPcu,
      partner_pos_type: this.partnerPosType,
      partner_pos_parent_id: this.partnerPosParentId,
      partner_pos_branch_commission: this.partnerPosBranchCommission,
      partner_pos_attach_files: this.partnerPosAttachFiles,
      partner_pos_saldo_deposit: this.partnerPosSaldoDeposit
    });
  }
}
