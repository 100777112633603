import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center" }
const _hoisted_2 = {
  class: "absolute bottom-0 left-0 top-12 w-full rounded-b-md text-center",
  style: {"margin-top":"80px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton = _resolveComponent("skeleton")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_skeleton, {
        width: "6.2rem",
        height: "1.2rem",
        class: "mt-1"
      }),
      _createVNode(_component_skeleton, {
        width: "5.5rem",
        height: "1.8rem",
        class: "mt-1"
      }),
      _createVNode(_component_skeleton, {
        width: "5rem",
        height: "0.8rem",
        class: "mt-3"
      }),
      _createVNode(_component_skeleton, {
        width: "3rem",
        height: "0.8rem",
        class: "mt-1"
      })
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_skeleton, {
        width: "100%",
        height: "100%"
      })
    ])
  ], 64))
}