import { createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-12px font-normal" }
const _hoisted_2 = { class: "text-14px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock("div", {
    class: `border border-dashed ${_ctx.content?.status 
      ? 'border-green-lp-400 bg-gradient-to-b from from-primary-green to-secondary-green' 
      : 'border-gray-lp-300 bg-gradient-to-b from from-primary-gray to-secondary-gray'} 
    rounded-lg py-2 px-4 flex items-center gap-x-4`
  }, [
    _createVNode("img", {
      src: require(`@/app/ui/assets/svg/${_ctx.content?.status ? 'box-money' : 'box-money-inactive'}.svg`),
      alt: "info"
    }, null, 8, ["src"]),
    _createVNode("div", _hoisted_1, [
      _withDirectives(_createVNode("p", _hoisted_2, null, 512), [
        [_directive_sanitize_html, _ctx.content?.msg]
      ])
    ])
  ], 2))
}