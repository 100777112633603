
import { Vue, prop, Options } from "vue-class-component";
import { PosterProduct } from "@/domain/entities/Booking";
import HighlightLoading from "../loading/highlight-loading.vue";

class Props {
  product = prop<PosterProduct>({
    default: new PosterProduct(),
    type: PosterProduct,
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean,
  });
  firstRender = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean,
  });
  version = prop<string>({
    default: "v1",
    type: String
  })
  isEmbargo = prop<boolean>({
    default: false,
    type:Boolean
  });
  hideKgs = prop<boolean>({
    default: false,
    type: Boolean
  })
}

@Options({
  emits: ["setActive"],
  components: {
    HighlightLoading,
  },
})
export default class HighLightProducts extends Vue.with(Props) {
  setActive() {
    this.$emit('setActive');
  }
}
