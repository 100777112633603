import { container } from "tsyringe";
import ApiService from "@/app/infrastructures/services/ApiService";
import { PromoConfigurationRepository } from "@/app/infrastructures/repositories/api/PromoConfigurationRepository";
import { PromoConfigurationMapper } from "@/data/persistences/mappers/PromoConfigurationMapper";
import { PromoConfigurationEndpoint } from "@/app/infrastructures/endpoints/horde/PromoConfigurationEndpoint";
import { PromoConfigurationPresenter } from "@/app/ui/presenters/PromoConfigurationPresenter";

export class PromoConfigurationComponent {
  public static init() {
    container.register<PromoConfigurationRepository>(
      PromoConfigurationRepository,
      {
        useFactory: d => {
          return new PromoConfigurationRepository(
            d.resolve(ApiService),
            d.resolve(PromoConfigurationMapper),
            d.resolve(PromoConfigurationEndpoint)
          );
        }
      }
    );
    container.register<PromoConfigurationMapper>(PromoConfigurationMapper, {
      useClass: PromoConfigurationMapper
    });
    container.register<PromoConfigurationPresenter>(
      PromoConfigurationPresenter,
      {
        useFactory: d => {
          return new PromoConfigurationPresenter(
            d.resolve(PromoConfigurationRepository)
          );
        }
      }
    );
  }
}
