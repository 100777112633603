
import { Vue, Options, prop } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { ValidateInputPhoneNumber } from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";
import { formatInputNumberOnlyV2 } from "@/app/infrastructures/misc/Utils";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import { CustomerManagementController } from "@/app/ui/controllers/CustomerManagementController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import SelectPhoneCountryV2 from "@/app/ui/views/shipment/booking/create-booking-v2/components/select-phone-country-v2.vue";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";

class Props {
  isShown = prop<boolean>({
    default: false,
    type: Boolean
  });

  customer = prop<any>({
    required: true,
    default: {
      customerAddress: "",
      customerId: 0,
      customerName: "",
      customerPhoneNumber: "",
      customerDistrictId: 0,
      customerDistrictName: "",
      customerZipCode: "",
      customerAddressType: ""
    }
  });

  type = prop<string>({
    default: "sender",
    type: String
  });
}

@Options({
  emits: ["update:isShown", "onEditSuccess"],
  components: {
    SelectPhoneCountryV2,
    codePhoneCountry
  }
})
export default class EditCustomerForm extends Vue.with(Props) {
  mounted() {
    this.form = this.customer;
    if (this.form.customerDistrictId != 0) {
      this.fetchDistrictId(this.form.customerDistrictId);
    }
    if (this.form.customerZipCode != "") {
      this.zipCode = {
        id: Number(this.form.customerZipCode),
        name: this.form.customerZipCode
      };
    }

    this.form.customerPhoneCode = codePhoneCountry(
      this.customer.customerPhoneNumber
    ).code;

    setTimeout(() => {
      this.isChanged = false;
    }, 100);
  }

  isChanged = false;
  props: any = this.$props;
  // State Value
  archiveType = "customer-management";
  activityName = "";
  versionName = "";

  // Handle File
  archiveFile: any = "";
  isClearFile = false;

  statusReset = false;

  get phoneNumberValue() {
    return codePhoneCountry(this.customer.customerPhoneNumber).number;
  }

  form = {
    customerId: 0,
    customerName: "",
    customerPhoneNumber: "",
    customerAddress: "",
    customerDistrictId: 0,
    customerDistrictName: "",
    customerZipCode: "",
    customerAddressType: "",
    customerPhoneCode: ""
  };

  phoneNumberError = {
    success: false,
    error: false,
    message: ""
  };

  error = {
    customerName: false,
    customerAddress: false
  };

  errorCaption = {
    customerName: "Nama tidak valid, mohon ulangi kembali",
    customerPhoneNumber: "Nomor telepon tidak valid, mohon ulangi kembali",
    customerAddress: "Alamat tidak valid, mohon ulangi kembali"
  };

  get isSaveDisabled() {
    return (
      Object.values(this.error).some(value => value) ||
      this.phoneNumberError.error ||
      !this.isChanged
    );
  }

  showErrorMessage() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "new-badge-failed",
        title: `Aktivitas Upload Gagal`,
        message: `Aktivitas “${this.archiveFile?.name}” gagal terupload`,
        textSuccess: "OK",
        onSubmit: () => MainAppController.closeMessageModal()
      })
    );
  }

  resetForm() {
    this.form = {
      customerId: 0,
      customerName: "",
      customerPhoneNumber: "",
      customerAddress: "",
      customerDistrictId: 0,
      customerDistrictName: "",
      customerZipCode: "",
      customerAddressType: "",
      customerPhoneCode: ""
    };
  }

  onCloseWithReset() {
    this.resetForm();
    this.$emit("update:isShown", false);
  }

  validatePhone(value: string) {
    this.phoneNumberError = ValidateInputPhoneNumber(value);
  }

  formatNumber(value: string) {
    return formatInputNumberOnlyV2(value);
  }

  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  validateName(value: string) {
    this.isChanged = true;
    const min3Chars = value.length < 3;
    const maxChars = value.length > 100;
    const empty = value === "";
    const senderError = min3Chars || empty || maxChars;
    this.error.customerName = senderError;
  }

  formatName(value: string) {
    if (value.length > 100) {
      return value.slice(0, 100);
    }
    return value;
  }

  validateAddress(value: string) {
    this.isChanged = true;
    const min3Chars = value.length < 3;
    const empty = value === "";
    const senderError = min3Chars || empty;
    this.error.customerAddress = senderError;
  }

  requestEditCustomer() {
    this.form.customerDistrictId = this.destinationCity.id;
    this.form.customerDistrictName = this.destinationCity.name;
    this.form.customerZipCode = this.zipCode.name;
    CustomerManagementController.editCustomerOnBooking({
      id: this.form.customerId,
      name: this.form.customerName,
      phone: this.form.customerPhoneNumber,
      address: this.form.customerAddress,
      districtId: this.form.customerDistrictId,
      zipCode: this.form.customerZipCode,
      addressType: this.form.customerAddressType
    }).then(() => {
      const customerName = this.form.customerName;
      this.openSnackbar(customerName);
      this.onEditSuccess();
      this.onCloseWithReset();
    });
  }

  openSnackbar(name: string) {
    MainAppController.setSnackbarMessage(
      `Perubahaan data pelanggan "${name}" berhasil!`
    );
    MainAppController.setSnackbar(true);
  }

  onEditSuccess() {
    this.$emit("onEditSuccess", { ...this.form });
  }

  // city
  destinationCity: any = "";
  destinationCityList: Array<any> = [];
  onFocusdestinationCity() {
    if (!this.destinationCityList.length || !this.destinationCity) {
      LocationController.setLoadingDistrict(true);
      this.fetchdestinationCity("");
    }
  }
  fetchdestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  get isLoadingdestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.originZipCode}`
      }));
  }

  async fetchDistrictId(search: number) {
    if (search) {
      const responseDistrictData = await LocationController.getDetailDistrict({
        id: search,
        notSaveDetail: true
      });
      const disctrictName =
        responseDistrictData.name + "," + responseDistrictData.cityName;
      this.destinationCity =
        responseDistrictData.name == null
          ? ""
          : { id: search, name: disctrictName };
    } else {
      this.destinationCity = "";
    }
  };

  onChangeDestinationCity() {
    this.isChanged = true;
    if (this.destinationCity.code?.includes(",")) {
      const arrayZipcode: string[] = this.destinationCity.code.split(",")
      this.zipCodeList = arrayZipcode.map((e: any) => ({
        code: e,
        name: e
      }));
    } else {
      this.zipCode = this.destinationCity.code
        ? { code: this.destinationCity.code, name: this.destinationCity.code }
        : this.zipCode;
      this.zipCodeList = [
        { code: this.destinationCity.code, name: this.destinationCity.code }
      ];
    }
  }

  //zipcode
  zipCode: any = "";
  zipCodeList: Array<any> = [];

  fetchZipCode = debounce(async (search: string) => {
    if (search) {
      this.zipCode = {
        code: search,
        name: search,
        id: search,
        key: search
      }
    }
    if (!this.destinationCity.code) {
      const response = await LocationController.getZipCodeList({
        search: search,
        page: 1,
        limit: 10
      });
      this.zipCodeList = this.filterZipCode(response);
    }
  }, 250);

  filterZipCode(response: any) {
    return response.zipCodeData.map((e: any) => ({
      code: e.originZipCode,
      name: e.originZipCode,
      id: e.id,
      key: `${e.name}, ${e.cityName}`
    }));
  }

  get isLoadingZipCode() {
    return LocationController.isLoadingZipCode;
  }

  onFocusZipCode() {
    if (!this.zipCodeList.length || !this.zipCode) {
      this.fetchZipCode("");
    }
  }

  onChangeZipCode() {
    this.isChanged = true;
    if (!this.destinationCity.code) {
      this.destinationCity = this.zipCode.name
        ? { id: this.zipCode.id, name: this.zipCode.key }
        : "";
    }
  }

  // address type types
  get listAddressType() {
    const defaultChoices: any = [
      {
        name: this.$t("bookingShipment.column.office"),
        value: "kantor"
      },
      {
        name: this.$t("bookingShipment.column.home"),
        value: "rumah"
      }
    ];

    return defaultChoices;
  }

  onSelectAddressType(name: string, value: string) {
    this.isChanged = true;
    this.form.customerAddressType = value;
  }

  setPhoneNumber(val: string) {
    this.isChanged = true;
    this.form.customerPhoneNumber = this.form.customerPhoneCode.slice(1) + val;
    this.validatePhone(this.form.customerPhoneNumber);
  }

  setPhoneCode(val: string) {
    this.isChanged = true;
    this.form.customerPhoneCode = val;
  }
}
