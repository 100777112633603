
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { ListPickupController } from "@/app/ui/controllers/ListPickupController";
import { ApiStateData } from "@/domain/entities/Api";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  ListPickup,
  RequestListPickupList,
  ListPickupList
} from "@/domain/entities/ListPickup";
import { Options, Vue } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import { BookingController } from "@/app/ui/controllers/BookingController";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import debounce from "lodash/debounce";
import { OptionsClass } from "@/domain/entities/MainApp";
import router from "@/app/ui/router";
import DataWrapper from "@/app/ui/components/data-wrapper/index.vue";
import { CourierManagementController } from "@/app/ui/controllers/CourierManagementController";
import { ApiRequestCourierVehicleList } from "@/data/payload/api/CourierManagementApiRequest";

const PickupTypeTitle: { [x: string]: string } = {
  scheduled: "Pick Up terjadwal",
  instant: "Pick Up langsung"
};

@Options({
  components: {
    SelectSearchV2,
    Checkbox,
  },
})
export default class PickupList extends Vue {
  courierManagementController = CourierManagementController;

  isFirstLanding = true;

  mounted() {
    this.filterProductsDelivery("");
    this.getListPickupList();
    this.fetchVehicles("");
  }

  apiListData = new ApiStateData();
  listData: ListPickupList  = new ListPickupList();

  async getListPickupList() {
    this.apiListData.loading = true;
    try {
      const resp = await ListPickupController.getListPickupList(
        new RequestListPickupList({
          page: this.listData.pagination.page,
          pickupSchedule: this.filter.lsPickupSchedule,
          transportType: this.filter.lsTransportTypeId,
          serviceCode: this.filter.lsServiceCodes,
          lastStatus: this.filter.lsLastStatus,
          shipmentId: this.filter.lsShipmentId,
          sortBy: this.filter.sortBy,
          isTotalData: true,
        })
      );
      this.listData = new ListPickupList({
        pagination: new PaginationV2({
          page: resp.pagination.page,
          limit: resp.pagination.limit,
          totalData: resp.pagination.totalData
        }),
        data: resp.data,
      });
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
    }
  }

fetchVehicles(search = "") {
  this.courierManagementController
    .getListVehicle(new ApiRequestCourierVehicleList({ search }))
    .then(res => {
      this.transportTypeOptions = res.data.map(
        (item: { id: number; vehicle_name: string }) => {
          return new OptionsClass({
            name: item.vehicle_name,
            value: String(item.vehicle_name)
          });
        }
      );
    });
}

  get getTitle() {
    return this.$route.meta.title;
  }

  onClickRow(item: ListPickup) {
    router.push(`/pickup/list-pickup/${item.listPickupShipmentId}`);
  }

  filter = {
    lsShipmentId: "" as any,
    lsPickupSchedule: "" as any,
    lsTransportTypeId: "" as any,
    lsServiceCodes: "" as any,
    lsLastStatus: "" as any,
    sortBy: "" as any,
  };

  get columns() {
    return [
      {
        name: "No",
        styleHead: "w-12 text-left",
        render: (_: any, index: any) => {
          return `<div class="text-left">${
            index +
            1 +
            this.listData.pagination.limit * (this.listData.pagination.page - 1)
          }</div>`;
        },
      },
      {
        name: "Shipment ID",
        styleHead: "w-56 text-left",
        render: (item: ListPickup) => {
          return `<div class="text-left">${item.listPickupShipmentId}</div>`;
        },
      },
      {
        name: "Referensi klien",
        styleHead: "w-44 text-left whitespace-nowrap",
        render: (item: ListPickup) => {
          return `<div class="text-left">${item.listPickupClientRef}</div>`;
        },
      },
      {
        name: "Jadwal Pick Up",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: ListPickup) => {
          return `<div class="overflow-ellipsis text-left">${item.formatPickupSchedule || "-" }</div>`;
        },
      },
      {
        name: "Tipe armada",
        styleHead: "w-44 text-left whitespace-nowrap",
        render: (item: ListPickup) => {
          return `<div class="overflow-ellipsis text-left">${item.listPickupTransportType || "-"}</div>`;
        },
      },
      {
        name: "Estimasi jumlah koli",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: ListPickup) => {
          return `<div class="flex flex-row">
          <p class="overflow-ellipsis text-left">${item.listPickupEstTotalKoli}</p> ${item.listPickupActualTotalKoli > 0 ? ' <p class="overflow-ellipsis text-left text-gray-lp-600">&nbsp;(Aktual '+item.listPickupActualTotalKoli +' Koli)</p>': ""}
          </div>`;
        },
      },
      {
        name: "Tipe Pick Up",
        styleHead: "w-72 text-left whitespace-nowrap",
        render: (item: ListPickup) => {
          return `<div class="overflow-ellipsis text-left">${PickupTypeTitle[item.listPickupPickupType.toLowerCase()]}</div>`;
        },
      },
      {
        name: "Jenis pengiriman",
        styleHead: "w-80 text-left whitespace-nowrap",
        render: (item: ListPickup) => {
          const listPickupService = item.listPickupServiceCodes.replace(/,/g, ", ");
          return `<div class="flex flex-row">
            <p class="overflow-ellipsis text-left">${this.ellipsisString(listPickupService, item.isEllipsis)}</p>
            ${item.isEllipsis && listPickupService.length > 20 ? '<p class="text-red-lp-100 text-decoration-line: underline">(Lihat semua)</p>' : ""} 
          </div>`;
        },
        onClickValue: (item: ListPickup, index: number) => {
          this.listData.data[index].isEllipsis = !this.listData.data[index].isEllipsis
        }
      },
      {
        name: "Status",
        styleHead: "w-48 text-left",
        render: (item: ListPickup) =>
          ChipsStyles({ status: item.listPickupLatestStatus }),
      },
    ];
  }

  get isEmpty(): boolean {
    return !this.listData.data.length && this.sumFilter === 0 && this.isFirstLanding;
    // add no filter
  }

  get sumFilter(): number {
    return (
      (this.filter.lsTransportTypeId ? 1 : 0) +
      (this.filter.lsServiceCodes?.length ? 1 : 0) +
      (this.filter.lsLastStatus.length ? 1 : 0)
    );
  }

  ellipsisString(val: string, isElipsize: boolean) {
    return isElipsize && val.length > 20 ? ellipsisString(val, 20): val ;
  }

  onSearch(value: string) {
    Object.assign(this.filter, {
      lsShipmentId: value,
    });
    this.onChangeAdvancedFilter();
    
  }

  get searchValue() {
    return this.filter.lsShipmentId;
  }

  clearSearch() {
    Object.assign(this.filter, {
      lsShipmentId: "",
    });
    this.onChangeAdvancedFilter();
  }

  onChangeAdvancedFilter = debounce(() => {
    this.listData.pagination.page = 1;
    this.getListPickupList();
    this.isFirstLanding = false;
  }, 500);

  //product
  get productOptions() {
    const listData = BookingController.productsDelivery.data.map((e: any) => ({
      id: e.id,
      name: e.name,
      code: e.name,
      status: e.status,
    }));

    const idx = listData.findIndex((res) => res.name.toUpperCase() === "JUMBOPACKH2H");
    listData.splice(idx, 1);

    return listData;
  }

  filterProductsDelivery = debounce((search: string) => {
    BookingController.getProductsDelivery({
      search,
      page: 1,
      limit: 99,
      status: "active",
    });
  }, 250);

  onChangeProduct() {
    this.onChangeAdvancedFilter();
  }

  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  
  async onResetAdvancedFilter() {
    this.apiListData.loading = true;
    Object.assign(this.filter, {
      lsShipmentId: "",
      lsPickupSchedule: "",
      lsTransportTypeId: "",
      lsServiceCodes: "",
      lsLastStatus: "",
      sortBy: "" ,
    });

    this.listData.pagination.page = 1;
    await this.getListPickupList();
  }

  // vehicle
  transportTypeOptions = [
    new OptionsClass()
  ];

  onSelectVehicle(data: OptionsClass) {
    Object.assign(this.filter, {
      lsTransportTypeId: data.value,
    });
    this.onChangeAdvancedFilter();
  }

  // status pickup schedule
  get statusPickupSchedule(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "Mencari Kurir",
        value: "CRRNFD",
      }),
      new OptionsClass({
        name: "Kurir Ditugaskan",
        value: "CRROTW",
      }),
      new OptionsClass({
        name: "Paket di Pick Up",
        value: "CRRPIC",
      }),
      new OptionsClass({
        name: "Selesai",
        value: "CRRDON",
      }),
      new OptionsClass({
        name: "Dibatalkan Kurir",
        value: "CRRCNC",
      }),
      new OptionsClass({
        name: "Dibatalkan Klien",
        value: "CSTCNC",
      }),
      new OptionsClass({
        name: "Dibatalkan Admin",
        value: "ADMCNC",
      }),
    ];
  }

  selectStatus(data: OptionsClass) {
    Object.assign(this.filter, { lsLastStatus: data.value });
    this.onChangeAdvancedFilter();
  }

  get maxStartDatePeriode() {
    return new Date();
  }


  onChangePickupSchedule(event: any) {
    Object.assign(this.filter, {
      lsPickupSchedule: event.periodeStart ? new Date(event.periodeStart).toLocaleDateString("fr-CA") : "",
      });
      this.onChangeAdvancedFilter();
  }

  //sort by

  sortByTitle = "";
  sortBySelect = false;
  sortByData = [
    { name: "Urutkan", value: "" },
    { name: "Jadwal Pick Up terdekat", value: "nearest" },
    { name: "Jadwal Pick Up terjauh", value: "farthest" },
    { name: "Jumlah tonase terbanyak", value: "max_pickup" },
    { name: "Jumlah tonase terkecil", value: "min_pickup" },
    { name: "Jumlah koli terbanyak", value: "max_koli" },
    { name: "Jumlah koli terkecil", value: "min_koli" }
  ];

  onOpenSortBy() {
    this.sortBySelect = true;
  }

  onCloseSortBy() {
    this.sortBySelect = false;
  }

  onSelectSortBy(name: string, value: string) {
    Object.assign(this.filter, {
      sortBy: value || "",
    });
    this.onChangeAdvancedFilter();
    this.sortByTitle = name;
  }

};
