
import { BookingController } from "@/app/ui/controllers/BookingController";
import { Options, Vue, prop } from "vue-class-component";
import MoreLoading from "../loading/more-loading.vue";
class Props {
  loading = prop<boolean>({
    default: false,
    type: Boolean,
  });
  firstRender = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isJumboPack = prop<boolean>({
    default: false,
    type: Boolean
  })
}

@Options({
  emits: ["expand"],
  components: {
    MoreLoading,
  },
})
export default class MoreProducts extends Vue.with(Props) {
  updated() {
    if(this.isJumboPack && this.isPartnerPcu && !this.productExpandIcons.includes("bigpack"))
      this.productExpandIcons.unshift("bigpack");
  }
  productExpandIcons = ["otopack-150", "otopack-250", "interpack"];
  expandProduct() {
    this.$emit("expand");
  }

  get isPartnerPcu() {
    return BookingController.dataProfile.isPartnerPcu || BookingController.dataProfile.isInternalAccount;
  }
}
