
import { Options, Vue } from "vue-class-component";
import { PaymentProps } from "@/app/ui/components/dialog-payment/payment-props";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import DialogPaymentLayout from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayout.vue";
import DialogPaymentLayoutHeader from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutHeader.vue";
import DialogPaymentLayoutImage from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutImage.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { PaymentMethod } from "@/domain/entities/Payment";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

@Options({
  emits: ["onSubmit"],
  components: {
    DialogPaymentLayoutImage,
    DialogPaymentLayoutHeader,
    DialogPaymentLayout,
    Skeleton
  }
})
export default class DialogChoosePayment extends Vue.with(PaymentProps) {
  mounted() {
    PaymentController.getPaymentMethodsAvailable();
  }

  payWithCash() {
    dataLayer(`${this.billType}_bayar_tunai_clicked`, {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(
      new PaymentMethod({
        name: "tunai",
        title: "Cash",
        method: "cash"
      })
    );
  }
  payWithQr(paymentMethod: PaymentMethod) {
    if (paymentMethod.status === "inactive") return;
    dataLayer(
      `${this.billType}_bayar_${paymentMethod.name.toLowerCase()}_clicked`,
      {},
      ["timestamp", "userType", "userId"]
    );
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(paymentMethod);
  }
  get isLoadPaymentMethods() {
    return PaymentController.isLoadPaymentMethods;
  }
  get paymentMethods() {
    return PaymentController.paymentMethods?.map(
      (paymentMethod: PaymentMethod) => {
        if (
          this.amountToBePaid > paymentMethod.maxAmount &&
          paymentMethod.status === "active"
        ) {
          this.paymentMethodsAmountExceed.push(paymentMethod);
        }
        return {
          ...paymentMethod,
          status:
            this.amountToBePaid > paymentMethod.maxAmount &&
            paymentMethod.status === "active"
              ? "inactive"
              : paymentMethod.status
        };
      }
    );
  }
  get sttNumber() {
    return PaymentController.sttNumber;
  }
  get billAmount() {
    return PaymentController.formattedBillAmountAfterDiscount;
  }
  get amountToBePaid() {
    return PaymentController.billAmount;
  }
  stylePayment(paymentMethod: PaymentMethod) {
    return {
      style: {
        background:
          paymentMethod.logo === "logo-linkaja_qris" &&
          paymentMethod.status === "inactive"
            ? "#767676"
            : paymentMethod.color,
        cursor: paymentMethod.status === "inactive" ? "not-allowed" : "pointer"
      },
      status: paymentMethod.status
    };
  }
  paymentMethodsAmountExceed: PaymentMethod[] = [];
  get highestMaxAmountPaymentAmountExceed() {
    return formatPrice(
      Math.max(
        ...this.paymentMethodsAmountExceed?.map(
          (e: PaymentMethod) => e.maxAmount
        )
      )
    );
  }
  get titlePaymentAmountExceed() {
    return this.paymentMethodsAmountExceed
      ?.map((e: PaymentMethod) => e.title)
      .join(", ");
  }
  get captionPaymentAmountExceed() {
    if (!this.paymentMethodsAmountExceed.length) return "";
    return `Catatan : Pembayaran hanya bisa dilakukan secara tunai dikarenakan limit pembayaran maksimal dengan metode pembayaran ${this.titlePaymentAmountExceed} adalah <strong>${this.highestMaxAmountPaymentAmountExceed}</strong>`;
  }
}
