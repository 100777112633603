import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton = _resolveComponent("skeleton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_skeleton, {
      width: "5.5rem",
      height: "1.8rem",
      class: "mt-3"
    }),
    _createVNode(_component_skeleton, {
      width: "5.5rem",
      height: "1.8rem",
      class: "mt-1"
    }),
    _createVNode(_component_skeleton, {
      width: "5.5rem",
      height: "1.8rem",
      class: "mt-1"
    }),
    _createVNode(_component_skeleton, {
      width: "8rem",
      height: "1rem",
      class: "mt-5"
    })
  ]))
}