
import { Vue, Options } from "vue-class-component";
import DialogChoosePayment from "@/app/ui/components/dialog-payment/dialog-choose-payment.vue";
import PaymentCash from "@/app/ui/components/dialog-payment/payment-cash/index.vue";
import PaymentQr from "@/app/ui/components/dialog-payment/payment-qr/index.vue";
import {
  PAYMENT_METHOD,
  PaymentProps
} from "@/app/ui/components/dialog-payment/payment-props";
import { PaymentController } from "@/app/ui/controllers/PaymentController";

@Options({
  emits: ["successPayment"],
  components: { DialogChoosePayment, PaymentCash, PaymentQr }
})
export default class DialogPayment extends Vue.with(PaymentProps) {
  get paymentMethodActive() {
    if (!PaymentController.paymentMethod?.name) return "";
    return PaymentController.paymentMethod.method;
  }
  get dialogPayment() {
    return PaymentController.dialogPayment;
  }
  get showDialogPaymentQr() {
    return (
      this.dialogPayment &&
      this.paymentMethodActive === PAYMENT_METHOD.QR_INDONESIAN_STANDARD
    );
  }
  get showDialogPaymentCash() {
    return (
      this.dialogPayment && this.paymentMethodActive === PAYMENT_METHOD.CASH
    );
  }
  get showSuccessBookingDialogPayment() {
    return this.dialogPayment && !this.paymentMethodActive;
  }
}
