/* eslint-disable @typescript-eslint/camelcase */
import { EditHeavyWeightSurchargeRequestInterface } from "@/data/payload/contracts/HeavyWeightSurcharge";

export class EditHeavyWeightSurchargeApiRequest
  implements EditHeavyWeightSurchargeRequestInterface {
  configurable_price_id: number;
  configurable_price_type: string;
  configurable_price_description: string;
  configurable_price_status: string;
  configurable_heavy_weight_surcharge: any;

  constructor(
    configurable_price_id: number,
    configurable_price_description: string,
    configurable_price_status: string,
    configurable_price_commodity_exclude: Array<any>,
    configurable_price_additional_costs: Array<any>,
    configurable_price_product_exclude: Array<string>,
    configurable_price_product_include: Array<string>,
    heavy_weight_surcharge_type: string,
    heavy_weigth_surcharge_description: string,
    heavy_weight_surcharge_id: number,
    heavy_weight_surcharge_status: string,
  ) {
    this.configurable_price_id = configurable_price_id;
    this.configurable_price_type = "heavy_weight_surcharge";
    this.configurable_price_description = configurable_price_description;
    this.configurable_price_status = configurable_price_status;
    this.configurable_heavy_weight_surcharge = {
      heavy_weight_surcharge_commodity_exclude: {
        commodities: configurable_price_commodity_exclude
      },
      heavy_weight_surcharge_additional_cost: {
        additional_costs: configurable_price_additional_costs
      },
      heavy_weight_surcharge_product_type_exclude: {
        product_types: configurable_price_product_exclude
      },
      heavy_weight_surcharge_product_type_include: {
        product_types: configurable_price_product_include
      },
      heavy_weight_surcharge_type: heavy_weight_surcharge_type,
      heavy_weight_surcharge_description: heavy_weigth_surcharge_description,
      heavy_weight_surcharge_id: heavy_weight_surcharge_id,
      heavy_weight_surcharge_status: heavy_weight_surcharge_status
    };
  }

  public toJSON(): string {
    return JSON.stringify({
      configurable_price_id: this.configurable_price_id,
      configurable_price_type: this.configurable_price_type,
      configurable_price_description: this.configurable_price_description,
      configurable_price_status: this.configurable_price_status,
      configurable_heavy_weight_surcharge: this
        .configurable_heavy_weight_surcharge
    });
  }
}
