
import CreateBookingV1 from "../create-booking/add.vue";
import CreateBookingV2 from "../create-booking-v2/add.vue";
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
@Options({
  components: {
    CreateBookingV1,
    CreateBookingV2,
  },
})
export default class ConfigCreateBooking extends Vue {
  get isBookingVersion() {
    return FlagsPermissionBooking.permission_booking_version.getValue();
  }

  get isAbleBookingManual() {
    return FlagsPermissionBooking.permission_booking_create_manual.isEnabled();
  }

  get isAbleBookingCorporate() {
    return FlagsPermissionBooking.permission_booking_create_for_corporate.isEnabled();
  }

  get configBookingVersion() {
    let result = "";
    switch (this.isBookingVersion.toLowerCase()) {
      case "v1":
        result = "CreateBookingV1";
        break;
      case "v2":
        result = "CreateBookingV2";
        break;
      default:
        result = "CreateBookingV1";
        break;
    }
    if (this.isAbleBookingManual || this.isAbleBookingCorporate) {
      return result;
    }
    return "";
  }
}
