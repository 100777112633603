import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-12 mb-6 z-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_tariff = _resolveComponent("check-tariff")!
  const _component_select_payment_methods = _resolveComponent("select-payment-methods")!
  const _component_select_receipt_language = _resolveComponent("select-receipt-language")!
  const _component_Book = _resolveComponent("Book")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_check_tariff, {
      isBooking: _ctx.isBooking,
      isDisabled: _ctx.isDisabled,
      bookingType: _ctx.bookingType,
      payload: _ctx.payload,
      accountRefAndCommodity: _ctx.accountRefAndCommodity,
      formType: _ctx.formType,
      discountData: _ctx.discount,
      isCodRetail: _ctx.isCodRetail,
      onUpdateStatusErrorEmbargo: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateStatusErrorEmbargo')))
    }, null, 8, ["isBooking", "isDisabled", "bookingType", "payload", "accountRefAndCommodity", "formType", "discountData", "isCodRetail"]),
    (_ctx.isCreateBookingRetail)
      ? (_openBlock(), _createBlock(_component_select_payment_methods, {
          key: 0,
          isDiscount: _ctx.discount.status
        }, null, 8, ["isDiscount"]))
      : _createCommentVNode("", true),
    (_ctx.isProductInterpack && _ctx.accountIsNonForeign)
      ? (_openBlock(), _createBlock(_component_select_receipt_language, {
          key: 1,
          customStyleList: _ctx.customStyleLanguage
        }, null, 8, ["customStyleList"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Book, {
      isDisabled: _ctx.isDisabled,
      bookingType: _ctx.bookingType,
      payload: _ctx.payload,
      isDiscountActive: _ctx.discount.status,
      onSetDiscount: _ctx.setDiscount
    }, null, 8, ["isDisabled", "bookingType", "payload", "isDiscountActive", "onSetDiscount"])
  ]))
}