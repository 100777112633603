import { toDisplayString as _toDisplayString, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "md:flex md:flex-row" }
const _hoisted_2 = {
  key: 0,
  class: " text-red-lp-300 text-16px bold mx-1"
}
const _hoisted_3 = { class: "text-12px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("div", { class: "md:text-16px text-10px pr-1 font-bold text-red-lp-100" }, _toDisplayString(_ctx.currency(_ctx.tariffAfterDiscount)), 513), [
      [_vShow, _ctx.showDiscountNominal]
    ]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listDiscount, (promo, index) => {
      return (_openBlock(), _createBlock("div", {
        key: index,
        class: "flex items-center"
      }, [
        _createVNode("div", {
          class: "\n          flex flex-row\n          bg-green-lp-700\n          text-white text-10px\n          px-1\n          rounded-md\n          mb-1 cursor-pointer\n        ",
          onMouseenter: ($event: any) => (_ctx.onOpenTooltipPromo($event, index)),
          onMouseleave: ($event: any) => (_ctx.onOpenTooltipPromo($event, index))
        }, [
          _createVNode("p", null, _toDisplayString(promo.promoConfigurationDiscountNominal) + "% Off", 1),
          _createVNode(_component_Icons, {
            name: "info",
            class: "w-3 ml-1"
          })
        ], 40, ["onMouseenter", "onMouseleave"]),
        (index !== _ctx.listDiscount.length - 1 && _ctx.listDiscount.length > 1)
          ? (_openBlock(), _createBlock("span", _hoisted_2, "+"))
          : _createCommentVNode("", true),
        _createVNode(_component_OverlayPanel, {
          ref: `infoPromo${index}`,
          appendTo: "body",
          class: "text-center",
          style: {"width":"200px"}
        }, {
          default: _withCtx(() => [
            _createVNode("p", _hoisted_3, _toDisplayString(promo.promoConfigurationName), 1)
          ]),
          _: 2
        }, 1536)
      ]))
    }), 128))
  ]))
}