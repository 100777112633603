
import { Vue, prop } from "vue-class-component";

class Props {
  type = prop<string>({
    type: String,
    default: "cod"
  });
  booking = prop<string>({
    type: String,
    default: ""
  });
}

export default class WarningCodRetail extends Vue.with(Props) {}
