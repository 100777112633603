import { AxiosResponse } from "axios";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import {
  PromoConfiguration,
  PromoConfigurationList,
  PromoConfigurationListData
} from "@/domain/entities/PromoConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
export class PromoConfigurationMapper {
  public convertSubmitDataFromApi(result: AxiosResponse): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message
    });
  }

  public convertPromoConfigurationListFromApi(
    res: AxiosResponse<any>
  ): PromoConfigurationList {
    const { data } = res;

    return new PromoConfigurationList(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data
        ? data.data.map((data: any) => {
            return new PromoConfigurationListData({
              promoConfigurationId: data.promo_configuration_id,
              promoConfigurationName: data.promo_configuration_name,
              promoConfigurationStartDate: data.promo_configuration_start_date,
              promoConfigurationEndDate: data.promo_configuration_end_date,
              promoConfigurationUpdatedName:
                data.promo_configuration_updated_name,
              promoConfigurationUpdatedAt: data.promo_configuration_updated_at,
              promoConfigurationStatus: data.promo_configuration_status,
              promoConfigurationCategory: data.promo_configuration_category,
              promoConfigurationDiscountNominal:
                data.promo_configuration_discount_nominal,
              promoConfigurationAppliedTo: data.promo_applied_to,
              promoConfigurationAppliedShipments: data.promo_applied_shipments
            });
          })
        : []
    );
  }

  public convertDetailPromoConfigurationFromApi(
    result: AxiosResponse
  ): PromoConfiguration {
    const {
      data: { data }
    } = result;

    return new PromoConfiguration({
      promoConfigurationId: data.pdc_id,
      promoConfigurationName: data.pdc_name,
      promoConfigurationType: data.pdc_category,
      promoConfigurationOrigin: data.pdc_origin,
      promoConfigurationDestination: data.pdc_destination,
      promoConfigurationProductType: data.pdc_product_type,
      promoConfigurationMinimalGrossWeight: data.pdc_minimal_gross_weight,
      promoConfigurationMaximalGrossWeight: data.pdc_maximal_gross_weight,
      promoConfigurationStartDate: data.pdc_start_date,
      promoConfigurationEndDate: data.pdc_end_date,
      promoConfigurationDiscountType: data.pdc_discount_type,
      promoConfigurationPromoDiscount: data.pdc_promo_discount,
      promoConfigurationMaximalPromo: data.pdc_maximal_promo,
      promoConfigurationParameterCalculation: data.pdc_parameter_calculation,
      promoConfigurationAppliedTo: data.pdc_applied_to,
      promoConfigurationCreatedAt: data.pdc_created_at,
      promoConfigurationCreatedBy: data.pdc_created_by,
      promoConfigurationCreatedName: data.pdc_created_name,
      promoConfigurationUpdatedAt: data.pdc_updated_at,
      promoConfigurationUpdatedBy: data.pdc_updated_by,
      promoConfigurationUpdatedName: data.pdc_updated_name,
      promoConfigurationStatus: data.pdc_status,
      pdcPromoReferenceWeight: data.pdc_promo_reference_weight,
      promoConfigurationAppliedList: data.pdc_applied_list,
      promoConfigurationShipmentOption: data.pdc_shipment_option,
    });
  }
}
