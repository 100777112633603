import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CashActive = _resolveComponent("CashActive")!
  const _component_PaymentSuccess = _resolveComponent("PaymentSuccess")!
  const _component_DialogPaymentLayout = _resolveComponent("DialogPaymentLayout")!

  return (_openBlock(), _createBlock(_component_DialogPaymentLayout, null, {
    default: _withCtx(() => [
      (_ctx.activeState)
        ? (_openBlock(), _createBlock(_component_CashActive, {
            key: 0,
            billType: _ctx.billType
          }, null, 8, ["billType"]))
        : _createCommentVNode("", true),
      (_ctx.successState)
        ? (_openBlock(), _createBlock(_component_PaymentSuccess, {
            key: 1,
            billType: _ctx.billType,
            onOnSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closePayment')))
          }, null, 8, ["billType"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}