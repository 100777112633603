
import { prop, Vue } from "vue-class-component";
import { cargoTypeData } from "@/app/ui/views/cargo/modules/cargo-module";

class Props {
  slaMin = prop<number>({
    type: Number,
    default: 0
  });
  slaMax = prop<number>({
    type: Number,
    default: 0
  });
  transport = prop<string>({
    type: String,
    default: ""
  });
}

export default class WarningSlaTransportation extends Vue.with(Props) {
  get translatedCargoType() {
    return cargoTypeData(this.transport.toLowerCase()).label;
  }
}
