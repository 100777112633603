import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-lp-100 text-12px"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: 
      `${
        _ctx.isValid ? 'bg-gray-lp-200 p-4 items-start' : 'item'
      } flex flex-row rounded text-gray-lp-800 text-12px xxl:text-14px space-x-2`
    
  }, [
    _createVNode("img", {
      src: 
        require(`@/app/ui/assets/svg/${
          _ctx.isValid ? 'info-red' : 'exclamation-triangle'
        }.svg`)
      ,
      alt: ""
    }, null, 8, ["src"]),
    (!_ctx.isValid)
      ? (_openBlock(), _createBlock("div", _hoisted_1, " Tidak bisa ganti ke Area vendor " + _toDisplayString(_ctx.type) + " (Ninja) ", 1))
      : (_openBlock(), _createBlock("div", _hoisted_2, " Untuk tujuan yang dipilih maksimal 1 koli "))
  ], 2))
}