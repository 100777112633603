
import { Vue, Options } from "vue-class-component";
import QrActive from "@/app/ui/components/dialog-payment/payment-qr/qr-active.vue";
import QrLoading from "@/app/ui/components/dialog-payment/payment-qr/qr-loading.vue";
import {
  PAYMENT_STATE,
  PaymentProps
} from "@/app/ui/components/dialog-payment/payment-props";
import QrReload from "@/app/ui/components/dialog-payment/payment-qr/qr-reload.vue";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import QrPaymentSuccess from "@/app/ui/components/dialog-payment/components/payment-success.vue";
import QrPaymentFailedExpired from "@/app/ui/components/dialog-payment/payment-qr/qr-payment-failed-expired.vue";
import DialogPaymentLayout from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayout.vue";
import { PaymentMethod } from "@/domain/entities/Payment";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

@Options({
  emits: ["closePayment"],
  components: {
    DialogPaymentLayout,
    QrPaymentFailedExpired,
    QrPaymentSuccess,
    QrReload,
    QrLoading,
    QrActive
  }
})
export default class DialogQR extends Vue.with(PaymentProps) {
  mounted() {
    PaymentController.generateQr({
      sttNo: PaymentController.sttNumber,
      paymentMethod: PaymentController.paymentMethod.name,
      billType: this.billType
    });
  }

  get state() {
    return PaymentController.paymentState;
  }
  get activeState() {
    return this.state === PAYMENT_STATE.ACTIVE_STATE;
  }
  get successState() {
    return this.state === PAYMENT_STATE.SUCCESS_STATE;
  }
  get loadingState() {
    return this.state === PAYMENT_STATE.LOADING_STATE;
  }
  get reloadState() {
    return this.state === PAYMENT_STATE.RELOAD_STATE;
  }
  get failedOrExpiredState() {
    return (
      this.state === PAYMENT_STATE.EXPIRED_STATE ||
      this.state === PAYMENT_STATE.FAILED_STATE
    );
  }

  get closeUiState() {
    return this.activeState || this.failedOrExpiredState;
  }
  closeDialogPayment() {
    dataLayer(
      `${
        this.billType
      }_${PaymentController.paymentMethod.name.toLowerCase()}_close_clicked`,
      {},
      ["timestamp", "userType", "userId"]
    );
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(new PaymentMethod());
    if (this.billType === "booking") {
      PaymentController.toggleDialogPayment(false);
      BookingController.setForceRouter(true);
      this.$router.push("/shipment/booking");
    }
  }
}
