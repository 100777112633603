
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue, prop } from "vue-class-component";

class Props {
  bannerType = prop<string>({
    type: String,
    default: "",
  });
  commission = prop<number>({
    type: Number,
    default: 0,
  });
  profit = prop<number>({
    type: Number,
    default: 0,
  });
  maxProtection = prop<number>({
    type: Number,
    default: 0,
  });
  cost = prop<number>({
    type: Number,
    default: 0,
  });
  estimationPrice = prop<any>({
    type: Number,
    default: "0",
  });
}

export default class InfoInsurance extends Vue.with(Props) {
  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get textContent() {
    const result: any = {
      default: {
        value: `<p><strong> Agen bisa dapat tambahan income ${
          this.commission
        }% dari premi,</strong> Yuk tawarkan customer pakai <strong>asuransi</strong> agar barang terproteksi <strong>${this.formatLargeNumber(
          this.maxProtection
        )}!</strong></p>`,
      },
      cod: {
        value: `<p><strong> Pengiriman COD sudah tercover asuransi!</strong> Barang customer terproteksi asuransi hingga <strong>${this.formatLargeNumber(
          this.maxProtection
        )}!</strong></p>`,
      },
      dfod: {
        value: `<p><strong> Agen bisa dapat tambahan income ${formatPrice(
          this.profit
        )},</strong> Yuk tawarkan customer pakai <strong> asuransi seharga ${formatPrice(
          (this.cost / 100) * +this.estimationPrice.replace(/\./g, "")
        )}</strong> agar barang terproteksi hingga <strong>${this.formatLargeNumber(
          this.maxProtection
        )}!</strong></p>`,
      },
      dfodmax: {
        value: `<p><strong>Harga barang</strong> melebihi <strong>5 juta, asuransi otomatis terpilih. Agen dapat tambahan income ${formatPrice(
          (this.cost / 100) * +this.estimationPrice.replace(/\./g, "")
        )} </strong> & customer dapat proteksi hingga <strong>${this.formatLargeNumber(
          this.maxProtection
        )}!</p>`,
      },
    };
    return result[this.bannerType];
  }

  largeNumber(number: any) {
    if (number >= 1e9) {
      return (number / 1e9).toString().replace(".", ",") + " Miliar";
    } else if (number >= 1e6) {
      return (number / 1e6).toString().replace(".", ",") + " Juta";
    } else if (number >= 1e3) {
      return (number / 1e3).toString().replace(".", ",") + " Ribu";
    }
  }

  replaceNumber(result: any) {
    if (result?.split(",")[1]?.includes("0")) {
      result = result?.split(",")[0] + result?.split(",")[1].replace("0", "");
    }
    return result;
  }

  formatLargeNumber(number: any) {
    let result: any = "";
    const isLargeNumber = number >= 1e9 || number >= 1e6 || number >= 1e3;
    if (isLargeNumber) {
      result = this.largeNumber(number);
    } else {
      result = number.toString();
    }
    result = this.replaceNumber(result);
    return result;
  }
}
