
import { Options, prop, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import BookV2 from "./components/book-v2.vue";
import { PropType } from "vue";
import ModalErrorBalance from "@/app/ui/views/shipment/booking/component/modal-error-balance.vue";
import CheckTariffV2 from "@/app/ui/views/shipment/booking/create-booking-v2/components/footer-booking/check-tariff-v2.vue";
import SectionFooterDetailPayment from "./section-footer-detail-payment.vue";
import SelectReceiptLanguageV2 from "@/app/ui/views/shipment/booking/create-booking-v2/components/footer-booking/components/select-receipt-language-v2.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PromoConfigurationListRequest } from "@/data/payload/api/PromoConfigurationApiRequest";
import { PromoConfigurationController } from "@/app/ui/controllers/PromoConfigurationController";
import {
  PromoConfigurationList,
  PromoConfigurationListData
} from "@/domain/entities/PromoConfiguration";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import WeightCost from "./components/weight-cost.vue";

interface Diskon {
  isDiscount: boolean,
  totalTariff: number,
  totalAfterDiscount: number
}

interface DiscountInterface {
  status: boolean;
  data: PromoConfigurationListData[];
}

class Props {
  onNextPackage = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
  isDisabled = prop<boolean>({
    required: true,
  });
  formType = prop<string>({
    required: true,
  });
  payload = prop<any>({
    required: true,
  });
  accountRefAndCommodity = prop<any>({
    required: true,
  });
  isCodRetailApplied = prop<boolean>({
    required: true,
  });
  totalItem = prop<any>({
    default: "0",
  });
  totalGrossWeight = prop<any>({
    default: "0",
  });
  totalDimensionWeight = prop<any>({
    default: "0",
  });
  chargeableWeight = prop<any>({
    default: "0",
  });
  isVolumeWeight = prop<boolean>({
    required: true,
  });
  isCampaign = prop<boolean>({
    required: true,
  });
  isGrossWeight = prop<boolean>({
    required: true,
  });
  goodsValue = prop<any>({
    default: "0"
  });
}

@Options({
  emits: ["updateStatusErrorEmbargo", "setDiscount"],
  components: {
    SectionFooterDetailPayment,
    CheckTariffV2,
    Tooltip,
    BookV2,
    ModalErrorBalance,
    SelectReceiptLanguageV2,
    WeightCost
  },
  watch: {
    "payload.productName": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.destinationCity": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttGrossWeight": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttVolumeWeight": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    }
  }
})

export default class SectionFooterBooking extends Vue.with(Props) {
  get isCreateBookingRetail() {
    return (
      this.formType === "manual" && AccountController.accountData.isAllPartner
    );
  }
  get isProductInterpack() {
    return this.payload.productName === "INTERPACK";
  }

  discount: DiscountInterface = {
    status: false,
    data: []
  };

  async checkDiscountActive() {
    if (
      this.payload.originCity &&
      this.payload.destinationCity &&
      this.payload.productName &&
      this.payload.sttGrossWeight > 0 && 
      this.formType === 'manual' && 
      AccountController.accountData.isPosAccount
    ) {
      try {
        const promotion: PromoConfigurationList = await PromoConfigurationController.getPromoActive(
          new PromoConfigurationListRequest({
            page: 1,
            limit: 2,
            status: "active,nearly-expired",
            origin: this.payload.originCity,
            destination: this.payload.destinationCity,
            productName: this.payload.productName,
            grossWeight: this.payload.sttGrossWeight,
            volumeWeight: this.payload.sttVolumeWeight,
            chargeableWeight: this.payload.sttChargeableWeight,
            isTotalData: false,
            appliedTo: "retail"
          })
        );

        const promotionList = promotion.data;
        const isRetail = promotion.data[0].promoConfigurationAppliedTo.includes("retail");
        if (promotionList.length > 0 && isRetail) {
          this.discount = {
            status: true,
            data: promotionList.map((item: PromoConfigurationListData) => {
              return new PromoConfigurationListData({
                promoConfigurationName: `${
                  item.promoConfigurationName
                } sampai dengan ${formatDateWithoutTime(
                  item.promoConfigurationEndDate
                )}`,
                promoConfigurationDiscountNominal:
                  item.promoConfigurationDiscountNominal
              })
            }) 
          };
        } else {
          this.resetDiscountNominal();
        }
      } catch (err) {
        this.discount.status = false;
      }
    }
  }

  setDiscount(params: Diskon) {
    this.$emit("setDiscount", params)
  }

  resetDiscount() {
    if (this.payload.sttGrossWeight <= 0 || this.payload.sttVolumeWeight <= 0) {
      this.resetDiscountNominal();
    }
  }

  resetDiscountNominal() {
    this.discount = {
      status: false,
      data: []
    }
  }

  get customStyleLanguage() {
    return this.discount.status ? 'margin-top: 50px' : 'margin-top: 25px'
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }
}
