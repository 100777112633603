
import { Options, prop, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import Book from "../../../edit-booking/edit-payment-detail/book.vue";
import { PropType } from "vue";
import ModalErrorBalance from "@/app/ui/views/shipment/booking/component/modal-error-balance.vue";
import CheckTariffV2 from "./check-tariff-v2.vue";
import SectionFooterDetailPayment from "./section-footer-detail-payment.vue";
import SelectReceiptLanguageV2 from "./components/select-receipt-language-v2.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PromoConfigurationListRequest } from "@/data/payload/api/PromoConfigurationApiRequest";
import { PromoConfigurationController } from "@/app/ui/controllers/PromoConfigurationController";
import {
  PromoConfigurationList,
  PromoConfigurationListData,
} from "@/domain/entities/PromoConfiguration";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import WeightCost from "./components/weight-cost.vue";
import { promoPrefix } from "@/app/ui/views/shipment/booking/booking-utils";

interface Diskon {
  isDiscount: boolean;
  totalTariff: number;
  totalAfterDiscount: number;
}

interface DiscountInterface {
  status: boolean;
  data: PromoConfigurationListData[];
}

class Props {
  onNextPackage = prop<any>({
    default: Function as PropType<() => void>,
    type: Function,
  });
  isDisabled = prop<boolean>({
    required: true,
  });
  formType = prop<string>({
    required: true,
  });
  payload = prop<any>({
    required: true,
  });
  accountRefAndCommodity = prop<any>({
    required: true,
  });
  isCodRetailApplied = prop<boolean>({
    required: true,
  });
  totalItem = prop<any>({
    default: "0",
  });
  totalGrossWeight = prop<any>({
    default: "0",
  });
  totalDimensionWeight = prop<any>({
    default: "0",
  });
  chargeableWeight = prop<any>({
    default: "0",
  });
  isVolumeWeight = prop<boolean>({
    required: true,
  });
  isCampaign = prop<boolean>({
    required: true,
  });
  isGrossWeight = prop<boolean>({
    required: true,
  });
  goodsValue = prop<any>({
    default: "0",
  });
  showCurrency = prop<boolean>({
    default: false,
  });
  isSttForeign = prop<boolean>({
    default: false,
  });
}

@Options({
  emits: ["updateStatusErrorEmbargo", "setDiscount"],
  components: {
    SectionFooterDetailPayment,
    CheckTariffV2,
    Tooltip,
    Book,
    ModalErrorBalance,
    SelectReceiptLanguageV2,
    WeightCost,
  },
  watch: {
    "payload.sttProductType": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttDestinationCityId": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttGrossWeight": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttVolumeWeight": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
  },
})
export default class SectionFooterBooking extends Vue.with(Props) {
  get isCreateBookingRetail() {
    return (
      this.formType === "manual" && AccountController.accountData.isAllPartner
    );
  }
  get isProductInterpack() {
    return this.payload.sttProductType === "INTERPACK";
  }

  discount: DiscountInterface = {
    status: false,
    data: [],
  };

  get bookingTypePromoAllowed() {
    return (
      this.payload.sttBooked.sttShipmentId ||
      this.payload.sttBooked.sttBookedForType === "pos"
    );
  }

  get isReverseJourney() {
    return this.payload.sttNo.match(/^78LP|^77LP|^76LP|^94LP/gi);
  }

  get isAccountGetPromo() {
    return (
      AccountController.accountData.isAllPartner ||
      AccountController.accountData.isInternalAccount
    );
  }

  async checkDiscountActive() {
    const grossWeight = Number(this.payload.sttGrossWeight.replace(",", "."));
    if (
      this.payload.sttOriginCityId &&
      this.payload.sttDestinationCityId &&
      this.payload.sttProductType &&
      grossWeight > 0 &&
      this.bookingTypePromoAllowed &&
      this.isAccountGetPromo &&
      !this.isReverseJourney
    ) {
      try {
        const promotion: PromoConfigurationList = await PromoConfigurationController.getPromoActive(
          new PromoConfigurationListRequest({
            page: 1,
            limit: 2,
            status: "active,nearly-expired",
            origin: this.payload.sttOriginCityId,
            destination: this.payload.sttDestinationCityId,
            productName: this.payload.sttProductType,
            grossWeight: grossWeight,
            volumeWeight: this.payload.sttVolumeWeight,
            shipmentPrefix: this.payload.promoPrefix,
            chargeableWeight: this.payload.sttChargeableWeight,
            isTotalData: false,
            appliedTo: this.payload.sttBooked.sttShipmentId
              ? "shipment"
              : "retail",
          })
        );

        const promotionList = promotion.data;
        const isGetDiscountAllowed = this.allowedToGetDiscount(promotion);
        if (promotionList.length > 0 && isGetDiscountAllowed) {
          this.discount = {
            status: true,
            data: promotionList.map((item: PromoConfigurationListData) => {
              return new PromoConfigurationListData({
                promoConfigurationName: `${
                  item.promoConfigurationName
                } sampai dengan ${formatDateWithoutTime(
                  item.promoConfigurationEndDate
                )}`,
                promoConfigurationDiscountNominal:
                  item.promoConfigurationDiscountNominal,
              });
            }),
          };
        } else {
          this.resetDiscountNominal();
        }
      } catch (err) {
        this.discount.status = false;
      }
    }
  }

  allowedToGetDiscount(promotion: PromoConfigurationList) {
    let isGetDiscountAllowed = false;
    const prefix = promoPrefix(this.payload.sttBooked.sttShipmentId);
    if (prefix) {
      isGetDiscountAllowed = promotion.data[0].promoConfigurationAppliedShipments.includes(prefix)
    } else {
      isGetDiscountAllowed = true;
    }

    return isGetDiscountAllowed;
  }

  setDiscount(params: Diskon) {
    this.$emit("setDiscount", params);
  }

  resetDiscount() {
    if (this.payload.sttGrossWeight <= 0 || this.payload.sttVolumeWeight <= 0) {
      this.resetDiscountNominal();
    }
  }

  resetDiscountNominal() {
    this.discount = {
      status: false,
      data: [],
    };
  }

  get customStyleLanguage() {
    return this.discount.status ? "margin-top: 50px" : "margin-top: 25px";
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }
}
