import { injectable } from "tsyringe";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { PromoConfigurationRepository } from "@/app/infrastructures/repositories/api/PromoConfigurationRepository";
import {
  PromoConfiguration,
  PromoConfigurationList
} from "@/domain/entities/PromoConfiguration";
import {
  PromoConfigurationListRequest,
  SubmitPromoConfigurationApiRequest
} from "@/data/payload/api/PromoConfigurationApiRequest";
@injectable()
export class PromoConfigurationPresenter {
  private repository: PromoConfigurationRepository;

  constructor(repo: PromoConfigurationRepository) {
    this.repository = repo;
  }
  createPromoConfiguration(
    payload: SubmitPromoConfigurationApiRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.createPromoConfiguration(payload);
  }
  editPromoConfiguration(
    payload: SubmitPromoConfigurationApiRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.editPromoConfiguration(payload);
  }

  getPromoConfigurationList(
    params: PromoConfigurationListRequest
  ): Promise<PromoConfigurationList> {
    return this.repository.getPromoConfigurationList(params);
  }

  getDetailPromoConfiguration(id: number): Promise<PromoConfiguration> {
    return this.repository.getDetailPromoConfiguration(id);
  }

  getPromoActive(
    params: PromoConfigurationListRequest
  ): Promise<PromoConfigurationList> {
    return this.repository.getPromoActive(params);
  }
}
