const prefixAlgoCheck = (params: {
  prefix: string; // e.g. "ARA,ARB,AS,AP"
  shipmentId: string;
}): boolean => {
  const prefix = params.prefix
    .split(",")
    .map(item => `^${item}`)
    .join("|");
  return !!params.shipmentId.match(new RegExp(prefix));
};
export default prefixAlgoCheck;
