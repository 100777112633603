
import formatDateWithDay from "@/app/infrastructures/misc/common-library/FormatDateWithDay";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { PickupScheduleController } from "@/app/ui/controllers/PickupScheduleController";
import { ApiStateData } from "@/domain/entities/Api";
import { OptionsClass } from "@/domain/entities/MainApp";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  CourierVehicle,
  PickupSchedule,
  PickupScheduleList,
  RequestPickupScheduleList,
} from "@/domain/entities/PickupSchedule";
import { FlagsPermissionPickup } from "@/feature-flags/flag-pickup";
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { ClientController } from "@/app/ui/controllers/ClientController";
import {
  ClientData,
  ClientMappingList,
  RequestApiClientMappingList,
} from "@/domain/entities/Client";
import { AccountController } from "@/app/ui/controllers/AccountController";
import formatRepeatDays from "./utils";
import router from "@/app/ui/router";

@Options({
  components: {
    SelectSearchV2,
    Checkbox,
  },
})
export default class ScheduleList extends Vue {
  mounted() {
    if (this.isClientBranch) {
      Object.assign(this.filter, {
        psClientId: this.clientBranchInfo,
      });
    }
    this.filterProductsDelivery("");
    this.getPickupScheduleList();
  }

  get isClientBranch() {
    return AccountController.accountData.isClientBranchAccount;
  }

  get clientBranchInfo() {
    return {
      id: AccountController.accountData.account_type_detail.id,
      name: AccountController.accountData.name,
      code: AccountController.accountData.account_type_detail.code,
      display: AccountController.accountData.name,
      isActive: false,
      status: "approved",
    };
  }

  get getTitle() {
    return this.$route.meta.title;
  }

  listData: PickupScheduleList = new PickupScheduleList();
  apiListData = new ApiStateData();

  async getPickupScheduleList() {
    this.apiListData.loading = true;
    try {
      const resp = await PickupScheduleController.getPickupScheduleList(
        new RequestPickupScheduleList({
          page: this.listData.pagination.page,
          psClientId: this.filter.psClientId?.id || "",
          psServiceCodes: this.filter.psServiceCodes,
          psStatus: this.filter.psStatus,
          psTransportType: this.filter.psTransportType,
          isTotalData: true,
        })
      );
      this.listData = new PickupScheduleList({
        pagination: new PaginationV2({
          page: resp.pagination.page,
          limit: resp.pagination.limit,
          totalData: resp.pagination.totalData,
        }),
        data: resp.data,
      });
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
    }
  }

  get isAbleCreate() {
    return FlagsPermissionPickup.permission_pickup_scheduled_pickup_create.isEnabled();
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (_: any, index: any) => {
          return `<div class="text-left">${
            index +
            1 +
            this.listData.pagination.limit * (this.listData.pagination.page - 1)
          }</div>`;
        },
      },
      {
        name: "Nama konfigurasi jadwal",
        styleHead: "w-56 text-left",
        render: (item: PickupSchedule) => {
          return `<div class="text-left">${item.pickupScheduleName}</div>`;
        },
      },
      {
        name: "Referensi Klien",
        styleHead: "w-44 text-left whitespace-nowrap",
        render: (item: PickupSchedule) => {
          return `<div class="text-left">${item.pickupScheduleClientName}</div>`;
        },
      },
      {
        name: "Tanggal Dimulai",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: PickupSchedule) => {
          return `<div class="overflow-ellipsis text-left">${formatDateWithDay(
            item.pickupScheduleStart
          )}</div>`;
        },
      },
      {
        name: "Tanggal Selesai",
        styleHead: "w-48 text-left whitespace-nowrap",
        render: (item: PickupSchedule) => {
          return `<div class="overflow-ellipsis text-left">${formatDateWithDay(
            item.pickupScheduleEnd
          )}</div>`;
        },
      },
      {
        name: "Pengulangan Pickup",
        styleHead: "w-72 text-left whitespace-nowrap",
        render: (item: PickupSchedule) => {
          return `<div class="overflow-ellipsis text-left">${this.formatRepeatDays(
            item
          )}</div>`;
        },
      },
      {
        name: "Tipe Armada",
        styleHead: "w-44 text-left whitespace-nowrap",
        render: (item: PickupSchedule) => {
          return `<div class="overflow-ellipsis text-left">${item.pickupScheduleTransportType}</div>`;
        },
      },
      {
        name: "Jenis Pengiriman",
        styleHead: "w-44 text-left whitespace-nowrap",
        render: (item: PickupSchedule) => {
          return `<div class="overflow-ellipsis text-left">${item.pickupScheduleServiceCodes.split(",").join(", ")}</div>`;
        },
      },
      {
        name: "Status",
        styleHead: "w-36 text-left",
        render: (item: PickupSchedule) =>
          ChipsStyles({ status: item.pickupScheduleStatus }),
      },
    ];
  }

  get isEmpty(): boolean {
    return !this.listData.data.length && this.sumFilter === 0;
    // add no filter
  }

  onClickRow(item: PickupSchedule) {
    router.push(`/pickup/scheduled-pickup/${item.pickupScheduleId}`);
  }

  //advanced filter

  filter = {
    psClientId: "" as any,
    psTransportType: "" as any,
    psServiceCodes: "" as any,
    psStatus: "" as any,
  };

  get sumFilter(): number {
    return (
      ((this.filter.psClientId as OptionsClass)?.id ? 1 : 0) +
      (this.filter.psTransportType?.length || this.filter.psTransportType?.vehicleName?.length ? 1 : 0) +
      (this.filter.psServiceCodes.length ? this.filter.psServiceCodes.length : 0) +
      (this.filter.psStatus.length ? 1 : 0)
    );
  }
  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onChangeAdvancedFilter() {
    this.listData.pagination.page = 1;
    this.getPickupScheduleList();
  }
  async onResetAdvancedFilter() {
    this.apiListData.loading = true;
    Object.assign(this.filter, {
      psClientId: this.isClientBranch ? this.clientBranchInfo : "",
      psTransportType: "",
      psServiceCodes: "",
      psStatus: "",
    });

    this.listData.pagination.page = 1;
    await this.getPickupScheduleList();
  }

  formatRepeatDays(obj: PickupSchedule) {
    return formatRepeatDays(obj);
  }

  //referensi klien
  onSearchClientName = debounce((value: string) => {
    if (!value || value.length >= 3) {
      if (AccountController.accountData.isClientParentAccount) {
        ClientController._onGetList({
          page: 1,
          limit: 10,
          search: value,
          status: "",
          clientParentId: AccountController.accountData.account_type_detail?.id,
        });
        return;
      }
      ClientController._onGetCLientMappingList(
        new RequestApiClientMappingList({
          page: 1,
          limit: 10,
          search: value,
          isParent: false,
          isBranch: true,
        })
      );
    }
  }, 250);

  get isLoadingClientPartner() {
    return ClientController.isLoading;
  }

  get mappedDataClient() {
    if (AccountController.accountData.isClientParentAccount) {
      return this.mappedDataClientBranch;
    }
    return ClientController.clientMappingData.map((client: ClientMappingList) => ({
      id: client.clientId,
      name: client.clientName,
      code: client.clientCode,
      display: `${client.clientCode}-${client.clientName?.toUpperCase()}`,
      isActive: false,
    }));
  }

  onSelectClient() {
    Object.assign(this.filter, {
      psClientId: this.filter.psClientId || "",
    });
    this.onChangeAdvancedFilter();
  }

  // status pickup schedule
  get statusPickupSchedule(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "Aktif",
        value: "active",
      }),
      new OptionsClass({
        name: "Tidak Aktif",
        value: "inactive",
      }),
    ];
  }

  selectStatus(data: OptionsClass) {
    Object.assign(this.filter, { psStatus: data.value });
    this.onChangeAdvancedFilter();
  }
  //product
  get productOptions() {
    const listData = BookingController.productsDelivery.data
      .filter((res) => res.name.toUpperCase() !== "JUMBOPACKH2H")
      .map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.name,
        status: e.status,
      }));
    
    return listData;
  }

  onChangeProduct() {
    this.onChangeAdvancedFilter();
  }

  //product
  filterProductsDelivery = debounce((search: string) => {
    BookingController.getProductsDelivery({
      search,
      page: 1,
      limit: 99,
      status: "active",
    });
  }, 250);

  //courier vehicle
  vehicles: CourierVehicle[] = [];

  async getCourierVehicleList(search: string) {
    this.isLoadingVehicleList = true;
    try {
      const resp = await PickupScheduleController.getCourierVehicleList(search);
      this.vehicles = resp;
    } catch (error) {
      this.vehicles = [];
    } finally {
      this.isLoadingVehicleList = false;
    }
  }

  onSelectVehicle() {
    Object.assign(this.filter, {
      psTransportType: this.filter.psTransportType?.vehicleName || "",
    });
    this.onChangeAdvancedFilter();
  }
  onSearchVehicle = debounce((search: string) => {
    this.getCourierVehicleList(search);
  }, 250);

  isLoadingVehicleList = false;

  get mappedDataClientBranch() {
    return ClientController.clientData.data
      .map((client: ClientData) => ({
        id: client.id,
        name: client.companyName,
        code: client.code,
        display: `${client.code}-${client.companyName?.toUpperCase()}`,
        isActive: false,
        status: client.status,
      }))
      .filter((item: any) => item.status === "approved");
  }
}
