import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, Fragment as _Fragment } from "vue"
import _imports_0 from '@/app/ui/assets/svg/refresh.svg'
import _imports_1 from '@/app/ui/assets/svg/chevron-up-light.svg'
import _imports_2 from '@/app/ui/assets/svg/chevron-down-light.svg'


const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "text-12px xxl:text-14px text-gray-lp-300" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex items-center relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_discount = _resolveComponent("discount")!
  const _component_detail_estimation = _resolveComponent("detail-estimation")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _withDirectives(_createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('bookingShipment.estimation.totalCost')), 1),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _withDirectives(_createVNode(_component_Tooltip, {
            title: "",
            message: _ctx.$t('bookingShipment.tooltip.updateCost'),
            footer: "",
            isArrowBottom: false,
            isArrowRight: "",
            style: {"width":"150px","margin-top":"-0.5em","margin-left":"-11em"}
          }, null, 8, ["message"]), [
            [_vShow, _ctx.isOpenTooltip]
          ]),
          _createVNode("img", {
            class: "cursor-pointer",
            alt: "",
            src: _imports_0,
            onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onOpenTooltip(true))),
            onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onOpenTooltip(false))),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateEstimationPrice && _ctx.updateEstimationPrice(...args)))
          }, null, 32)
        ]),
        _createVNode("div", {
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showDetail && _ctx.showDetail(...args))),
          class: 
          `flex items-center text-20px cursor-pointer ml-2 ${
            _ctx.isDiscountActive || _ctx.dataDetail.isPromo
              ? 'text-gray-lp-600 line-through'
              : 'text-red-lp-100 font-bold'
          }`
        
        }, _toDisplayString(_ctx.currency(_ctx.dataDetail.totalTariff)), 3),
        _createVNode("div", {
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.showDetail && _ctx.showDetail(...args))),
          class: "flex items-center ml-2"
        }, [
          _createVNode("img", {
            alt: "",
            class: `cursor-pointer ${_ctx.isShow ? '' : 'hidden'}`,
            src: _imports_1
          }, null, 2),
          _createVNode("img", {
            alt: "",
            class: `cursor-pointer ${_ctx.isShow ? 'hidden' : ''}`,
            src: _imports_2
          }, null, 2)
        ])
      ]),
      _createVNode("div", {
        class: "flex cursor-pointer",
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.showDetail && _ctx.showDetail(...args)))
      }, [
        (_ctx.isDiscountActive || _ctx.dataDetail.isPromo)
          ? (_openBlock(), _createBlock(_component_discount, {
              key: 0,
              showDiscountNominal: _ctx.showDiscountNominal,
              discountData: _ctx.discountData.data,
              defaultDiscount: _ctx.dataDetail.listDiscount,
              tariffAfterDiscount: _ctx.dataDetail.totalTariffAfterDiscount
            }, null, 8, ["showDiscountNominal", "discountData", "defaultDiscount", "tariffAfterDiscount"]))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_directive_click_outside, () => _ctx.onCloseAnywhere(false)]
    ]),
    _createVNode(_component_detail_estimation, {
      isShow: _ctx.isShow,
      dataDetail: _ctx.dataDetail,
      isDiscountActive: _ctx.isDiscountActive || _ctx.dataDetail.isPromo,
      isCodRetail: _ctx.isCodRetail || _ctx.isShipmentCodPrefix || _ctx.payload.sttIsDfod || _ctx.isShipmentFavoriteCOD,
      isForeignCurrency: _ctx.isSttForeign
    }, null, 8, ["isShow", "dataDetail", "isDiscountActive", "isCodRetail", "isForeignCurrency"])
  ], 64))
}