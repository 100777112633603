
import { Vue, Options } from "vue-class-component";
import { PaymentProps } from "@/app/ui/components/dialog-payment/payment-props";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import DialogPaymentLayoutHeader from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutHeader.vue";
import DialogPaymentLayoutImage from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutImage.vue";
import DialogPaymentLayoutButton from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutButton.vue";
import { PaymentMethod } from "@/domain/entities/Payment";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

@Options({
  components: {
    DialogPaymentLayoutButton,
    DialogPaymentLayoutImage,
    DialogPaymentLayoutHeader
  },
  emits: ["onSubmit"]
})
export default class PaymentSuccess extends Vue.with(PaymentProps) {
  get paidWith() {
    return PaymentController.paymentMethod?.title;
  }
  get billAmount() {
    return PaymentController.formattedBillAmountAfterDiscount;
  }
  get sttNumber() {
    return PaymentController.sttNumber;
  }

  successPayment() {
    dataLayer(
      `${
        this.billType
      }_bayar_${PaymentController.paymentMethod.name.toLowerCase()}_success_clicked`,
      {},
      ["timestamp", "userType", "userId"]
    );
    PaymentController.toggleDialogPayment(false);
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(new PaymentMethod());
    this.$emit("onSubmit");
  }
}
