
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import Checkbox from "primevue/checkbox";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
import isUrlUpdateVersion from "@/app/infrastructures/misc/common-library/IsUrlUpdateVersion";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import DetailCalculation from "./payment-detail-footer/calculation.vue";
import DetailUncalculation from "./payment-detail-footer/uncalculation.vue";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { ManualSTTController } from "@/app/ui/controllers/ManualSTTController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import router from "@/app/ui/router";
import { maxLengthWeight } from "../component/modules";
import {
  maxKg,
  SHIPMENT_ID_FAVOURITE,
  codePhoneCountry,
  totalGrossWeightSikat,
  totalGrossWeightNonSikat,
  clientMapperSikat,
  MAX_WEIGHT_SHIPMENT,
  prefixDisableDimension,
  volumeWeightForProduct
} from "@/app/ui/views/shipment/booking/booking-utils";
import LpInputSearch from "@/app/ui/components/lp-input-search/index.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import SelectPhoneCountry from "../component/select-phone-country.vue";
import { ProductConfigurationRuleList } from "@/domain/entities/Product";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ProductConfigurationRuleRequestParams } from "@/data/payload/api/ProductApiRequest";
import WarningSlaTransportation from "@/app/ui/views/shipment/booking/component/error-message/warning-sla-transportation.vue";
import BannerProductType from "@/app/ui/views/shipment/booking/component/banner-product-type.vue";
import ProductPoster from "../component/product-poster/index.vue";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import RadioButton from "primevue/radiobutton";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import {
  parsingErrorResponse,
  formatPriceWithoutCurrency
} from "@/app/infrastructures/misc/Utils";
import {
  InsuranceTypeDeliveryData,
  ManualBooking
} from "@/domain/entities/Booking";
import InformationCodRetail from "@/app/ui/views/shipment/booking/component/error-message/warning-cod-retail.vue";
import Woodpacking from "../component/woodpacking/index.vue";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";

@Options({
  emits: ["successBooking"],
  components: {
    WarningSlaTransportation,
    Title,
    Tooltip,
    InputDimension,
    Checkbox,
    DetailCalculation,
    DetailUncalculation,
    LpInputSearch,
    SelectPhoneCountry,
    BannerProductType,
    ProductPoster,
    RadioButton,
    InformationCodRetail,
    Woodpacking
  },
  props: {
    shipmentId: {
      default: "",
      type: String
    },
    source: {
      default: "",
      type: String
    }
  }
})
export default class extends Vue {
  get maxAddressLength() {
    return FlagsMedusa.config_max_address.getValue();
  }

  get titleForm() {
    return (
      BookingController.shipmentIdList.data.length +
      1 +
      " - " +
      this.props.shipmentId
    );
  }

  get discountFavoritePercentage() {
    return BookingController.shipmentIdList.data[0].discountFavoritePercentage;
  }

  setAddNewDestination(value: boolean) {
    ShipmentBookingController.setBookingAddShipmentDestination(value);
  }

  setEditTerminated(value: boolean) {
    this.isEditTerminated = value;
  }

  props: any = this.$props;

  error = {
    sttManual: false,
    productName: false,
    taxNumber: false,
    phoneSender: false,
    phoneCodeSender: false,
    phoneCharMinimalSender: false,
    phoneCharMinimalReceiver: false,
    phoneReceiver: false,
    phoneCodeReceiver: false,
    estimationPrice: false,
    grossWeight: false,
    product: false,
    insurance: false
  };

  // Tooltip
  isOpenTooltipManualSTT = false;
  isOpenTooltipStatusGoods = false;
  isOpenTooltipShipmentID = false;
  isOpenTooltipInsurance = false;
  isOpenTooltipTax = false;
  isHoverDelete = false;
  isOpenTooltipWeight = false;
  isOpenTooltipPiecePerPack = false;
  isOpenTooltipNextCommodity = false;

  cacheIndex = 0;
  origins = [];
  destinations = [];
  destination = "";
  commodity = "";
  sender = "";
  receiver = "";
  commodityName = "";
  isOpenCommodity = false;
  checkedNumberSender = false;
  checkedNumberReceiver = false;
  isStatusGoods = false;
  isOpenSelectInsurance = false;
  tempIndexToRemove = -1;
  isEditTerminated = false;
  newPath = "";
  answer = false;
  indexVolume = 0;
  division = 0;
  listStatusGoods = [
    { name: "Pilih status pengiriman yang akan dikirim", value: "" },
    { name: "Ecommerce", value: "ecommerce" },
    { name: "Produk lokal / Batam", value: "batam" },
    { name: "Eks luar Batam / transit", value: "transit" },
    { name: "Salah kirim / return", value: "return" },
    { name: "Personal effect", value: "personal-effect" }
  ];

  listKGs: any = [
    {
      grossWeight: 0,
      dimension: {
        length: 10,
        width: 10,
        height: 10
      },
      volumeWeight: 0.16,
      convertVolumeWeight: "0,16",
      isDimensionDisabled: this.configDisableDimension,
      warningHwsSingle: false,
      isErrorHws: false
    }
  ];

  get getTitle() {
    return this.$route.meta.name;
  }

  get formType() {
    return "manual";
  }

  async mounted() {
    this.fetchListConfigurationCOD();
    await this.fetchProductConfigurableRule();
    await AccountController.checkAccountLocation();
    this.getCommodityList();
    this.fetchDestinationCity("");
    BookingController.setShowModalManualBooking(false);
    BookingController.setManualBooking(
      new ManualBooking({
        isWoodpack: this.manualBooking.isWoodpack
      })
    );
    BookingController.setError(false);
    BookingController.setEmptyDestination("");
    BookingController.setIncrementManualBooking();
    this.filterClients("");
    this.filterProductsDelivery("");
    this.getInsurancesTypeDelivery();
    this.getClientID();
    this.handleErrorDestinationCity();
    this.codStatus();
    this.dfodStatus();
  }

  created() {
    window.addEventListener("keydown", this.onEnterBooking);
  }

  unmounted() {
    window.removeEventListener("keydown", this.onEnterBooking);
  }

  prefixAlgoEstimationShow(algoId: string) {
    return prefixAlgoEstimationShow(algoId);
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
    BookingController.setDefaultManualBooking();
  }

  get isFreeTradeZone() {
    return AccountController.isFreeTradeZone;
  }

  get isErrorSpesificRate() {
    return ShipmentBookingController.errorSpesificByRate;
  }

  get isErrorAdd() {
    return ShipmentBookingController.isError;
  }

  get errorCause() {
    return ShipmentBookingController.isErrorCause;
  }

  get convertedArr() {
    return this.listKGs.map((key: any) => ({
      stt_piece_length: key.dimension.length,
      stt_piece_width: key.dimension.width,
      stt_piece_height: key.dimension.height,
      stt_piece_gross_weight: Number(
        key.grossWeight.toString().replace(",", ".")
      ),
      stt_piece_volume_weight: key.volumeWeight
    }));
  }

  get totalChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (
      this.totalGrossWeight.includes(",") ||
      this.totalVolumeWeight.includes(",")
    ) {
      total = this.compareTotalGrossAndVolume(x, y);
    } else {
      total = `${x > y ? x : y}`;
    }
    return total;
  }

  get productConfigRuleActive() {
    const clientId = clientMapperSikat("AP") || clientMapperSikat("AS");
    return this.productConfigurationRuleList.findProductConfigRule(
      this.manualBooking.productName,
      clientId,
      false
    );
  }
  get isCampaign() {
    return this.productConfigRuleActive.isCampaign;
  }

  get isGrossWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x > y;
  }

  get isVolumeWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x < y;
  }

  get totalCampaignChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (this.totalGrossWeight || this.totalVolumeWeight) {
      total = totalGrossWeightSikat(
        this.isCampaign,
        x,
        y,
        this.totalGrossWeight,
        this.totalVolumeWeight
      );
    } else {
      total = totalGrossWeightNonSikat(this.isCampaign, x, y);
    }
    return total;
  }

  compareTotalGrossAndVolume(x: number, y: number) {
    let total = 0;
    if (x > y) {
      const split = this.totalGrossWeight.split(",");
      let result = x - Number(split[0]);
      result = Number(Number.parseFloat(result.toString()).toFixed(2));
      if (x < 1) {
        total = 1;
      } else {
        total =
          result >= this.productConfigRuleActive.parameter
            ? Math.ceil(x)
            : Math.floor(x);
      }
    } else {
      const split = this.totalVolumeWeight.split(",");
      let result = y - Number(split[0]);
      result = Number(Number.parseFloat(result.toString()).toFixed(2));
      if (y < 1) {
        total = 1;
      } else {
        total =
          result >= this.productConfigRuleActive.parameter
            ? Math.ceil(y)
            : Math.floor(y);
      }
    }
    return total;
  }

  get payload(): any {
    BookingController.checkTransportAndEstimateSlaBooking({
      originCity:
        this.origin.cityCode || this.manualBooking.originCity.cityCode,
      destinationCity: this.manualBooking.destinationCity.cityCode,
      originDistrict: this.origin.code || this.manualBooking.originCity.code,
      destinationDistrict: this.manualBooking.destinationCity.code,
      product: this.manualBooking.productName
    }).then(response => {
      this.estimateSla = response;
    });
    return {
      sttManual: this.manualBooking.sttManual,
      referenceExternal: !this.manualBooking.referenceExternal
        ? ""
        : this.manualBooking.referenceExternal,
      productName: this.manualBooking.productName,
      originCity:
        this.origin.cityCode || this.manualBooking.originCity.cityCode,
      originDistrict: this.origin.code || this.manualBooking.originCity.code,
      destinationCity: this.manualBooking.destinationCity.cityCode,
      destinationDistrict: this.manualBooking.destinationCity.code,
      phoneSender: `${this.manualBooking.phoneCodeSender.substring(1)}${
        this.manualBooking.phoneSender
      }`,
      phoneReceiver: `${this.manualBooking.phoneCodeReceiver.substring(1)}${
        this.manualBooking.phoneReceiver
      }`,
      sender:
        typeof this.manualBooking.sender === "object"
          ? this.manualBooking.sender.customerName
          : this.manualBooking.sender,
      receiver:
        typeof this.manualBooking.receiver === "object"
          ? this.manualBooking.receiver.customerName
          : this.manualBooking.receiver,
      addressSender: this.manualBooking.addressSender,
      addressReceiver: this.manualBooking.addressReceiver,
      sttGrossWeight: Number(
        this.totalGrossWeight.toString().replace(",", ".")
      ),
      sttVolumeWeight: Number(
        this.totalVolumeWeight.toString().replace(",", ".")
      ),
      sttChargeableWeight: this.totalChargeableWeight,
      commodityCode: this.manualBooking.commodityCode.code,
      commodityId: this.manualBooking.commodityCode.id,
      insurance: this.manualBooking.insurance,
      statusGoods: this.manualBooking.statusGoods,
      estimationPrice: Number(
        this.manualBooking.estimationPrice.replace(/[.]/g, "")
      ),
      taxNumber: this.manualBooking.taxNumber,
      isWoodpack: this.manualBooking.isWoodpack,
      isCOD: this.manualBooking.isDFOD ? true : this.manualBooking.isCOD,
      isDFOD: this.manualBooking.isDFOD,
      codAmount: this.manualBooking.isDFOD || this.deliveryFeeType === "non-cod"
        ? 0
        : Number(
            this.manualBooking.codAmount
              .toString()
              .replace(/[.]/g, "")
          ),
      isSaveSender: this.manualBooking.isSaveSender,
      isSaveReceiver: this.manualBooking.isSaveReceiver,
      sttPieces: this.convertedArr,
      isDO: this.isDO,
      isMixpack: this.isMixpack,
      shipmentId: this.props.shipmentId,
      sttPiecePerPack: Number(this.manualBooking.piecePerPack),
      sttNextCommodity: this.manualBooking.nextCommodity,
      postalCodeDestination: this.manualBooking.postalCodeDestination?.id,
      discountFavoritePercentage: this.discountFavoritePercentage,
      isFromBooking: false
    };
  }

  addKGs() {
    const weight = (10 * 10 * 10) / this.division;
    const splitVolumeWeight = weight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = splitVolumeWeight[0];
    const behind = splitVolumeWeight[1]
      ? "," + splitVolumeWeight[1].slice(0, 2)
      : "";
    this.listKGs.push({
      grossWeight: 0,
      dimension: {
        length: 10,
        width: 10,
        height: 10
      },
      volumeWeight: weight,
      convertVolumeWeight: front + behind,
      isDimensionDisabled: this.configDisableDimension,
      warningHwsSingle: false,
      hwsError: false
    });
    this.calculationVolumeWeight(this.listKGs.length - 1);
  }

  onCloseCommodity() {
    this.isOpenCommodity = false;
  }

  getCommodityList() {
    CommodityController.getCommodityList(new RequestListCommodity());
  }

  fetchCommodityList = debounce((search: string) => {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search
      })
    );
  }, 250);

  get isErrorCity() {
    return (
      this.manualBooking.destinationCity?.status === "INACTIVE" ||
      this.manualBooking.originCity?.status === "INACTIVE"
    );
  }

  get checkIsDOForClientManual() {
    return !(this.detailClientID.clientIsDo && !this.payload.referenceExternal);
  }

  get isErrorCommodity() {
    return this.manualBooking.commodityCode?.status === "inactive";
  }

  get isFormValid() {
    let result = false;
    if (
      this.payload.productName &&
      this.checkIsDOForClientManual &&
      this.payload.destinationCity &&
      this.payload.destinationDistrict &&
      this.payload.phoneSender &&
      this.payload.phoneReceiver &&
      this.payload.sender &&
      this.payload.receiver &&
      this.payload.addressSender &&
      this.payload.addressReceiver &&
      this.payload.sttGrossWeight &&
      this.payload.sttChargeableWeight &&
      this.payload.commodityCode &&
      this.payload.insurance &&
      this.checkIntersection() &&
      !this.error.product &&
      !this.errorPhoneShipper &&
      !this.error.phoneCodeSender &&
      !this.error.phoneCharMinimalSender &&
      !this.error.phoneCharMinimalReceiver &&
      !this.error.phoneCodeReceiver &&
      !this.errorPhoneConsignee &&
      !this.error.insurance &&
      !this.error.sttManual &&
      !this.errorEstimationPrice &&
      this.isFtzRequiredForm &&
      this.isEstimationGoodMandatory &&
      this.validationVendorNinja &&
      this.isValidPostalCodeDestination &&
      !this.disableSubmitForHWS
    ) {
      result = true;
    }
    return result;
  }

  get validationVendorNinja() {
    let result = true;
    if (this.isVendorNinjaDestination && this.listKGs.length > 1) {
      result = false;
    }
    return result;
  }

  checkIntersection() {
    const result = true;
    for (const el of this.convertedArr) {
      if (
        el.stt_piece_gross_weight === 0 ||
        el.stt_piece_width === 0 ||
        el.stt_piece_length === 0 ||
        el.stt_piece_height === 0
      ) {
        return false;
      }
    }
    return result;
  }

  get manualBooking(): any {
    return BookingController.manualBooking;
  }

  get commodities() {
    return CommodityController.commodities.data.map(item => {
      return {
        id: item.commodity_id,
        name: item.commodity_name,
        code: item.commodity_code,
        status: item.commodity_status
      };
    });
  }

  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  get arrGrossWeight() {
    return this.listKGs.map((key: any) => {
      return Number(key.grossWeight.toString().replace(/,/g, "."));
    });
  }

  get totalGrossWeight() {
    let indicator = 0;
    this.arrGrossWeight.forEach((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  get arrVolumeWeight() {
    return this.listKGs.map((key: any) => {
      key.volumeWeight = Number(key.volumeWeight.toString().replace(/,/g, "."));
      return key.volumeWeight;
    });
  }

  get totalVolumeWeight() {
    let indicator = 0;
    this.arrVolumeWeight.forEach((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  removeKGs(index: number) {
    this.isHoverDelete = false;
    this.tempIndexToRemove = index;
    if (this.tempIndexToRemove > -1) {
      this.listKGs.splice(this.tempIndexToRemove, 1);
      this.tempIndexToRemove = -1;
    }
    this.setValidateHWS();
  }

  isHoverEnter(indexParam: number) {
    this.isHoverDelete = true;
    this.cacheIndex = indexParam;
  }

  formatted(value: string) {
    return formattedNPWP(value.replace(/\D+/g, ""));
  }

  calculationVolumeWeight(index: number) {
    this.indexVolume = index;
    const dimension =
      this.listKGs[index].dimension.length *
      this.listKGs[index].dimension.width *
      this.listKGs[index].dimension.height;

    const volume = volumeWeightForProduct(this.manualBooking.productName);
    this.listKGs[index].volumeWeight = dimension / volume;
    this.division = volume;

    const split = this.listKGs[index].volumeWeight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    this.listKGs[index].convertVolumeWeight = front + behind;
  }

  onChangeDimension(obj: any) {
    this.listKGs[0].dimension.length = obj.length;
    this.listKGs[0].dimension.width = obj.width;
    this.listKGs[0].dimension.height = obj.height;
  }

  isMaxLengthGrossWeight: any = null;
  formatFloat(value: string) {
    const regex = value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");

    this.isMaxLengthGrossWeight = maxLengthWeight(regex);
    return regex;
  }

  validatePhoneSender(value: string) {
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      BookingController.setPhoneSender(newPhone);
    } else {
      BookingController.setPhoneSender(value);
    }
    this.error.phoneSender = !/^\d+$/.test(value);
  }
  validatePhoneReceiver(value: string) {
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      BookingController.setPhoneReceiver(newPhone);
    } else {
      BookingController.setPhoneReceiver(value);
    }
    this.error.phoneReceiver = !/^\d+$/.test(value);
  }

  detectPhoneNumber(phoneArray: Array<string>) {
    return phoneArray.find(
      () => phoneArray[0] === "0" && phoneArray[1] === "8"
    );
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value);
    if (value.length < 20) {
      this.error.taxNumber = true;
    }
  }

  handleErrorTaxNumber() {
    if (!this.manualBooking.taxNumber) {
      this.error.taxNumber = false;
    }
  }

  formatEstimationPrice(value: string) {
    return formatInputMoney(value.replace(/\D+/g, ""));
  }

  formatPiecePerPack(value: string) {
    return value.replace(/\D+/g, "").replace(/\D|^0+/g, "");
  }

  // route navigation
  goBack() {
    this.$router.push("/shipment/booking");
  }
  onOpenSelect() {
    this.isStatusGoods = true;
  }

  onCloseSelect() {
    this.isStatusGoods = false;
  }

  onSelectStatus(key: string, value: string) {
    BookingController.setStatusGoods(value);
    this.statusGoodForClients = value;
  }

  filterProductsDelivery = debounce((search: string) => {
    BookingController.getProductsDelivery({
      search: search,
      page: 1,
      limit: 10,
      status: "active"
    });
  }, 250);

  get productsDelivery() {
    return BookingController.productsDelivery.data.map((e: any) => ({
      id: e.id,
      name: e.name,
      status: e.status
    }));
  }

  get isLoadingProduct() {
    return BookingController.loadingProductsDelivery;
  }

  isProductSelected = false;
  async onSelectProduct(obj: any) {
    if (obj) {
      if (obj.status === "ACTIVE") {
        if (
          this.manualBooking.productName !== "INTERPACK" &&
          obj.name === "INTERPACK"
        ) {
          this.listKGs = [this.listKGs[0]];
        }

        this.isProductSelected = true;
        BookingController.setProductName(obj.name);
        for (let i = 0; i < this.listKGs.length; i++) {
          this.calculationVolumeWeight(i);
        }
        this.validateSttManual(this.manualBooking.sttManual);
        this.isClientDO = this.statusCODClient;
        this.manualBooking.postalCodeDestination = "";
        this.callZipCode();

        this.deliveryFeeType = "non-cod";
        this.codStatus();
        this.dfodStatus();
        await this.getMinimumHeavyWeight();
        this.setValidateHWS();
      } else {
        this.error.product = true;
      }
    } else {
      this.isProductSelected = false;
      this.isClientDO = true;
      this.manualBooking.sttManual = "";
    }

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
  }

  // insurance types
  listInsurance: any = [{
    name: "",
    value: "",
  }];
  async getInsurancesTypeDelivery() {
    await BookingController.getInsuranceTypesDelivery({
      insuranceConfig: 2
    });

    this.listInsurance = BookingController.insuranceTypeDelivery.data.map((e: InsuranceTypeDeliveryData) => {
      return {
        name: e.name,
        value: e.value,
        status: e.status,
        cost: e.cost,
        commissionPos: e.commissionPos
      }
    })
  }

  onOpenSelectInsurance() {
    this.isOpenSelectInsurance = true;
  }

  onCloseSelectInsurance() {
    this.isOpenSelectInsurance = false;
  }

  onSelectStatusInsurance(name: string, value: string) {
    this.manualBooking.insurance = value;
    this.insuranceForClients = value;

    const insurance = this.listInsurance.find(
      (item: any) => item.value == value
    );
    this.error.insurance = insurance?.status === "Inactive";
  }

  get titleErrorspesification() {
    return BookingController.titleErrorspesification;
  }

  closeModalError() {
    ShipmentBookingController.setError(false);
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(true);
    }
  }

  updateStatusErrorEmbargo(status: boolean) {
    this.errorEmbargo.originCity = status;
    this.errorEmbargo.destinationCity = status;
    this.errorEmbargo.productType = status;
    this.errorEmbargo.comodity = status;
    this.errorEmbargo.weightStatus = status;
    this.errorEmbargo.lengthStatus = status;
  }

  //customer sender receiver
  get senders() {
    return this.getPhoneNumbers;
  }
  get receivers() {
    return this.getPhoneNumbers;
  }
  get getPhoneNumbers() {
    const list = BookingController.customerList.data;
    if (list.length !== 0) {
      return BookingController.customerList.data.map(item => {
        return {
          ...item,
          customerPhoneNumber: `${item.customerPhoneNumber}`
        };
      });
    } else {
      return list;
    }
  }
  setNameCustomer(search: string, type: string) {
    if (type === "senders") {
      BookingController.setNameSender(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      BookingController.setNameReceiver(search); // set payload even if not choosing
    }
    if (search.length >= 3) {
      BookingController.setIsLoadingCustomer(true);
      this.fetchCustomer(search);
    } else {
      return;
    }
  }
  setCustomer(search: string, type: string) {
    if (type === "senders") {
      BookingController.setNameSender(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      BookingController.setNameReceiver(search); // set payload even if not choosing
    }
  }
  fetchCustomer = debounce((search: string) => {
    BookingController.getCustomerList({ search: search, cache: true });
  }, 1000);
  get isLoadingCustomer() {
    return BookingController.isLoadingCustomer;
  }
  onChooseSenders(item: any) {
    if (!item) {
      return false;
    }
    item = {
      ...item,
      customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
      customerPhoneflag: codePhoneCountry(item.customerPhoneNumber).flag,
      customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number
    };
    BookingController.setDataSender(item);
    if (item.customerPhoneNumber.length < 5) {
      this.error.phoneCharMinimalSender = true;
    } else {
      this.error.phoneCharMinimalSender = false;
    }
    this.validatePhoneSender(item.customerPhoneNumber);
  }
  onChooseReceivers(item: any) {
    if (!item) {
      return false;
    }
    item = {
      ...item,
      customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
      customerPhoneflag: codePhoneCountry(item.customerPhoneNumber).flag,
      customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number
    };
    BookingController.setDataReceiver(item);
    if (item.customerPhoneNumber.length < 5) {
      this.error.phoneCharMinimalReceiver = true;
    } else {
      this.error.phoneCharMinimalReceiver = false;
    }
    this.validatePhoneReceiver(item.customerPhoneNumber);
  }

  onSaveCustomer = debounce((event: any, type: string) => {
    if (!event.target.checked) {
      return;
    }
    if (type === "sender") {
      if (this.manualBooking.sender && this.manualBooking.phoneSender) {
        BookingController.addCustomer({
          name:
            typeof this.manualBooking.sender === "object"
              ? this.manualBooking.sender.customerName
              : this.manualBooking.sender,
          phone: `${this.manualBooking.phoneCodeSender.substring(1)}${
            this.manualBooking.phoneSender
          }`,
          address: this.manualBooking.addressSender
        });
      }
    } else if (type === "receiver") {
      if (this.manualBooking.receiver && this.manualBooking.phoneReceiver) {
        BookingController.addCustomer({
          name:
            typeof this.manualBooking.receiver === "object"
              ? this.manualBooking.receiver.customerName
              : this.manualBooking.receiver,
          phone: `${this.manualBooking.phoneCodeReceiver.substring(1)}${
            this.manualBooking.phoneReceiver
          }`,
          address: this.manualBooking.addressReceiver
        });
      }
    }
  }, 1000);

  get isLoadingSttManual() {
    return ManualSTTController.isLoading;
  }
  get accountRefId(): number {
    return AccountController.accountData.account_type_detail.id;
  }

  get isClientPartner(): boolean {
    return (
      this.manualBooking.sttManual &&
      this.manualBooking.sttManual.accountRefId !== this.accountRefId
    );
  }

  get origin() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityCode: `${detail.cityCode}`
    };
  }

  // Destination City
  get errorDestinationCity() {
    return BookingController.errorDestination;
  }

  get isVendorNinjaDestination() {
    return BookingController.isVendorNinjaDestination;
  }

  setIsVendorNinjaDestination(val: boolean) {
    BookingController.setIsVendorNinjaDestination(val);
  }

  checkVendorDestinationDistrict() {
    const clientType =
      this.manualBooking.destinationCity?.type?.toLowerCase() === "vendor" ||
      this.manualBooking.destinationCity?.type?.toLowerCase() ===
        "vendor_lanjutan";
    const vendorCode =
      this.manualBooking.destinationCity?.vendor_code?.toLowerCase() ===
        "ninja" ||
      this.manualBooking.destinationCity?.vendor_code?.toLowerCase() === "nx";
    if (clientType && vendorCode && this.listKGs.length >= 1) {
      this.setIsVendorNinjaDestination(true);
    } else {
      this.setIsVendorNinjaDestination(false);
    }
    return this.isVendorNinjaDestination;
  }

  async handleErrorDestinationCity() {
    BookingController.setProductName("");
    BookingController.setErrorDestination(false);

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    if (this.isVendorNinjaDestination) {
      this.setIsVendorNinjaDestination(false);
    }
    
    this.callZipCode();
    if (this.manualBooking.destinationCity) {
      this.loadUpsellingProduct();
    } else {
      this.resetProductUpselling();
    }
  }

  loadUpsellingProduct() {
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.getPosterProduct(
      "",
      this.payload.originDistrict,
      this.payload.destinationDistrict,
      true,
      2311,
      "client"
    );
  }

  resetProductUpselling() {
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.resetAllProduct();
  }

  async callZipCode() {
    if (this.manualBooking.destinationCity && this.manualBooking.productName === "INTERPACK") {
      const responseDistrictData = await LocationController.getDetailDistrict({
        id: this.manualBooking.destinationCity.id,
        notSaveDetail: true
      });
      this.postalCodeDestinationOptions = responseDistrictData.originListZipCode.map((item: any) => {
        return {
          id: item,
          name: item
        }
      });
    }
  }

  destinationCityList: Array<any> = [];
  fetchDestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
        search: search,
        status: "",
        page: 1,
        limit: typeAccuracyAllowed ? 50 : 10,
        cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.code}`,
        cityCode: `${e.cityCode}`,
        status: e.status,
        type: e.type,
        vendor_code: e.vendorCode,
        isCod: e.isCod
      }));
  }

  get isLoadingDestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  get disableSaveSender() {
    return (
      !this.manualBooking.sender ||
      !this.manualBooking.phoneSender ||
      !this.manualBooking.addressSender ||
      this.error.phoneSender
    );
  }

  get disableSaveReceiver() {
    return (
      !this.manualBooking.receiver ||
      !this.manualBooking.phoneReceiver ||
      !this.manualBooking.addressReceiver ||
      this.error.phoneReceiver
    );
  }

  get accountRefAndCommodity() {
    return {
      accountRefId: this.manualBooking.sttManual.id,
      commodityId: this.manualBooking.commodityCode.id
    };
  }

  get openSave() {
    return ShipmentBookingController.showModalShipmentBooking;
  }

  booking() {
    this.isSubmit = true;
    ShipmentBookingController.addShipmentBooking(this.payload);
  }

  closeModalBooking() {
    ShipmentBookingController.setShowModalShipmentBooking(false);
  }

  onOpenSuccess() {
    ShipmentBookingController.setShowModalSuccess(true);
  }

  get openSuccess() {
    return ShipmentBookingController.showModalSuccess;
  }

  get messageSuccess() {
    return ShipmentBookingController.messageSuccess;
  }

  onCloseSuccess() {
    ShipmentBookingController.setShowModalSuccess(false);
    BookingController.setGuardBookingSubmit(false);
    this.setAddNewDestination(false);
    this.$emit("successBooking", "addBooking");
  }

  get isDO() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isDO
      : false;
  }

  get isMixpack() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isMixpack
      : false;
  }

  get reRenderManualBooking() {
    return BookingController.reRenderManualBooking;
  }

  // Validation
  get isMaxAddKgs() {
    return this.listKGs.length < 15;
  }

  isCountGrossWeigth = false;
  validateGrossWeightPerKoli(value: number): boolean {
    if (value > maxKg) {
      this.isCountGrossWeigth = true;
      return true;
    }
    this.isCountGrossWeigth = false;
    return false;
  }

  get validateTotalGrossWeight(): boolean {
    const total = Number(this.totalGrossWeight.replace(",", "."));
    return total > maxKg;
  }

  get validateTotalVolumeWeight(): boolean {
    let total = 0;
    this.listKGs.forEach((e: any) => (total += Number(e.volumeWeight)));
    return total > maxKg;
  }

  get allowToAddMoreKoliVolume(): boolean {
    return this.validateTotalVolumeWeight;
  }

  get allowToAddMoreKoli(): boolean {
    return this.validateTotalGrossWeight;
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : ""
    };
  }

  // clients
  filterClients = debounce((search: string) => {
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      PartnerController.getClientNamePOS({
        search: search,
        page: 1,
        limit: 10,
        isBranch: true,
        isApiCbp: false,
        packageType: "",
        cityCode: "",
        isDirectory: false
      });
    }
  }, 250);

  get clients() {
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      return PartnerController.clientDataNamePOS.data.map((e: any) => ({
        id: e.clientId,
        name: e.clientName,
        status: e.clientStatus,
        isDO: e.clientIsDO,
        isCOD: e.clientIsCOD
      }));
    }

    return [];
  }

  get isLoadingClients() {
    return PartnerController.loadingClientsNamePOS;
  }

  isErrorClient = false;
  isClientDO = true;
  statusCODClient = false;
  isClientCOD = true;
  getClientStatus(obj: any) {
    if (obj.status) {
      this.isErrorClient = true;
      this.isClientDO = true;
      this.isClientCOD = true;
      this.manualBooking.isCOD = false;
      this.statusCODClient = true;
      this.manualBooking.sttManual = "";
    } else {
      this.statusCODClient = obj.isDO;
      this.isErrorClient = false;

      if (obj.isDO) {
        this.isClientDO = obj.isDO;
        this.manualBooking.sttManual = "";
      } else {
        if (!this.isProductSelected) {
          this.isClientDO = obj.isDO;
          this.manualBooking.sttManual = "";
        } else {
          this.isClientDO = obj.isDO;
        }
      }

      if (obj.isCOD) {
        this.isClientCOD = false;
      } else {
        this.isClientCOD = true;
        this.manualBooking.isCOD = false;
      }
    }
  }

  // origin city
  get originCityList() {
    return LocationController.districtData.districtData
      .filter(key => key.status.toLowerCase() === "active")
      .map(e => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.code}`,
        cityCode: `${e.cityCode}`,
        status: e.status
      }));
  }

  get isLoadingOriginCity() {
    return LocationController.isLoadingDistrict;
  }

  statusGoodForClients = "ecommerce";
  get statusGoodValueForclients() {
    this.manualBooking.statusGoods = this.statusGoodForClients;
    return this.statusGoodForClients;
  }

  set statusGoodValueForclients(value: string) {
    this.statusGoodForClients = value;
  }

  defaultInsuranceTypeForClients = "free";
  get insuranceAsDefault() {
    this.manualBooking.insurance = this.insuranceForClients;
    return this.insuranceForClients;
  }

  set insuranceForClients(value: string) {
    this.defaultInsuranceTypeForClients = value;
  }
  get insuranceForClients() {
    return this.defaultInsuranceTypeForClients;
  }

  get checkIsDO() {
    return !(this.isClientDO && !this.payload.referenceExternal);
  }

  get checkIsOrigincityFilled() {
    if (this.getAccount.accountType === "partner" && !this.payload.originCity) {
      return false;
    } else
      return !(
        this.getAccount.accountType === "internal" &&
        !this.manualBooking.originCity
      );
  }

  getClientID = debounce(() => {
    if (this.getAccount.accountType === "client") {
      ClientManagementController.getDetail(this.getAccount.accountClientId);
    }
  }, 250);

  get detailClientID() {
    return ClientManagementController.detailClientManagement;
  }

  errorCaptionSttManual = "";
  get isDisabledSttManual() {
    if (this.formType === "client") {
      return (
        this.isClientDO ||
        this.manualBooking.productName === "" ||
        this.manualBooking.clientName === ""
      );
    }
    return this.getAccount.accountType === "client"
      ? this.isDO || this.manualBooking.productName === ""
      : false;
  }
  validateSttManual = debounce(async (value: string) => {
    if (value === "") {
      this.errorCaptionSttManual = "";
      this.error.sttManual = false;
      return;
    }
    const result = await BookingController.validateSttManual({
      clientId: this.getValidateSttManual,
      sttManual: value,
      bookingType:
        router.currentRoute.value.meta.formType === "manual" &&
        this.getAccount.accountType === "partner"
          ? "pos"
          : "client",
      productType: this.manualBooking.productName
    });
    this.error.sttManual = !result.isAllowBooking;
    this.errorCaptionSttManual = result.errorMessage;
  }, 500);

  get getValidateSttManual() {
    if (router.currentRoute.value.meta.formType === "client") {
      return this.manualBooking.clientName.id;
    } else if (this.getAccount.accountType === "client") {
      return this.getAccount.accountClientId;
    } else {
      return "";
    }
  }

  //phone validation

  get watchPhoneValidation() {
    const sender = this.payload.phoneSender;
    const receiver = this.payload.phoneReceiver;
    const requestErrorMessage = debounce(
      (phoneSender: string, phoneReceiver: string) => {
        if (phoneSender.length > 3 || phoneReceiver.length > 3) {
          BookingController.validatePhone({
            shipmentId: "",
            phoneSender: phoneSender.length > 3 ? phoneSender : "",
            phoneReceiver: phoneReceiver.length > 3 ? phoneReceiver : ""
          });
        }
      },
      100
    );
    return requestErrorMessage(sender, receiver);
  }

  get errorPhoneShipper() {
    let phoneShipper = "";
    if (BookingController.errorPhoneNumber) {
      if (
        BookingController.errorPhoneNumber.toLowerCase().includes("pengirim")
      ) {
        phoneShipper = BookingController.errorPhoneNumber;
      }
    }

    return phoneShipper;
  }

  get errorPhoneConsignee() {
    let phoneConsignee = "";
    if (BookingController.errorPhoneNumber) {
      if (
        BookingController.errorPhoneNumber.toLowerCase().includes("penerima")
      ) {
        phoneConsignee = BookingController.errorPhoneNumber;
      }
    }

    return phoneConsignee;
  }

  // error embargo
  get isErrorEmbargo() {
    return BookingController.isErrorEmbargo;
  }

  errorEmbargo = {
    originCity: false,
    destinationCity: false,
    productType: false,
    comodity: false,
    weightStatus: false,
    lengthStatus: false
  };

  onUpdateEmbargoStatusFail(value: string, index: number) {
    this.onSetDisableDimension(value, index)
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
  }

  get isShipperAndConsigneeAddressIdentical() {
    if (
      !this.manualBooking.addressSender ||
      !this.manualBooking.addressReceiver
    ) {
      return false;
    }
    return (
      this.manualBooking.addressSender.toLowerCase() ===
      this.manualBooking.addressReceiver.toLowerCase()
    );
  }

  get isFtzRequiredForm() {
    if (this.isFtzAccount) {
      return !!(
        (this.manualBooking.statusGoods || this.statusGoodValueForclients) &&
        this.manualBooking.piecePerPack &&
        this.manualBooking.nextCommodity
      );
    }

    return true;
  }

  get isFtzAccount() {
    return (
      AccountController.accountData.account_location?.city_free_trade_zone ===
      "yes"
    );
  }

  get isEstimationGoodMandatory() {
    if (this.manualBooking.insurance === "free") {
      return this.payload.estimationPrice >= 0 || !this.payload.estimationPrice;
    } else {
      return !(
        this.payload.estimationPrice <= 0 || !this.payload.estimationPrice
      );
    }
  }

  get isShipmentInsuranceEnable() {
    return SHIPMENT_ID_FAVOURITE.includes(this.props.shipmentId.substr(0, 2));
  }

  // error indicator mandatory
  currentFieldIndex = 0;
  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }
  get errorMandatory() {
    return [
      ...this.errorMandatoryDetailDelivery,
      ...this.errorMandatoryDetailGood
    ];
  }

  get errorMandatoryDetailDelivery() {
    return [
      !this.payload.productName && this.currentFieldIndex > 0,
      !this.payload.referenceExternal && this.currentFieldIndex > 1, // unused
      !this.payload.sender && this.currentFieldIndex > 2,
      !this.payload.receiver && this.currentFieldIndex > 3,
      !this.manualBooking.phoneSender && this.currentFieldIndex > 4,
      !this.manualBooking.phoneReceiver && this.currentFieldIndex > 5,
      !this.payload.addressSender && this.currentFieldIndex > 6,
      !this.payload.addressReceiver && this.currentFieldIndex > 7,
      !this.manualBooking.originCity?.code &&
        this.getAccount.accountType === "internal" &&
        this.currentFieldIndex > 8,
      !this.payload.destinationDistrict && this.currentFieldIndex > 9,
      !this.payload.commodityCode && this.currentFieldIndex > 10
    ];
  }

  get errorMandatoryDetailGood() {
    return [
      !this.payload.statusGoods &&
        this.isFtzAccount &&
        this.currentFieldIndex > 11,
      !this.payload.insurance && this.currentFieldIndex > 12,
      !this.payload.estimationPrice &&
        this.insuranceAsDefault !== "free" &&
        this.currentFieldIndex > 13,
      !this.payload.sttGrossWeight && this.currentFieldIndex > 14,
      !this.payload.sttChargeableWeight && this.currentFieldIndex > 15,
      !this.payload.sttPiecePerPack &&
        this.isFtzAccount &&
        this.currentFieldIndex > 16,
      !this.payload.sttNextCommodity &&
        this.isFtzAccount &&
        this.currentFieldIndex > 17
    ];
  }

  get urlVersion() {
    return isUrlUpdateVersion("customer");
  }

  isSubmit = false;
  onEnterBooking(event: any) {
    this.isSubmit = false;
    if (event.key === "Enter") {
      if (this.isDisabledButton) {
        this.setFieldIndex(19);
        return;
      }
      if (
        !this.isSubmit &&
        !this.openSuccess &&
        !MainAppController.loadingModal
      ) {
        this.booking();
        return;
      }
    }
  }

  get isDisabledButton() {
    return (
      !this.isFormValid ||
      this.isErrorCommodity ||
      this.isShipperAndConsigneeAddressIdentical
    );
  }

  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  // sender
  get errorPhoneSender() {
    return {
      errorPhone: this.error.phoneSender,
      errorMandatory: this.errorMandatory[4],
      errorPhoneLabel: this.errorPhoneShipper,
      errorPhoneCode: this.error.phoneCodeSender,
      phoneCharMinimal: this.error.phoneCharMinimalSender
    };
  }

  setErrorPhoneSender(val: any) {
    this.error.phoneSender = !/^\d+$/.test(val);
  }

  setErrorPhoneCodeSender(status: boolean) {
    this.error.phoneCodeSender = status;
  }

  setErrorCharMinimalSender(status: boolean) {
    this.error.phoneCharMinimalSender = status;
  }

  setPhoneSender(val: string) {
    BookingController.setPhoneSender(val);
  }

  setPhoneCodeSender(val: string) {
    BookingController.setPhoneCodeSender(val);
  }

  setPhoneFlagSender(val: string) {
    BookingController.setPhoneFlagSender(val);
  }

  // receiver
  get errorPhoneReceiver() {
    return {
      errorPhone: this.error.phoneReceiver,
      errorMandatory: this.errorMandatory[5],
      errorPhoneLabel: this.errorPhoneConsignee,
      errorPhoneCode: this.error.phoneCodeReceiver,
      phoneCharMinimal: this.error.phoneCharMinimalReceiver
    };
  }

  setErrorPhoneReceiver(val: any) {
    this.error.phoneReceiver = !/^\d+$/.test(val);
  }

  setErrorPhoneCodeReceiver(status: boolean) {
    this.error.phoneCodeReceiver = status;
  }

  setErrorCharMinimalReceiver(status: boolean) {
    this.error.phoneCharMinimalReceiver = status;
  }

  setPhoneReceiver(val: string) {
    BookingController.setPhoneReceiver(val);
  }

  setPhoneCodeReceiver(val: string) {
    BookingController.setPhoneCodeReceiver(val);
  }

  setPhoneFlagReceiver(val: string) {
    BookingController.setPhoneFlagReceiver(val);
  }

  // postal code
  postalCodeDestinationOptions: any[] = [];
  setPostalCodeDestination(value: string) {
    if (value) {
      this.manualBooking.postalCodeDestination = {
        id: value,
        name: value
      };
      return;
    }
    this.manualBooking.postalCodeDestination = value;
  }
  get isValidPostalCodeDestination() {
    if (this.isPostalCodeFieldShow) {
      return !!this.manualBooking.postalCodeDestination?.id;
    }
    return true;
  }
  get isPostalCodeFieldShow() {
    return this.manualBooking.productName === "INTERPACK";
  }

  // configurable rule product
  productConfigurationRuleList: ProductConfigurationRuleList = new ProductConfigurationRuleList();
  async fetchProductConfigurableRule() {
    try {
      this.productConfigurationRuleList = await ProductController.fetchProductConfigurationRule(
        new ProductConfigurationRuleRequestParams()
      );
    } catch (err) {
      this.productConfigurationRuleList = new ProductConfigurationRuleList();
    }
  }

  estimateSla = {
    slaMin: 0,
    slaMax: 0,
    transport: ""
  };
  get isShowWarningSla() {
    return this.estimateSla.slaMin >= 7;
  }

  get errorProduct() {
    if (!this.errorDestintaionProductUpselling) {
      if (this.errorMandatory[0]) {
        return "Produk pengiriman wajib dipilih";
      }

      if (this.errorEmbargo.productType) {
        return "Kota tujuan pengiriman masih berstatus Embargo" 
      }
    }

    return "";
  }

  errorDestintaionProductUpselling = false;
  setErrorDestintaionProductUpselling(status: boolean) {
    this.errorDestintaionProductUpselling = status;
    BookingController.setErrorDestination(status);
  }

  get configDisableDimension() {
    return prefixDisableDimension(this.props.shipmentId);
  }

  onSetDisableDimension(value: string, index: number) {
    const isDisable = this.configDisableDimension && Number(value?.replaceAll(",", ".")) < MAX_WEIGHT_SHIPMENT;
    if (this.listKGs[index]) {
      this.listKGs[index].isDimensionDisabled = isDisable;
    }
    
    if (isDisable && this.listKGs[index]) {
      const defaultDimension = {
        length: 10,
        width: 10,
        height: 10
      }
      this.listKGs[index].dimension = defaultDimension;
      this.calculationVolumeWeight(index);
    }
  }

  deliveryFeeType = "non-cod";
  get deliveryFeeTypes() {
    if (FlagsMedusa.cod_dfod_pos_favorite.isEnabled()) {
      return [
        { name: "Non COD", value: "non-cod", disabled: false, logo: "" },
        { name: "Cash On Delivery", value: "cod", disabled: this.codStatusDisabled, logo: "cod-logo" },
        { name: "Delivery Fee on Delivery", value: "dfod", disabled: this.dfodStatusDisabled, logo: "dfod-logo" },
      ];
    }

    return [];
  }

  requestData: ApiRequestList = new ApiRequestList();
  listDataConfigurationCOD: ResponsePayloadV2 = new ResponsePayloadV2();
  isEligibleCOD = false;
  isEligibleDFOD = false;
  isInsurance = false;
  listCodActive = [] as any;
  listDfodActive = [] as any;
  listCcoProductType = [] as any;
  listDfodProductType = [] as any;
  async fetchListConfigurationCOD() {
    this.listDataConfigurationCOD.loading = true;
    try {
      this.listDataConfigurationCOD = await CodConfigurationController.getList(
        new ApiRequestList({
          page: this.listDataConfigurationCOD.pagination.page,
          limit: this.listDataConfigurationCOD.pagination.limit,
          status: this.requestData.statusData.value
        })
      );
      // cod
      this.listCodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "COD CA RETAIL"
      );
      this.codStatusRetail();

      // dfod
      this.listDfodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "DFOD CA"
      );
      this.dfodStatusRetail();
    } catch (err) {
      this.listDataConfigurationCOD.message = parsingErrorResponse(err).type;
    } finally {
      this.listDataConfigurationCOD.loading = false;
    }
  }

  codStatusRetail() {
    if (this.listCodActive[0]?.ccoStatus === "active") {
      this.isEligibleCOD = true;
      this.listCcoProductType = this.listCodActive[0].ccoProductType.split(",");
    } else {
      this.isEligibleCOD = false;
    }
  }

  dfodStatusRetail() {
    if (this.listDfodActive[0]?.ccoStatus === "active") {
      this.isEligibleDFOD = true;
      this.listDfodProductType = this.listDfodActive[0].ccoProductType.split(",");
    } else {
      this.isEligibleDFOD = false;
    }
  }

  codStatusDisabled = false;
  codStatus() {
    const isCodDestination = this.manualBooking.destinationCity?.isCod === "yes";
    let result = false;
    if (this.isEligibleCOD) {
      if (!this.listCcoProductType.includes(this.manualBooking.productName)) {
        this.manualBooking.isCOD = false;
        this.checkIsInsurance();
      }
      
      result = this.listCcoProductType.includes(this.manualBooking.productName);
    } else {
      result = false;
    }

    if (!result || !isCodDestination) {
      this.codStatusDisabled = true;
    } else{
      this.codStatusDisabled = false;
    }

    return result;
  }

  dfodStatusDisabled = false;
  dfodStatus() {
    const isDfodDestination = this.manualBooking.destinationCity?.isCod === "yes";
    let result = false;
    if (this.isEligibleDFOD) {
      if (!this.listDfodProductType.includes(this.manualBooking.productName)) {
        this.manualBooking.isDFOD = false;
        this.checkIsInsurance();
      }

      result = this.listDfodProductType.includes(this.manualBooking.productName)
    } else {
      result = false;
    }

    if (!result || !isDfodDestination) {
      this.dfodStatusDisabled = true;
    } else {
      this.dfodStatusDisabled = false;
    }

    return result;
  }

  get disableInsuranceCOD() {
    return this.listCodActive[0]?.ccoIsInsurance && this.deliveryFeeType === "cod";
  }

  get disableInsuranceDFOD() {
    return this.listDfodActive[0]?.ccoIsInsurance && this.deliveryFeeType === "dfod";
  }

  disabledInsurance = false;
  checkIsInsurance() {
    if (this.disableInsuranceDFOD || this.disableInsuranceCOD) {
      this.disabledInsurance = true;
      this.manualBooking.insurance = "free";
      this.insuranceForClients = "free";
    } else {
      this.disabledInsurance = false;
    }
  }

  onChangeDeliveryFeeType(value: string) {
    this.processErrorEstimationPrice(this.manualBooking.estimationPrice);
    this.deliveryFeeType = value;
    this.changeCod(value);
    this.changeDfod(value);
    this.checkIsInsurance();
  }

  changeCod(value: string) {
    if (value === "cod") {
      BookingController.setCOD(true)
    } else {
      BookingController.setCOD(false);
    }
  }

  changeDfod(value: string) {
    if (value === "dfod") {
      BookingController.setDfod(true)
    } else {
      BookingController.setDfod(false);
    }
  }

  fullFilledCOD(event: any) {
    if (this.codStatus()) {
      this.manualBooking.codAmount = event;
    }

    this.processErrorEstimationPrice(event);
  }

  errorEstimationPrice = false;
  errMsgPrice = "";
  processErrorEstimationPrice(price: string) {
    const isNonCodValidate = this.deliveryFeeType === "non-cod";
    const isDfodValidate = this.deliveryFeeType === "dfod";
    const isCodValidate = this.deliveryFeeType === "cod";

   const estimation = Number(price?.replace(/\./g, ""));

   if (isNonCodValidate) {
    this.errorEstimationPrice = false;
   } else {
    this.validateMainEstimationPrice(estimation, isCodValidate, isDfodValidate);
   }
  }

  validateMainEstimationPrice(estimation: number, isCodValidate: boolean, isDfodValidate: boolean) {
    this.minPriceZero();
    this.maxPriceZero();

    const label = isCodValidate ? "COD" : "DFOD";
    const subLabelMinPrice = isCodValidate ? this.listCodActive[0]?.ccoMinPrice : this.listDfodActive[0]?.ccoMinPrice;
    const subLabelMaxPrice = isCodValidate ? this.listCodActive[0]?.ccoMaxPrice : this.listDfodActive[0]?.ccoMaxPrice;
    const validate = {
      codValidate: isCodValidate, 
      dfodValidate: isDfodValidate
    }
    const subLabel = {
      minPrice: subLabelMinPrice, 
      maxPrice: subLabelMaxPrice
    }

    this.conditionalValidationEstimationPrice(
      estimation, 
      validate, 
      subLabel,
      label
    );
  }

  minPriceZero() {
    if (this.listCodActive[0]?.ccoMinPrice === 0 || this.listDfodActive[0]?.ccoMinPrice === 0) {
      return;
    }
  }

  maxPriceZero() {
    if (this.listCodActive[0]?.ccoMaxPrice === 0 || this.listDfodActive[0]?.ccoMaxPrice === 0) {
      return;
    }
  }

  conditionalValidationEstimationPrice(
    estimation: number, 
    validate: any,
    subLabel: any,
    label: string
  ) {
    this.validateEstimationPriceIsTrue(estimation, validate, subLabel, label);
    this.validateEstimationPriceIsFalse(estimation, validate);
  }

  validateEstimationPriceIsFalse(
    estimation: number, 
    validate: any
  ) {
    if (
      this.estimationCompareMinAndMaxPrice(estimation, this.listCodActive[0]?.ccoMinPrice, this.listCodActive[0]?.ccoMaxPrice, validate.codValidate) ||
      this.estimationCompareMinAndMaxPrice(estimation, this.listDfodActive[0]?.ccoMinPrice, this.listDfodActive[0]?.ccoMaxPrice, validate.dfodValidate)
    ) {
      this.errorEstimationPrice = false;
    }
  }

  validateEstimationPriceIsTrue(
    estimation: number, 
    validate: any,
    subLabel: any,
    label: string
  ) {
    this.validateEstimationPriceLess(estimation, validate, subLabel, label);
    this.validateEstimationPriceMore(estimation, validate, subLabel, label);
  }

  validateEstimationPriceLess(
    estimation: number, 
    validate: any,
    subLabel: any,
    label: string
  ) {
    if (
      this.estimationLessThanPrice(estimation, this.listCodActive[0]?.ccoMinPrice, validate.codValidate) || 
      this.estimationLessThanPrice(estimation, this.listDfodActive[0]?.ccoMinPrice, validate.dfodValidate)
    ) { 
      this.errMsgPrice = `Minimal Harga Barang ${label} ${formatPriceWithoutCurrency(subLabel.minPrice)}`;
      this.errorEstimationPrice = true;
    }
  }

  validateEstimationPriceMore(
    estimation: number, 
    validate: any,
    subLabel: any,
    label: string
  ) {
    if (
      this.estimationMoreThanPrice(estimation, this.listCodActive[0]?.ccoMaxPrice, validate.codValidate) ||
      this.estimationMoreThanPrice(estimation, this.listDfodActive[0]?.ccoMaxPrice, validate.dfodValidate)
    ) {
      this.errMsgPrice = `Maximal Harga Barang ${label} ${formatPriceWithoutCurrency(subLabel.maxPrice)}`;
      this.errorEstimationPrice = true;
    }
  }

  estimationLessThanPrice(estimasi: number, price: number, validate: boolean) {
    return estimasi < price && validate;
  }

  estimationMoreThanPrice(estimasi: number, price: number, validate: boolean) {
    return estimasi > price && validate;
  }

  estimationCompareMinAndMaxPrice(estimasi: number, minPrice: number, maxPrice: number, validate: boolean) {
    return estimasi >= minPrice && estimasi <= maxPrice && validate;
  }

  get cityForWoodPacking() {
    return this.origin.cityCode || this.manualBooking.originCity.cityCode;
  }

  get woodpackingAccess() {
    return FlagsMedusa.permission_woodpacking_shipment.isEnabled();
  }

  async callHeavyWeightSurcharge(id: number) {
    if (this.manualBooking.productName && this.manualBooking.commodityCode.code) {
      await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge({
        id: id,
        product: this.manualBooking.productName,
        commodity: this.manualBooking.commodityCode.code
      })
    } else {
      HeavyWeightSurchargeController.setMinimumHeavyWeightSurcharge({
        value: 0,
        existMinimum: false
      });
    }
  }

  async getMinimumHeavyWeight() {
    await this.callHeavyWeightSurcharge(1);
    if (!HeavyWeightSurchargeController.heavyWeightMinimum.minimumExist) {
      this.callHeavyWeightSurcharge(2);
    }
  }

  get minimumHeavyWeightSurcharge() {
    return HeavyWeightSurchargeController.heavyWeightMinimum;
  }

  setValidateHWS() {
    const GW = String(this.listKGs[0].grossWeight).replace(",", ".")
    
    const grossWeight = +GW;
    this.listKGs[0].warningHwsSingle = this.listKGs.length === 1 
      && this.minimumHeavyWeightSurcharge.minimumExist 
      && (grossWeight >= this.minimumHeavyWeightSurcharge.heavyWeightMinimum);

    this.listKGs.forEach((key: any, index: number) => {
        const GW = String(key.grossWeight).replace(",", ".");
        this.listKGs[index].isErrorHws = (+GW >= this.minimumHeavyWeightSurcharge.heavyWeightMinimum) 
          && this.listKGs.length > 1 
          && this.minimumHeavyWeightSurcharge.minimumExist;
    })
  }

  get disableSubmitForHWS() {
    const hwsError = this.listKGs.filter((item: any) => item.isErrorHws)
    return this.listKGs.length > 1 && this.minimumHeavyWeightSurcharge.minimumExist && hwsError.length > 0;
  }

  get disableAddPiecesHws() {
    const hwsError = this.listKGs.filter((item: any) => item.isErrorHws || item.warningHwsSingle)
    return hwsError.length > 0;
  }

  async setHwsCommodity() {
    await this.getMinimumHeavyWeight();
    this.setValidateHWS();
  }
}
