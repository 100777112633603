
import { Vue, Options } from "vue-class-component";
import {
  PAYMENT_STATE,
  PaymentProps
} from "@/app/ui/components/dialog-payment/payment-props";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import CashActive from "@/app/ui/components/dialog-payment/payment-cash/cash-active.vue";
import PaymentSuccess from "@/app/ui/components/dialog-payment/components/payment-success.vue";
import DialogPaymentLayout from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayout.vue";

@Options({
  emits: ["closePayment"],
  components: { DialogPaymentLayout, PaymentSuccess, CashActive }
})
export default class DialogCash extends Vue.with(PaymentProps) {
  mounted() {
    PaymentController.changeState(PAYMENT_STATE.ACTIVE_STATE);
  }

  get state() {
    return PaymentController.paymentState;
  }
  get activeState() {
    return this.state === PAYMENT_STATE.ACTIVE_STATE;
  }
  get successState() {
    return this.state === PAYMENT_STATE.SUCCESS_STATE;
  }
}
