import { PromoConfigurationListRequest } from "@/data/payload/api/PromoConfigurationApiRequest";
export class PromoConfigurationEndpoint {
  promoConfigApi(id?: string): string {
    return `horde/v1/promo-discount-configuration${id ? `/${id}` : ""}`;
  }

  getPromoConfigurationList(params: PromoConfigurationListRequest): string {
    return `${this.promoConfigApi()}?${params.toQueryString()}`;
  }

  getPromoActive(params: PromoConfigurationListRequest): string {
    return `${this.promoConfigApi()}/active/list?${params.toQueryString()}`;
  }
}
