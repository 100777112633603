
import { Vue, Options, prop } from "vue-class-component";
import { I18N_LOCALES } from "@/i18n/locales";
class Props {
  customStyleList = prop<string>({
    default: "margin-top: 25px",
    type: String
  });
}
@Options({})
export default class SelectReceiptLanguage extends Vue.with(Props) {
  mounted() {
    this.$i18n.locale = "en";
  }
  unmounted() {
    this.$i18n.locale = "id";
  }
  get activeLocale() {
    return I18N_LOCALES[this.$i18n.locale];
  }
  get availableLanguage() {
    const languangesUsed = this.$i18n.availableLocales.slice(0, 2)
    return languangesUsed.map((locale: string) => {
      return I18N_LOCALES[locale];
    });
  }

  showListAvailableLanguage = false;
  toggleListAvailableLanguage() {
    this.showListAvailableLanguage = !this.showListAvailableLanguage;
  }
  forceCloseListAvailableLanguage() {
    this.showListAvailableLanguage = false;
  }

  changeLanguageReceipt(value: string) {
    this.$i18n.locale = value;
    this.toggleListAvailableLanguage();
  }
}
