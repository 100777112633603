export const I18N_LOCALES: any = {
  en: {
    flag: "uk",
    name: "English",
    value: "en"
  },
  id: {
    flag: "indonesia",
    name: "Indonesia",
    value: "id"
  }
};
