import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '@/app/ui/assets/svg/x.svg'


const _hoisted_1 = {
  key: 0,
  class: "flex justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QrActive = _resolveComponent("QrActive")!
  const _component_QrLoading = _resolveComponent("QrLoading")!
  const _component_QrReload = _resolveComponent("QrReload")!
  const _component_QrPaymentSuccess = _resolveComponent("QrPaymentSuccess")!
  const _component_QrPaymentFailedExpired = _resolveComponent("QrPaymentFailedExpired")!
  const _component_DialogPaymentLayout = _resolveComponent("DialogPaymentLayout")!

  return (_openBlock(), _createBlock(_component_DialogPaymentLayout, null, {
    default: _withCtx(() => [
      (_ctx.closeUiState)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode("img", {
              src: _imports_0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeDialogPayment && _ctx.closeDialogPayment(...args))),
              class: "w-5 cursor-pointer",
              alt: "x"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeState)
        ? (_openBlock(), _createBlock(_component_QrActive, {
            key: 1,
            billType: _ctx.billType
          }, null, 8, ["billType"]))
        : _createCommentVNode("", true),
      (_ctx.loadingState)
        ? (_openBlock(), _createBlock(_component_QrLoading, {
            key: 2,
            billType: _ctx.billType
          }, null, 8, ["billType"]))
        : _createCommentVNode("", true),
      (_ctx.reloadState)
        ? (_openBlock(), _createBlock(_component_QrReload, {
            key: 3,
            billType: _ctx.billType,
            onOnSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closePayment')))
          }, null, 8, ["billType"]))
        : _createCommentVNode("", true),
      (_ctx.successState)
        ? (_openBlock(), _createBlock(_component_QrPaymentSuccess, {
            key: 4,
            billType: _ctx.billType,
            onOnSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closePayment')))
          }, null, 8, ["billType"]))
        : _createCommentVNode("", true),
      (_ctx.failedOrExpiredState)
        ? (_openBlock(), _createBlock(_component_QrPaymentFailedExpired, {
            key: 5,
            billType: _ctx.billType,
            type: _ctx.state
          }, null, 8, ["billType", "type"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}