
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    customPath: {
      type: String,
      default: "booking"
    }
  },
  components: {}
})
export default class ModalErrorNotAllowEdit extends Vue {
  props: any = this.$props;
  onSubmit() {
    this.$router.push(`/shipment/${this.props.customPath}`);
  }
}
