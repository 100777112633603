
import { Options, Vue, prop } from "vue-class-component";
import RadioButton from "primevue/radiobutton";

class Props {
  modelValue = prop<string>({
    default: "non-cod",
    type: String,
  });
  isCod = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isDfod = prop<boolean>({
    default: false,
    type: Boolean,
  });
}

@Options({
  components: {
    RadioButton,
  },
})
export default class DeliveryTypeClient extends Vue.with(Props) {
  deliveryFeeType = this.modelValue;

  setValue(value: string) {
    this.deliveryFeeType = value;
    this.$emit("update:modelValue", value);
  }

  get deliveryFeeTypes() {
    return [
      { name: "Non COD", value: "non-cod", disabled: false, logo: "" },
      {
        name: "Cash On Delivery",
        value: "cod",
        disabled: !this.isCod,
        logo: "cod-logo",
      },
      {
        name: "Delivery Fee on Delivery",
        value: "dfod",
        disabled: !this.isDfod,
        logo: "dfod-logo",
      },
    ];
  }
}
