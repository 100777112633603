import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = { class: "inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogPaymentLayoutHeader = _resolveComponent("DialogPaymentLayoutHeader")!
  const _component_DialogPaymentLayoutImage = _resolveComponent("DialogPaymentLayoutImage")!
  const _component_button_outline = _resolveComponent("button-outline")!
  const _component_button_fill = _resolveComponent("button-fill")!
  const _component_DialogPaymentLayoutButton = _resolveComponent("DialogPaymentLayoutButton")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DialogPaymentLayoutHeader, {
      billType: _ctx.billType,
      textHeader: "Lakukan Pembayaran dengan Cash"
    }, null, 8, ["billType"]),
    _createVNode(_component_DialogPaymentLayoutImage, { image: "payment-cash-illustration" }),
    _createVNode(_component_DialogPaymentLayoutButton, null, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_button_outline, {
            onClick: _ctx.changePayment,
            title: "Ganti Metode Pembayaran",
            custom: "w-48",
            style: {"height":"40px"},
            class: "mr-4"
          }, null, 8, ["onClick"])
        ]),
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_button_fill, {
            onClick: _ctx.paidCash,
            title: "Telah Dibayar",
            custom: "w-48",
            style: {"height":"40px"}
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 1
    })
  ], 64))
}