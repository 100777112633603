import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center" }
const _hoisted_2 = {
  class: "absolute bottom-0 left-0 top-12 w-full rounded-b-md text-center",
  style: {"margin-top":"100px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton = _resolveComponent("skeleton")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, {
    isShadow: false,
    class: "relative px-5",
    style: {"height":"210px"}
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_skeleton, {
          width: "7.5rem",
          height: "2rem",
          class: "mt-3"
        }),
        _createVNode(_component_skeleton, {
          width: "6.5rem",
          height: "1rem",
          class: "mt-1"
        }),
        _createVNode(_component_skeleton, {
          width: "6rem",
          height: "0.8rem",
          class: "mt-3"
        }),
        _createVNode(_component_skeleton, {
          width: "4rem",
          height: "0.8rem",
          class: "mt-1"
        })
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_skeleton, {
          width: "100%",
          height: "100%"
        })
      ])
    ]),
    _: 1
  }))
}