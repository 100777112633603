
import { Options, prop, Vue } from "vue-class-component";
import Book from "./book.vue";
import SelectReceiptLanguage from "@/app/ui/views/shipment/booking/create-booking/payment-detail/select-receipt-language.vue";
import CheckTariff from "./check-tariff.vue";
import { PromoConfigurationListRequest } from "@/data/payload/api/PromoConfigurationApiRequest";
import { PromoConfigurationController } from "@/app/ui/controllers/PromoConfigurationController";
import {
  PromoConfigurationList,
  PromoConfigurationListData
} from "@/domain/entities/PromoConfiguration";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { promoPrefix } from "@/app/ui/views/shipment/booking/booking-utils";

interface Diskon {
  isDiscount: boolean,
  totalTariff: number,
  totalAfterDiscount: number
}

interface DiscountInterface {
  status: boolean;
  data: PromoConfigurationListData[];
}

class Props {
  dataCalculation = prop<any>({
    default: {},
    type: Object
  });
  bookingType = prop<string>({
    default: "",
    type: String
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  formType = prop<any>({
    default: "",
    type: String
  });
  isSttForeign = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["setDiscount"],
  components: {
    SelectReceiptLanguage,
    Book,
    CheckTariff
  },
  watch: {
    "payload.sttProductType": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttDestinationCityId": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttGrossWeight": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
    "payload.sttVolumeWeight": function() {
      this.checkDiscountActive();
      this.resetDiscount();
    },
  }
})
export default class Calculation extends Vue.with(Props) {
  discount: DiscountInterface = {
    status: false,
    data: []
  };

  async checkDiscountActive() {
    const grossWeight = Number(this.payload.sttGrossWeight.replace(",", "."));
    if (
      this.payload.sttOriginCityId &&
      this.payload.sttDestinationCityId &&
      this.payload.sttProductType &&
      grossWeight > 0 && 
      this.bookingTypePromoAllowed && 
      this.isAccountGetPromo &&
      !this.isReverseJourney
    ) {
      try {
        const promotion: PromoConfigurationList = await PromoConfigurationController.getPromoActive(
          new PromoConfigurationListRequest({
            page: 1,
            limit: 2,
            status: "active,nearly-expired",
            origin: this.payload.sttOriginCityId,
            destination: this.payload.sttDestinationCityId,
            productName: this.payload.sttProductType,
            grossWeight: grossWeight,
            volumeWeight: this.payload.sttVolumeWeight,
            chargeableWeight: this.payload.sttChargeableWeight,
            shipmentPrefix: this.payload.promoPrefix,
            isTotalData: false,
            appliedTo: this.payload.sttBooked.sttShipmentId ? "shipment" : "retail",
          })
        );

        const isGetDiscountAllowed = this.allowedToGetDiscount(promotion)
        this.calculatePromo(promotion.data, isGetDiscountAllowed);
      } catch (err) {
        this.discount.status = false;
      }
    }
  }

  allowedToGetDiscount(promotion: PromoConfigurationList) {
    let isGetDiscountAllowed = false;
    const prefix = promoPrefix(this.payload.sttBooked.sttShipmentId)
    if (prefix) {
      isGetDiscountAllowed = promotion.data[0].promoConfigurationAppliedShipments.includes(prefix)
    } else {
      isGetDiscountAllowed = true;
    }

    return isGetDiscountAllowed;
  }

  calculatePromo(promotionList: Array<PromoConfigurationListData>, isGetDiscountAllowed: boolean) {
    if (promotionList.length > 0 && isGetDiscountAllowed) {
      this.discount = {
        status: true,
        data: promotionList.map((item: PromoConfigurationListData) => {
          return new PromoConfigurationListData({
            promoConfigurationName: `${
              item.promoConfigurationName
            } sampai dengan ${formatDateWithoutTime(
              item.promoConfigurationEndDate
            )}`,
            promoConfigurationDiscountNominal:
              item.promoConfigurationDiscountNominal
          })
        }) 
      };
    } else {
      this.resetDiscountNominal();
    }
  }

  get isReverseJourney() {
    return this.payload.sttNo.match(/^78LP|^77LP|^76LP|^94LP/gi)
  }

  get isAccountGetPromo() {
    return  AccountController.accountData.isAllPartner || AccountController.accountData.isInternalAccount;
  }

  setDiscount(params: Diskon) {
    this.$emit("setDiscount", params)
  }

  get bookingTypePromoAllowed() {
    return this.payload.sttBooked.sttShipmentId || this.payload.sttBooked.sttBookedForType === "pos"
  }

  resetDiscount() {
    if (this.payload.sttGrossWeight <= 0 || this.payload.sttVolumeWeight <= 0) {
      this.resetDiscountNominal();
    }
  }

  resetDiscountNominal() {
    this.discount = {
      status: false,
      data: []
    }
  }
}
