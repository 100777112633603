import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: 
      `bg-gray-lp-200 p-4 items-start flex flex-row rounded text-gray-lp-800 text-12px xxl:text-14px space-x-2`
     }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("img", {
      src: require(`@/app/ui/assets/svg/info-red.svg`),
      alt: "info"
    }, null, 8, ["src"]),
    _createVNode("div", null, " STT dengan layanan " + _toDisplayString(_ctx.type === "cod" ? "Cash on Delivery" : "Delivery Fee On Delivery") + " tidak dapat mengubah kecamatan, kota tujuan ", 1)
  ]))
}