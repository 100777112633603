
import { Vue, Options } from "vue-class-component";
import Reload from "@/app/ui/components/icons/modules/reload.vue";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import DialogPaymentLayoutHeader from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutHeader.vue";
import DialogPaymentLayoutButton from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutButton.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { PaymentMethod } from "@/domain/entities/Payment";
import { PaymentProps } from "@/app/ui/components/dialog-payment/payment-props";
import { BookingController } from "@/app/ui/controllers/BookingController";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";

@Options({
  components: { DialogPaymentLayoutButton, DialogPaymentLayoutHeader, Reload },
  emits: ["onSubmit"]
})
export default class QRReload extends Vue.with(PaymentProps) {
  get paymentMethod() {
    return PaymentController.paymentMethod;
  }
  cancelQrPayment() {
    dataLayer(
      `${
        this.billType
      }_${this.paymentMethod.name.toLowerCase()}_batalkan_clicked`,
      {},
      ["timestamp", "userType", "userId"]
    );
    PaymentController.changeState("");
    PaymentController.changePaymentMethod(new PaymentMethod());
    if (this.billType === "booking") {
      PaymentController.toggleDialogPayment(false);
      BookingController.setForceRouter(true);
      this.$router.push("/shipment/booking");
    }
  }
  reGenerateQr() {
    dataLayer(
      `${
        this.billType
      }_${this.paymentMethod.name.toLowerCase()}_muat_ulang_clicked`,
      {},
      ["timestamp", "userType", "userId"]
    );
    PaymentController.generateQr({
      sttNo: PaymentController.sttNumber,
      paymentMethod: this.paymentMethod.name,
      billType: this.billType
    });
  }
  get paymentErrorCause() {
    return PaymentController.paymentErrorCause;
  }
  get captionMessage() {
    if (this.billType === "booking") {
      if (this.paymentErrorCause === "errorAmount") {
        return `Pembuatan booking berhasil dengan nomor STT ${
          PaymentController.sttNumber
        }.<br>Tidak dapat melakukan pembayaran menggunakan metode yang dipilih karena jumlah melebihi ${formatPrice(
          this.paymentMethod.maxAmount
        )}. Silakan lakukan pembayaran secara tunai.`;
      }
      return `Pembuatan booking berhasil dengan nomor STT ${PaymentController.sttNumber}.<br>Tidak dapat melakukan pembayaran menggunakan metode yang dipilih karena terjadi gangguan dalam sistem. Silakan lakukan pembayaran secara tunai.`;
    }
    return (
      "Silahkan klik “muat ulang” untuk memuat ulang halaman ini atau<br />" +
      "“Kembali” untuk kembali ke halaman list booking"
    );
  }
  get titleMessage() {
    if (this.billType === "booking") {
      return "Pembuatan booking berhasil";
    }
    return "Terjadi Gangguan Sistem";
  }
}
