import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-x-4 mt-6 gap-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, {
      fontSize: "20",
      label: _ctx.$t('bookingShipment.column.crossBorderInformation'),
      fontColor: "text-gray-lp-300",
      hint: _ctx.accountIsNonForeign ? '(Khusus area BATAM)' : ''
    }, null, 8, ["label", "hint"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.column.taxIdentificationNumber'),
        tooltipTitle: _ctx.$t('bookingShipment.tooltip.tax'),
        tooltipDescription: _ctx.$t('bookingShipment.tooltip.taxMsg'),
        fontSize: 14
      }, {
        default: _withCtx(() => [
          (_ctx.accountIsNonForeign)
            ? (_openBlock(), _createBlock(_component_lp_input, {
                key: 0,
                modelValue: _ctx.modelValue.taxNumber,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue.taxNumber = $event)),
                format: _ctx.formatted,
                onChange: _ctx.validateTaxNumber,
                errorCaption: "Masukan No. NPWP dengan benar",
                isErrorIconInput: false,
                error: _ctx.error.taxNumber,
                placeholder: 
            _ctx.$t('bookingShipment.placeholder.taxIdentificationNumber')
          ,
                maxLength: "20",
                "show-max-length": "",
                onBlur: _ctx.handleErrorTaxNumber
              }, null, 8, ["modelValue", "format", "onChange", "error", "placeholder", "onBlur"]))
            : (_openBlock(), _createBlock(_component_lp_input, {
                key: 1,
                modelValue: _ctx.modelValue.taxNumber,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.modelValue.taxNumber = $event)),
                placeholder: 
            _ctx.$t('bookingShipment.placeholder.taxIdentificationNumber')
          ,
                maxLength: "20",
                "show-max-length": ""
              }, null, 8, ["modelValue", "placeholder"]))
        ]),
        _: 1
      }, 8, ["label", "tooltipTitle", "tooltipDescription"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('bookingShipment.column.shipmentStatus'),
        tooltipTitle: _ctx.$t('bookingShipment.tooltip.shipmentStatus'),
        tooltipDescription: _ctx.$t('bookingShipment.tooltip.shipmentStatusMsg'),
        fontSize: 14
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Select, {
            onOpenSelect: 
            () => {
              _ctx.onOpenSelect();
            }
          ,
            onCloseSelect: _ctx.onCloseSelect,
            isOpenMenu: _ctx.isStatusGoods,
            value: _ctx.modelValue.statusGoods,
            onChooseSelect: _ctx.onSelectStatus,
            dataSelect: 
            _ctx.accountIsNonForeign
              ? _ctx.listStatusGoodsForIndonesian
              : _ctx.listStatusGoodsForEnglish
          ,
            class: "w-full whitespace-no-wrap my-1.5 z-10",
            errorCaption: "Status Pengiriman wajib diisi.",
            isError: _ctx.accountIsNonForeign ? _ctx.errorStatusGoods && _ctx.errorManadatoryShipmentStatus : false
          }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect", "isError"])
        ]),
        _: 1
      }, 8, ["label", "tooltipTitle", "tooltipDescription"]),
      (_ctx.accountIsNonForeign)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 0,
            label: _ctx.$t('bookingShipment.column.piecePerPack'),
            tooltipTitle: _ctx.$t('bookingShipment.tooltip.shipmentStatus'),
            tooltipDescription: _ctx.$t('bookingShipment.tooltip.shipmentStatusMsg'),
            fontSize: 14
          }, {
            default: _withCtx(() => [
              _createVNode(_component_lp_input, {
                modelValue: _ctx.modelValue.piecePerPack,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.modelValue.piecePerPack = $event)),
                format: _ctx.formatPiecePerPack,
                placeholder: "Masukkan total koli per kemasan",
                errorIcon: false,
                onChange: _cache[4] || (_cache[4] = (res) => {
            _ctx.errorPiecePerPack = !res && _ctx.isFtzAccount
          }),
                error: _ctx.errorPiecePerPack,
                errorCaption: "Total koli per kemasan wajib diisi"
              }, null, 8, ["modelValue", "format", "error"])
            ]),
            _: 1
          }, 8, ["label", "tooltipTitle", "tooltipDescription"]))
        : _createCommentVNode("", true),
      (_ctx.accountIsNonForeign)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 1,
            label: "Komoditas Selanjutnya",
            tooltipTitle: "Komoditas Selanjutnya",
            tooltipDescription: "Isi dengan komoditas barang yang ada dalam satu koli.",
            fontSize: 14
          }, {
            default: _withCtx(() => [
              _createVNode(_component_lp_input, {
                modelValue: _ctx.modelValue.nextCommodity,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.modelValue.nextCommodity = $event)),
                placeholder: "Masukkan komoditas selanjutnya",
                errorIcon: false,
                onChange: _cache[6] || (_cache[6] = (res)=> {
            _ctx.errorNextCommodity = !res && _ctx.isFtzAccount
          }),
                error: _ctx.errorNextCommodity,
                errorCaption: 'Komoditas selanjutnya wajib diisi'
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}