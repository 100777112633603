
import { Vue, Options } from "vue-class-component";
import { PaymentProps } from "@/app/ui/components/dialog-payment/payment-props";
import DialogPaymentLayoutHeader from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutHeader.vue";
import { PaymentController } from "@/app/ui/controllers/PaymentController";

@Options({
  components: { DialogPaymentLayoutHeader }
})
export default class QRLoading extends Vue.with(PaymentProps) {
  get paymentMethod() {
    return PaymentController.paymentMethod;
  }
  get billAmount() {
    if (
      PaymentController.formattedBillAmount ===
      PaymentController.formattedBillAmountAfterDiscount
    )
      return "";
    return PaymentController.formattedBillAmount;
  }
  get billAmountAfterDiscount() {
    return PaymentController.formattedBillAmountAfterDiscount;
  }
}
