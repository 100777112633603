import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerProductType = _resolveComponent("BannerProductType")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_DataWrapper, {
      label: _ctx.$t('bookingShipment.column.selectServiceType'),
      class: "w-full mr-4 mt-2"
    }, {
      default: _withCtx(() => [
        (_ctx.accountIsNonForeign)
          ? (_openBlock(), _createBlock(_component_BannerProductType, {
              key: 0,
              class: "mt-3 mb-4"
            }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "product")
      ]),
      _: 3
    }, 8, ["label"])
  ]))
}