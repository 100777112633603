
import { Vue, Options } from "vue-class-component";
import {
  PAYMENT_STATE,
  PaymentProps
} from "@/app/ui/components/dialog-payment/payment-props";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import DialogPaymentLayoutHeader from "@/app/ui/components/dialog-payment/layout/DialogPaymentLayoutHeader.vue";

@Options({
  components: { DialogPaymentLayoutHeader },
  emits: ["onSubmit"]
})
export default class QRActive extends Vue.with(PaymentProps) {
  mounted() {
    this.startCountDown();
    this.startCheckPayment();
  }
  unmounted() {
    this.clearIntervals();
  }

  get billAmount() {
    if (
      PaymentController.formattedBillAmount ===
      PaymentController.formattedBillAmountAfterDiscount
    )
      return "";
    return PaymentController.formattedBillAmount;
  }
  get billAmountAfterDiscount() {
    return PaymentController.formattedBillAmountAfterDiscount;
  }
  get sttNumber() {
    return PaymentController.sttNumber;
  }
  get paymentMethod() {
    return PaymentController.paymentMethod;
  }
  get paymentData() {
    return PaymentController.dataPayment;
  }

  get qrImageBase64() {
    return `data:image/png;base64, ${this.paymentData.qrImage}`;
  }
  get timeoutDuration() {
    return this.paymentData?.expTime;
  }

  // count down logic
  timerInSecond = this.timeoutDuration;
  countDownInterval: any = "";
  get qrTimer() {
    return new Date(this.timerInSecond * 1000).toISOString().substring(11, 19);
  }
  startCountDown() {
    if (this.timeoutDuration === 0) {
      return;
    }
    this.countDownInterval = setInterval(() => {
      if (this.timerInSecond === 1) {
        PaymentController.changeState(PAYMENT_STATE.EXPIRED_STATE);
      } else {
        this.timerInSecond--;
      }
    }, 1000);
  }
  // interval to check payment status
  intervalCheckDuration = 5;
  checkPaymentInterval: any = "";
  startCheckPayment() {
    this.checkPaymentInterval = setInterval(() => {
      PaymentController.checkInquiry(this.paymentData.transactionGenesisId);
    }, 1000 * this.intervalCheckDuration);
  }
  clearIntervals() {
    clearInterval(this.countDownInterval);
    clearInterval(this.checkPaymentInterval);
  }
}
