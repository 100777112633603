
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import { FlagsPermissionCbp } from "@/feature-flags/flags-cbp";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  computed: {},
})
export default class Booking extends Vue {
  tabs = [
    {
      name: "booking-list",
      title: "Daftar booking",
      permission: true
    },
    { 
      name: "booking-client-cbp-list", 
      title: "Daftar booking klien CBP",
      permission: FlagsPermissionCbp.cbp_enable.isEnabled()
    },
  ];
  changeTabs(value: string) {
    this.$router.push({
      name: value,
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  beforeMount() {
    this.handleTab();
  }

  async getListBookingClientCBP() {
    MainAppController.showLoading();
    try {
      const resp = await ShipmentBookingController.getBookingListCBP(
        new RequestListBooking({
          pmcClientId: 0,
          pmcStatus: "",
          limit: 20,
          paymentStatus: "",
          isPaidUnpaid: false,
          cache: true,
          isTotalData: true,
        })
      );
      return { data: resp.data };
    } catch (error) {
      return { data: [] };
    } finally {
      MainAppController.closeLoading();
    }
  }

  async handleTab() {
    const resp = await this.getListBookingClientCBP();
    // response no data show only one tab;
    if (!resp.data.length) {
      const query = this.$route.query.phoneNumber != null ? `?phoneNumber=${this.$route.query.phoneNumber}` : ""
      this.$router.push(`/shipment/booking/booking-list${query}`);
      this.tabs = [
        {
          name: "booking-list",
          title: "Daftar booking",
          permission: true
        },
      ];
    }
  }
}
